<template>
  <div class="sync-app-badge">
    <a v-if="currentOS == 'windows'" :href="msStoreLink">
      <img src="@/assets/logos/GetFromMicrosoft.png">
    </a>
    <a v-else href="#">
      <img src="@/assets/logos/GetFromMacStore.svg">
    </a>
  </div>
</template>

<style lang="scss" scoped>
img {
  height: 47px;
  border-radius: 10px;

  @media (min-width: 768px) {
    height: 47px;
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { osInfos, currentOS } from '@/rd/ResearchDataModel'

const osInfoRef = computed(() => osInfos.find(osInfo => osInfo.id === currentOS))
const msStoreLink = computed(() => currentOS == 'windows'
  ? "ms-windows-store://pdp?ocid=pdpshare&hl=en-us&gl=us&productid=9P50KNCSGK41"
  : "https://www.microsoft.com/store/productId/9P50KNCSGK41?ocid=pdpshare")

</script>