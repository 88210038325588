import { LatestTimestampStore } from '@webapp/util/LatestTimestampStore'
import _ from 'lodash';

// See https://gist.github.com/codecorsair/e14ec90cee91fa8f56054afaa0a39f13

/* eslint-disable @typescript-eslint/no-explicit-any -- apis return plain objects */

/**
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/.
 */

interface RequestOptions {
    ignoreCache?: boolean;
    useForms?: boolean;
    headers?: {[key: string]: string};
    // 0 (or negative) to wait forever
    timeout?: number;
}
  
const DEFAULT_REQUEST_OPTIONS = {
    ignoreCache: false,
    useForms: false,
    headers: {
        Accept: 'application/json',
    },
    // default max duration for a request
    timeout: 5000,
};
  
interface RequestResult {
    ok: boolean;
    status: number;
    statusText: string;
    data: string;
    json: <T>() => T;
    headers: string;
}

function queryParams(params: any = {}) {
    return Object.keys(params)
        .filter(k => params[k] !== undefined) // ADDED to avoid including present-but-undefined query params
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}
  
function withQuery(url: string, params: object = {}) {
    const queryString = queryParams(params);
    return queryString ? url + (url.indexOf('?') === -1 ? '?' : '&') + queryString : url;
}
  
function parseXHRResult(xhr: XMLHttpRequest): RequestResult {
    return {
        ok: xhr.status >= 200 && xhr.status < 300,
        status: xhr.status,
        statusText: xhr.statusText,
        headers: xhr.getAllResponseHeaders(),
        data: xhr.responseText,
        json: <T>() => JSON.parse(xhr.responseText) as T,
    };
}
  
function errorResponse(xhr: XMLHttpRequest, message: string | null = null): RequestResult {
    return {
        ok: false,
        status: xhr.status,
        statusText: xhr.statusText,
        headers: xhr.getAllResponseHeaders(),
        data: message || xhr.statusText,
        json: <T>() => JSON.parse(message || xhr.statusText) as T,
    };
  }
  
function request(method: 'get' | 'post' | 'put' | 'delete' | 'patch',
    url: string,
    queryParams: object = {},
    body: object | null = null,
    options: RequestOptions = DEFAULT_REQUEST_OPTIONS) {
    
    const ignoreCache = options.ignoreCache || DEFAULT_REQUEST_OPTIONS.ignoreCache;
    const headers = options.headers || DEFAULT_REQUEST_OPTIONS.headers;
    const timeout = options.timeout || DEFAULT_REQUEST_OPTIONS.timeout;
  
    return new Promise<RequestResult>((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method.toUpperCase(), withQuery(url, queryParams));
        
        if (headers) {
            Object.keys(headers).forEach(key => xhr.setRequestHeader(key, headers[key]));
        }

        if (ignoreCache) {
            xhr.setRequestHeader('Cache-Control', 'no-cache');
        }
    
        xhr.timeout = timeout;
    
        xhr.onload = () => {
            resolve(parseXHRResult(xhr));
        };
    
        xhr.onerror = () => {
            resolve(errorResponse(xhr, 'Failed to make request.'));
        };
    
        xhr.ontimeout = () => {
            resolve(errorResponse(xhr, 'Request took longer than expected.'));
        };
    
        if ((method === 'post' || method == 'put' || method == 'patch') && body) {
            if (options.useForms) {
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                const data = new URLSearchParams();
                Object.keys(body).forEach((key) => data.append(key, (body as any)[key]));
                xhr.send(data.toString());
            }
            else {
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.send(JSON.stringify(body));
            }
        } else {
            xhr.send();
        }
    });
}

// --------- END EXTERNAL SOURCE -----------

export class Api {
    private baseUrl: string
    private getToken: () => Promise<string | undefined> | string | undefined
    private getPushConnectionId: () => Promise<string | undefined> | string | undefined

    constructor(baseUrl: string, 
        getToken?: () => Promise<string | undefined> | string | undefined,
        getPushConnectionId?: () => Promise<string | undefined> | string | undefined) {

        this.baseUrl = baseUrl
        this.getToken = getToken ?? (() => undefined)
        this.getPushConnectionId = getPushConnectionId ?? (() => undefined)
    }

    async getPlainAsync(path = '', query: object = {}) {
        const url = this.baseUrl + path
        const options = await this.getOptionsAsync()
        query = query ?? {}

        const result = await request('get', url, query, null, options)
        if (!result.ok) {
            throw this.error(result)
        }
        return JSON.parse(result.data)
    }

    async getPlainByIdsAsync(itemPath: string | ((id: string) => string), ids: string[], expand?: string, asValues = false) {
        if (expand && asValues)
            throw 'Values cannot be expanded'

        const pathBuilder = typeof itemPath == "string" ? (id: string) => `${itemPath}/${id}` : itemPath

        if (ids.length == 0) {
            return []
        }

        if (ids.length == 1) {
            // use simple path to request a single item by id
            const path = pathBuilder(ids[0])
            return [await this.getPlainAsync(pathBuilder(ids[0]), { expand })] // return as 1-element array
        }
        else {
            // use special $list alias and ids query arg to request multiple items by id
            const path = pathBuilder("$list")
            const expandArg = asValues ? undefined : this.injectExpandItems(expand)

            return await this.getPlainByIdsCoreAsync(path, ids, expandArg)
        }
    }

    async getPlainByIdsCoreAsync(path: string, ids: string[], expandItems?: string) {
        const idBatches = _.chunk(ids, 20) // get up to 20 items per request
        const items = []
        for (const parallelBatch of _.chunk(idBatches, 5)) { // make up to 5 requests in parallel
            const idBatchPromises = parallelBatch.map(idBatch => this.getPlainAsync(path, { ids: idBatch.join(), expand: expandItems }))
            const itemBatches = await Promise.all(idBatchPromises) // wait for them all to return (TODO: send next as soon as first returns)
            items.push(..._.flatten(itemBatches))
        }
        return items
    }

    async getPlainCollectionAsync(collectionPath: string, expand?: string, query: any = {}) {
        const expandArg = this.injectExpandItems(expand)
        query['expand'] = expandArg
        return await this.getPlainAsync(collectionPath, query) as any[]
    }
    
    async getPlainCollectionByIdsAsync(pathBuilder: (id: string) => string, ids: string[], expandItems?: string) {
        if (ids.length == 1) {
            return await this.getPlainAsync(pathBuilder(ids[0]), { expand: expandItems }) as any[]
        }
        else {
            return await this.getPlainByIdsCoreAsync(pathBuilder('$list'), ids, expandItems)
        }
    }

    injectExpandItems(expand?: string) {
        if (!expand) {
            return 'items'
        }
        return expand.split(',')
            .map(itemPath => 'items/' + itemPath)
            .join(',')
    }

    async postPlainAsync(path: string, body?: object, query: object = {}, useForms = false) {
        const options = await this.getOptionsAsync(useForms)
        const result = await request('post', this.baseUrl + path, query ?? {}, body, options)
        if (!result.ok)
            throw this.error(result)
        
        return result.data ? JSON.parse(result.data) : undefined
    }

    async deleteAsync(path: string, query: object = {}) {
        const options = await this.getOptionsAsync()
        const result = await request('delete', this.baseUrl + path, query ?? {}, undefined, options)
        if (!result.ok)
            throw this.error(result)
    }

    async patchPlainAsync(path: string, changes: PatchChange[]) {
        const options = await this.getOptionsAsync()
        const result = await request('patch', this.baseUrl + path, {}, changes, options)
        if (!result.ok)
            throw this.error(result);

        //this.updateLatestTimestamp(result);
        
        return result.data ? JSON.parse(result.data) : null
    }

    private async getOptionsAsync(useForms = false) {
        const token = await this.getToken()
        const pushId = await this.getPushConnectionId()
        return {
            ignoreCache: false,
            useForms,
            headers: {
                Authorization: (token ? 'Bearer ' + token : ''),
                Accept: 'application/json',
                'X-Push-Connection-Id': pushId ?? '',
            },
            timeout: 30*1000, // 30 sec
        } as RequestOptions;
    }

    private updateLatestTimestamp(result: RequestResult) {
        //if (this.latestTimestampStore) {
            const headers = this.parseResponseHeaders(result.headers)
            // In modern browers, header names are returned in all lower case
            // See https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders
            const newTimestampStr = headers["latest-timestamp"]
            if (newTimestampStr) {
                const newTimestamp = parseInt(newTimestampStr)
                //this.latestTimestampStore.setLatestTimestamp(newTimestamp)
            }
        //}
    }

    private parseResponseHeaders(headerStr?: string) {
        const headers: {[key: string]: string} = {}
        if (headerStr) {
            const arr = headerStr.trim().split(/[\r\n]+/)
            arr.forEach(function (line) {
                const parts = line.split(': ')
                const name = parts.shift()
                const value = parts.join(': ')
                if (name) {
                    headers[name] = value
                }
            });
        }
        return headers
    }

    private error(result: RequestResult) {
        console.log(`Api.ErrorHeaders: ${result.headers}`)
        return new ApiError(result.status, result.statusText)
    }
}

export interface PaginatedList {
    items: any[]
    nextPageToken?: string
}

export enum PatchOp {
    Replace = "Replace",
}

export type PrimitiveValue = string | number | boolean | undefined
export type PatchChangeValue = PrimitiveValue | PrimitiveValue[] | { [key: string]: PrimitiveValue }

export class PatchChange {
    op = PatchOp.Replace
    path = ''
    value?: PatchChangeValue

    constructor(path: string, value?: PatchChangeValue) {
        this.path = path
        this.value = value
    }
}

export class ApiError extends Error {
    constructor(public statusCode: number, public message: string) {
        super(message)
        this.name = 'ApiError'
    }
}

export interface ItemAddedNotificationArgs {
    itemId: string
}

export interface ItemChangedNotificationArgs {
    itemId: string
}