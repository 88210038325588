<template>
  <div class="task-page container" :class="{ embedded: appHostStore.isEmbedded }">
    <h1 v-if="!props.noTitle">
      <img v-if="!props.noBrand" src="@webapp/assets/tree-crossing-icon-256.png" class="brand"/><slot name="title"></slot>
    </h1>
    <slot v-if="$slots.alert" name="alert"></slot>
    <div v-if="$slots.instruction" class="instruction">
      <slot name="instruction"></slot>
    </div>
    <form>
      <div class="body">
        <slot></slot>
      </div>
      <div class="actions">
        <div class="secondary-action">
          <slot name="secondaryAction"></slot>
        </div>
        <button type="button" v-if="!props.noBack" class="btn btn-outline-primary" @click="router.back()">
          Back
        </button>
        <button type="submit" v-if="!props.noAction" class="btn btn-primary" :disabled="busyRef || !props.canContinue" @click="onAction">
          <slot name="actionText">
            Next
          </slot>
        </button>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.task-page {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  img.brand {
    display: none; // small screen has brand in header
  }

  @media (min-width: 768px) {
    max-width: 600px;
    min-height: 500px;

    &:not(.embedded) {
      margin-top: 4rem;
      border: 1px solid #ddd;
      border-radius: 1rem;
      padding: 1rem 2rem 2rem 2rem;
    }

    img.brand {
      display: inline-block;
    }
  }
}

h1 {
  margin-bottom: 0;

  img {
    margin-right: 0.5rem;
    width: 50px;
  }
}

.instruction {
  color: #888;
}

form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.body {
  flex-grow: 1;
}

.actions {
  margin-top: 2rem; // for small screens
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-size: 0.875rem;

  .secondary-action {
    flex-grow: 1; // fill space between
    font-size: 0.875rem;   
  }

  button, a {
    min-width: 100px;
  }
}
</style>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAppHostStore } from '@webapp/AppHostStore'

const props = defineProps<{
  noTitle?: boolean
  noBrand?: boolean
  noBack?: boolean
  noAction?: boolean
  canContinue?: boolean
}>()

const appHostStore = useAppHostStore()

const emit = defineEmits([
  'action'
])

const router = useRouter()

const busyRef = ref(false)

function onAction(e: Event) {
  e.preventDefault()
  emit('action')
}
</script>
