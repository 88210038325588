import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/gp/UserStore'
import { useFamilyGroupCreator } from '@/manage/FamilyGroupCreator'
import { useExploreStore } from '@/explore/ExploreStore'
import { LoadMode } from '@/util/AsyncData'

// these pages represent the share action, so the button should NOT be shown in the header
const hideShareActionPages = [
    /^\/groups\/.+/,
    /^\/plans/,
]
export function useShareAction() {
    const router = useRouter()
    const userStore = useUserStore()
    const { defaultShareWorkspaceRef } = useFamilyGroupCreator()
    const exploreStore = useExploreStore()
    
    const selfRef = computed(() => userStore.getAsyncSelf(LoadMode.EnsureLoaded)?.data)
    const allowShareRef = computed(() => selfRef.value?.limitsDisabled || (selfRef.value?.limits?.sharingLimit ?? 0) > 0)
    const showShareActionRef = computed(() => 
        exploreStore.hasViewedTree &&
        defaultShareWorkspaceRef.value && 
        !hideShareActionPages.some(p => p.test(router.currentRoute.value.path))
    )
    const shareActionUrlRef = computed(() => allowShareRef.value ? "/groups/new" : "/plans")

    return {
        showShareActionRef,
        shareActionUrlRef,
    }
}
