import { AsyncData, AsyncDataState, DataError, VersionedData, LoadMode } from './AsyncData'
import { PatchChange } from '@webapp/util/Api'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { isDefined } from './TypeScriptUtil'

/**
 * Generic helper implementations for an async data store.
 */
export class AsyncDataStore {

    static areItemsLoaded<T>(storeMap: Map<string,AsyncData<T>>, keys: string[]) {
        const asyncItems = keys.map(k => storeMap.get(k))
        return asyncItems.every(a => a && a.loadComplete)
    }

    static getAsyncItemsIfAllLoaded<T>(storeMap: Map<string,AsyncData<T>>, keys: string[]) {
        const asyncItems = keys.map(k => storeMap.get(k))
        // NOTE: split tests to narrow return type
        if (!asyncItems.every(isDefined) || !asyncItems.every(a => a.loadComplete)) {
            return undefined
        }
        return asyncItems
    }

    /**
     * Gets the requested async items, adding them to the store map if needed. The actual items are then loaded
     * asynchronously if needed, while the async items are returned immediately.
     */
     static getAsyncItemsByIds<T>(
        storeMap: Map<string,AsyncData<T>>, 
        keys: (string | undefined)[], 
        typeName: string,
        loadMode: LoadMode,
        loadListAsync: (keys:string[], requestTime: DateTime) => Promise<Map<string,T>>) {

        const definedKeys = keys.filter(isDefined)

        if (definedKeys.length > 0) {
            const asyncItemsToLoad = AsyncDataStore.getAsyncItemsToLoad(storeMap, definedKeys, typeName, loadMode)
            if (asyncItemsToLoad.length > 0 && loadMode != LoadMode.TrackOnly) {
                const idsToLoad = asyncItemsToLoad.map(a => a.key)
                console.log(`Loading ${idsToLoad.length}/${keys.length} ${typeName}s: ${idsToLoad}`)

                const requestTime = DateTime.utc()
                AsyncDataStore.loadAsyncItemsAsync(asyncItemsToLoad, requestTime, loadListAsync(idsToLoad, requestTime)) // don't await
            }
        }
        
        // HACK: don't use map(storeMap.get), since it results in error "Cannot read properties of undefined (reading '__v_raw')"
        return definedKeys.map(id => storeMap.get(id)).filter(isDefined)
    }

    /**
     * Gets the async key list for the related items, adding it to the store map if needed. The actual key list AND related items 
     * are then loaded asynchronously if needed, while the async key list is returned immediately. When the async key list is updated, 
     * the related async items (and hopefully the items themselves) will also be available. The loaded key list will only contain
     * keys for related items that were successfully loaded.
     */
    static getAsyncKeyListForRelatedItems<T>(
        sourceKey: string | undefined,
        sourceTypeName: string,
        relatedKeysStoreMap: Map<string, AsyncData<VersionedKeyList>>,
        relatedItemStoreMap: Map<string, AsyncData<T>>,
        relatedTypeName: string,
        loadMode: LoadMode,
        loadRelatedItemsAsync: (sourceKeysToLoad: string[], requestTime: DateTime) => Promise<Map<string, T>>,
        getSourceKey?: (item: T) => string) {

        return this.getAsyncKeyListsForRelatedItems([sourceKey], sourceTypeName, relatedKeysStoreMap, relatedItemStoreMap, relatedTypeName, loadMode, loadRelatedItemsAsync, getSourceKey)[0]
    }

    /**
     * Gets the async key lists for the related items, adding them to the store map if needed. The actual key lists AND related items 
     * are then loaded asynchronously if needed, while the async key lists are returned immediately. When the async key lists are updated, 
     * the async related items (and hopefully the related items themselves) will also be available. The loaded key lists will only contain
     * keys for related items that were successfully loaded.
     */
     static getAsyncKeyListsForRelatedItems<T>(
        sourceKeys: (string | undefined)[],
        sourceTypeName: string,
        relatedKeysStoreMap: Map<string, AsyncData<VersionedKeyList>>,
        relatedItemStoreMap: Map<string, AsyncData<T>>,
        relatedTypeName: string,
        loadMode: LoadMode,
        loadRelatedItemsAsync: (sourceKeysToLoad: string[], requestTime: DateTime) => Promise<Map<string, T>>,
        getSourceKey?: (item: T) => string) {

        if (!getSourceKey && sourceKeys.length > 1)
            throw "getSourceKey must be specified if more than one source key"

        const definedSourceKeys = _.uniq(sourceKeys.filter(isDefined))
        const definedGetSourceKey = getSourceKey ?? (() => sourceKeys.at(0)!)

        const logSourceItemKey = definedSourceKeys.length == 1 ? definedSourceKeys[0] : `${definedSourceKeys.length} keys`
        const logPrefix = `${sourceTypeName}[${logSourceItemKey}]`

        const keyListTypeName = `${sourceTypeName}.${relatedTypeName}Keys`
        const asyncKeyListsToLoad = AsyncDataStore.getAsyncItemsToLoad(relatedKeysStoreMap, sourceKeys, keyListTypeName, loadMode)

        if (asyncKeyListsToLoad.length > 0 && loadMode != LoadMode.TrackOnly) {
            const keysToLoad = asyncKeyListsToLoad.map(a => a.key)
            console.log(`Loading ${keysToLoad.length}/${definedSourceKeys.length} ${keyListTypeName}: [${logSourceItemKey}]`)

            const requestTime = DateTime.utc()
            const keyListsPromise = loadRelatedItemsAsync(keysToLoad, requestTime).then(relatedItemMap => {
                AsyncDataStore.addItemMapToStore(relatedItemStoreMap, relatedTypeName, relatedItemMap, requestTime)

                console.log(`Added ${relatedItemMap.size} related ${relatedTypeName}s for ${logPrefix} to store`)
                
                const keyLists = new Map<string, VersionedKeyList>()
                for (const e of relatedItemMap.entries()) {
                    const key = e[0]
                    const item = e[1]
                    const sourceKey = definedGetSourceKey(item)
                    let keyList = keyLists.get(sourceKey)
                    if (!keyList) {
                        keyList = { keys: [], modifiedDate: requestTime } as VersionedKeyList
                        keyLists.set(sourceKey, keyList)
                    }
                    keyList.keys.push(key)
                }
                return keyLists
            })

            for (const asyncKeyList of asyncKeyListsToLoad) {
                AsyncData.trackLoad(asyncKeyList, requestTime, keyListsPromise
                    .then(keyLists => keyLists.get(asyncKeyList.key) ?? { keys: [] }))
            }
        }

        return definedSourceKeys.map(key => relatedKeysStoreMap.get(key)!) // async key lists should all be present now
    }

    /**
     * Gets or adds an async item for the specified key to the store map. The async item is only returned if it should be loaded.
     */
    static getAsyncItemToLoad<T>(storeMap: Map<string,AsyncData<T>>, key: string | undefined, typeName: string, loadMode: LoadMode) {
        return AsyncDataStore.getAsyncItemsToLoad(storeMap, [key], typeName, loadMode).at(0)
    }

    /**
     * Gets or adds async items for the specified keys to the store map. Only async items that should be loaded are returned.
     */
    static getAsyncItemsToLoad<T>(storeMap: Map<string,AsyncData<T>>, keys: (string | undefined)[], typeName: string, loadMode: LoadMode) {
        return AsyncDataStore.getOrAddAsyncItems(storeMap, keys, typeName).filter(a => a.shouldLoad(loadMode))
    }

    static shouldLoadPartialKeyList(asyncKeyList: AsyncData<VersionedPartialKeyList>, loadMode: LoadMode) {
        const isLoaded = asyncKeyList.state == AsyncDataState.Loaded
        return asyncKeyList.shouldLoad(loadMode) || (isLoaded && !asyncKeyList.data!.isComplete)
    }

    /**
     * Gets or adds async items for the specified keys to the store map. Undefined keys are ignored.
     */
    static getOrAddAsyncItems<T>(storeMap: Map<string, AsyncData<T>>, keys: (string | undefined)[], typeName: string) {

        // NOTE: allow keys to be undefined so method can be called safely in a context where some keys are not loaded yet

        const definedKeys = keys.filter(isDefined)
        const asyncItems: AsyncData<T>[] = []
        for (const key of definedKeys) {
            let asyncData = storeMap.get(key)
            if (!asyncData) {
                //console.log(`${typeName}[${key}]: Not in store, adding`)
                const plainAsyncData = new AsyncData<T>(key, typeName)
                storeMap.set(key, plainAsyncData)

                // get reactive proxy
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we just added it to the map
                asyncData = storeMap.get(key)!
            }
            asyncItems.push(asyncData)
        }
        return asyncItems
    }

    static loadListToStoreAsync<T>(storeMap: Map<string,AsyncData<T>>, keys: (string | undefined)[], typeName: string, loadMode: LoadMode,
        requestTime: DateTime, loadList: (keys:string[]) => Promise<Map<string,T>>) {

        if (loadMode == LoadMode.TrackOnly) {
            throw "loadListToStoreAsync should not be called with LoadMode.TrackOnly"
        }

        // NOTE: allow keys to be undefined so method can be called safely in a context where some keys are not loaded yet

        const asyncItemsToLoad = AsyncDataStore.getAsyncItemsToLoad(storeMap, keys, typeName, loadMode)

        if (asyncItemsToLoad.length > 0) {
            // get the promise that will return the list of requested items
            const loadListPromise = loadList(asyncItemsToLoad.map(a => a.key))

            AsyncDataStore.loadAsyncItemsAsync(asyncItemsToLoad, requestTime, loadListPromise)
        }
    }

    static async loadAsyncItemAsync<T>(asyncItem: AsyncData<T>, requestTime: DateTime, loadItemPromise: Promise<T>) {
        const itemMapPromise = loadItemPromise
            .then(item => new Map([[asyncItem.key, item]]))
        
        await AsyncDataStore.loadAsyncItemsAsync([asyncItem], requestTime, itemMapPromise)
    }

    static async loadAsyncItemsAsync<T>(asyncItems: AsyncData<T>[], requestTime: DateTime, loadItemsPromise: Promise<Map<string,T>>) {

        const trackPromises = [] as (Promise<T | undefined> | T | undefined)[]

        // track the loading of each item separately (tracking prevents concurrent loads)
        for (const asyncItem of asyncItems) {
            // create a promise that awaits the list promise, then returns this particular item if present or throws NotFound if not
            const itemPromise = loadItemsPromise.then(itemMap => {
                const item = itemMap.get(asyncItem.key)
                if (!item) {
                    throw DataError.NotFound
                }
                return item
            })

            AsyncData.trackLoad(asyncItem, requestTime, itemPromise)
            trackPromises.push(asyncItem.whenLoadCompleted!)
        }

        await Promise.all(trackPromises)
    }

    static createItemMap<T>(plainItems: any[], typeName: string, plainToItem: (p: any) => T, getKey: (i: T) => string) {
        const itemMap = new Map(plainItems.map(item => plainToItem(item)).map(item => [getKey(item), item]))
        const duplicates = plainItems.length - itemMap.size
        if (duplicates) {
            console.log(`Skipped ${duplicates} duplicate ${typeName}s`)
        }
        return itemMap
    }

    /**
     * Adds items from the specified map directly to the store, or updates them if already present. Current load state is ignored.
     */
    static addItemMapToStore<T>(storeMap: Map<string,AsyncData<T>>, typeName: string, itemMap: Map<string,T>, requestTime: DateTime) {

        const asyncItems = AsyncDataStore.getOrAddAsyncItems(storeMap, Array.from(itemMap.keys()), typeName)

        for (const asyncItem of asyncItems) {
            const data = itemMap.get(asyncItem.key)
            if (data) {
                AsyncData.load(asyncItem, data, requestTime)
            }
        }

        if (itemMap.size) console.log(`Added ${asyncItems.length}/${itemMap.size} ${typeName}s to store`)
    }

    /**
     * Adds the specified key list to the store, or updates it if already present. Current load state is ignored.
     */
    static addKeyListToStore(storeMap: Map<string, AsyncData<VersionedKeyList>>, id: string, typeName: string, keys: string[], requestTime: DateTime) {
        const asyncKeyList = AsyncDataStore.getOrAddAsyncItems(storeMap, [id], typeName)[0]
        const keyList = {
            keys,
            modifiedDate: requestTime,
        } as VersionedKeyList

        AsyncData.load(asyncKeyList, keyList, requestTime)
    }

    /**
     * Adds the specified keys to a loaded key list (if present). The updated list is assumed to be complete.
     */
    static addToLoadedKeyList(storeMap: Map<string, AsyncData<VersionedKeyList>>, id: string, newKeys: string[], requestTime: DateTime) {
        const asyncKeyList = storeMap.get(id)
        if (asyncKeyList?.state == AsyncDataState.Loaded) {
            const keyList = {
                keys: [...new Set([...asyncKeyList.data!.keys, ...newKeys])],
                modifiedDate: requestTime,
            } as VersionedKeyList
            AsyncData.load(asyncKeyList, keyList, requestTime)
        }
    }

    /**
     * Adds the specified partial key list to the store, or updates it if already present. Current load state is ignored.
     */
    static addPartialKeyListToStore(storeMap: Map<string, AsyncData<VersionedPartialKeyList>>, 
        id: string, 
        typeName: string, 
        getNewKeys: () => string[], 
        isNewListComplete: boolean, 
        requestTime: DateTime,
        reload = false) {

        const asyncKeyList = AsyncDataStore.getOrAddAsyncItems(storeMap, [id], typeName).at(0)!
        const newKeys = new Set(getNewKeys())
        const currentKeys = asyncKeyList.data?.keys
        if (isNewListComplete) {
            // proceed and replace current list (if identical, will not trigger a reactive update)
        }
        else if (reload) {
            // proceed and replace current list
        }
        else if (currentKeys) {
            // merge current keys into new keys
            for (const key of currentKeys) {
                newKeys.add(key)
            }

            if (newKeys.size == currentKeys.length) {
                // no new keys, so no need to update
                return
            }
        }
        const keyList = {
            keys: Array.from(newKeys),
            modifiedDate: requestTime,
            isComplete: isNewListComplete,
        } as VersionedPartialKeyList

        AsyncData.load(asyncKeyList, keyList, requestTime)
    }

    static async addToStoreMapAsync<T extends VersionedData>(
        map: Map<string,AsyncData<T>>, 
        typeName: string,
        getKey: (data: T) => string | undefined,
        addAsync: () => Promise<T>) {

        const data = await addAsync()
        const key = getKey(data)

        if (!key)
            throw 'added item has undefined key'

        console.log(`Added ${typeName} ${key} to store`)
            
        const plainAsyncData = new AsyncData<T>(key, typeName)
        map.set(key, plainAsyncData)

        // get reactive proxy
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we just added it to the map
        const asyncData = map.get(key)!
        //console.log(asyncData)

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- just added it, should have modifed date
        AsyncData.load<T>(asyncData, data, data.modifiedDate!)
        //console.log(asyncData)

        return key
    }

    static async saveFromStoreMapAsync<T extends VersionedData>(
        map: Map<string,AsyncData<T>>, 
        key: string, 
        saveAsync: (key: string, data: T) => Promise<T>) {

        const asyncData = map.get(key)
        if (!asyncData || asyncData.state != AsyncDataState.Loaded || !asyncData.data) {
            throw "AsyncDataStore.DataNotLoaded"
        }
        const data = asyncData.data // capture now
        await AsyncData.trackSaveAsync(asyncData, () => saveAsync(key, data))
    }

    static async patchFromStoreAsync<T extends VersionedData>(
        map: Map<string,AsyncData<T>>, 
        key: string,
        typeName: string,
        changes: PatchChange[],
        patchAsync: (key: string, changes: PatchChange[]) => Promise<T>) {

        // do not track this as a load request, since this call can fail for other reasons, and we don't want to mark
        // the item as "load failed" (and never retry) if a PATCH request failed but a GET might still succeed
        const requestTime = DateTime.utc()
        const data = await patchAsync(key, changes)
        const asyncData = AsyncDataStore.getAsyncItemToLoad(map, key, typeName, LoadMode.Reload)
        AsyncData.load(asyncData!, data, requestTime)

        console.log(`Patched ${typeName} ${key} with changes to ${changes.map(ch => ch.path).join(', ')}`)
    }
}

export interface VersionedKeyList extends VersionedData {
    keys: string[]
}

export interface VersionedPartialKeyList extends VersionedKeyList {
    isComplete: boolean
}
