<template>
  <div class="family-group-list">
    <p v-if="userRef?.isSelf" class="subhead">Groups are how people share trees and explore them with other family members</p>
    <div v-if="pendingInvitesRef.length > 0" class="invite-area">
      <h4>
        <span class="header-text">
          Invitations
          <span class="badge notify-badge rounded-pill">{{ pendingInvitesRef.length }}</span>
        </span>
      </h4>
      <ul class="list-unstyled invite-list">
        <li v-if="pendingInvitesRef.length == 0" class="empty-list">
          None
        </li>
        <li v-for="inv in pendingInvitesRef" :key="inv.id">
          <FamilyGroupInviteItem :inviteId="inv.id" select-mode @click="showInvite"/>
        </li>
      </ul>
      <h4 class="mt-3">Member of</h4>
    </div>
    <ul v-if="familyGroupsRef.length" class="list-unstyled group-list">
      <li v-for="fg in familyGroupsRef" :key="fg.id">
        <FamilyGroupItem :family-group-id="fg.id" :user-id="props.userId" select-mode @click="showMember(fg)"/>
      </li>
    </ul>
    <div v-else class="empty-list">
      <template v-if="userRef?.isSelf">You are not a member of any groups.</template>
      <template v-else>This user is not a member of any groups.</template>
    </div>
    <div class="actions-row">
      <router-link v-if="canAddGroupRef" class="btn btn-primary btn-sm" to="/groups/new">
        <plus-icon size="1.2x" class="mb-1"></plus-icon> Create a new group
      </router-link>
      <button type="button" v-if="canJoinGroupRef" class="btn" :class="joinClassRef" @click="onJoinGroup">
        Join a group
      </button>
    </div>
    <FamilyGroupMemberModal ref="memberModalRef"/>
    <FamilyGroupInviteModal ref="inviteModalRef"/>
  </div>
</template>

<style lang="scss" scoped>
.invite-area {
  h4 .header-text {
    position: relative;

    .notify-badge {
      position: absolute;
      top: 0px;
      right: -16px;
      padding: 3px 6px;
      background-color: var(--bs-danger);
      font-size: 0.75rem;
    }
  }
}

.group-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.empty-list {
  font-size: 0.875rem;
  color: #888;
}

.actions-row {
  margin-top: 2rem;
  font-size: 0.875rem;
}
</style>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { usePageTitle } from '@/util/AppUtil'
import { FamilyGroup, InvitationResult, ItemPermissions, PrincipalType } from '@/gp/GroupAdminModel'
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore'
import { useFamilyGroupInviteStore } from '@/gp/FamilyGroupInviteStore'
import { useUserStore } from '@/gp/UserStore'
import { LoadMode } from '@/util/AsyncData'
import { PlusIcon } from '@zhuowenli/vue-feather-icons'
import { TokenManager } from '@/auth/TokenManager'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import _ from 'lodash'
import FamilyGroupInviteItem from './FamilyGroupInviteItem.vue'
import FamilyGroupItem from './FamilyGroupItem.vue'
import FamilyGroupInviteModal from './FamilyGroupInviteModal.vue'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import FamilyGroupMemberModal from './FamilyGroupMemberModal.vue'
import { getPotentialMemberForUserAsync } from './FamilyGroupMemberManager'
import { useDataGroupStore } from '@/gp/DataGroupStore'

const props = defineProps<{
  userId?: string
}>()

const memberModalRef = ref<InstanceType<typeof FamilyGroupMemberModal>>()
const inviteModalRef = ref<InstanceType<typeof FamilyGroupInviteModal>>()

const userStore = useUserStore()
const familyGroupStore = useFamilyGroupStore()
const inviteStore = useFamilyGroupInviteStore()
const memberStore = useFamilyGroupMemberStore()
const dataGroupStore = useDataGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()

const userRef = computed(() => userStore.getAsyncUser(userIdRef.value, LoadMode.EnsureLoaded)?.data)
const userPermissionsRef = computed(() => userStore.getAsyncPermissions(userIdRef.value, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None)
const pendingInvitesRef = computed(() => inviteStore.getInvitesForUser(userIdRef.value, LoadMode.EnsureLoaded)
  .filter(inv => inv.result == InvitationResult.Pending))
const familyGroupsRef = computed(() => {
  const fms = memberStore.getMembersForUsers([userIdRef.value], LoadMode.EnsureLoaded)
  const dms = dataGroupMemberStore.getMembersForDataGroups([userRef.value?.profileDataGroupId], LoadMode.EnsureLoaded)
    .filter(dm => dm.principalType == PrincipalType.FamilyGroup)
  const familyGroupIds = _.uniq([...fms.map(fm => fm.familyGroupId), ...dms.map(dm => dm.principalId)])
  const familyGroups = familyGroupStore.getGroupList(familyGroupIds) // loaded inline
  _.sortBy(familyGroups, fg => fg.name)
  return familyGroups;
})

const userIdRef = computed(() => props.userId ?? TokenManager.userId)
const canAddGroupRef = computed(() => !!userRef.value?.isSelf && !TokenManager.isSupervised)
const canJoinGroupRef = computed(() => false)
const joinClassRef = computed(() => ({ 
  'btn-primary': !canAddGroupRef.value,
  'btn-outline-primary': canAddGroupRef.value,
}))

usePageTitle("Groups")

async function showInvite(inviteId: string) {
  inviteModalRef.value?.openEdit(inviteId)
}

async function showMember(fg: FamilyGroup) {
  // show member modal for family group + user profile
  const pm = await getPotentialMemberForUserAsync(fg, userRef.value!)
  const pg = (await dataGroupStore.getGroupListAsync([userRef.value!.profileDataGroupId])).at(0)
  if (pm && pg?.startItemId) {
    memberModalRef.value?.open(fg.id!, pm, pg.startItemId, true)
  }
}

function onJoinGroup() {
  alert("join!")
}
</script>