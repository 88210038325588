<template>
  <div class="modal member-modal" ref="modalRef" data-bs-backdrop="static" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img class="member-icon" :class="{ user: !!pmRef?.user }" src="@/assets/icons/person_gray_150.png">
          <h1>{{ pmRef?.displayName }}</h1>
          <button type="button" class="btn-close" v-bind="{ disabled: busyRef }" data-bs-dismiss="modal"
            aria-label="Close"></button>
          <div class="member-type">
            <img v-if="memIsGroupOwnerRef" class="owner" src="@/assets/icons/crown.svg"/>
            <template v-if="pmRef?.member">
              {{ memTypeNameRef }}
            </template>
            <template v-else-if="pmRef?.user">
              User
            </template>
            <template v-else>
              Profile managed by {{ profileOwnerRef?.displayName }}
            </template>
          </div>
        </div>
        <div class="modal-body">
          <div v-if="showRoleInfoRef" class="role-info">
            {{ memRoleInfoRef }}
          </div>
          <div v-if="scopeRelRef" class="scope-rel" :class="{ ['scope-person']: personIsScopePersonRef }">
            <img src="@/assets/icons/mini_tree_150.png">
            <div>{{ scopeRelTextRef }}</div>
          </div>
          <div class="member-link">
            <router-link v-if="forProfileRef && familyGroupRef" :to="familyGroupRef.pageUrl" @click="close">
              View group
            </router-link>
            <router-link v-else-if="pmRef?.user" :to="User.getPageUrl(pmRef.user.id)" @click="close">
              View user
            </router-link>
            <router-link v-else-if="profileIdRef" :to="Person.getPageUrl(profileIdRef)" @click="close">
              View profile
            </router-link>
          </div>
          <div v-if="!profileIsSharedRef" class="alert alert-primary alert-sm suggested-alert">
            This is a suggested group member.
          </div>
          <div v-if="profileIsSharedRef && personIsDeceasedRef" class="alert alert-primary alert-sm">
            This person is deceased.
          </div>
          <div v-if="profileIsSharedRef" class="member-task-list">
            <div v-if="showShareTaskRef" class="member-task shared" :class="{ done: profileIsSharedRef }">
              <EyeIcon />
              <div>
                <div class="task-not-done">Profile not shared</div>
                <div class="task-done">Profile shared</div>
                <div>
                  <span class="task-date">
                    {{ DateTimeUtil.toRelativePastDate(sharedDateRef) }}
                  </span>
                  <span v-if="sharedByRef" class="task-actor">
                    by <router-link :to="sharedByRef.pageUrl" @click="close">
                      {{ sharedByRef.displayName }}
                    </router-link>
                  </span>
                </div>
              </div>
            </div>
            <div v-if="showInviteTaskRef" class="member-task invited" :class="{ done: memIsInvitedRef || pmRef?.member }">
              <img src="@/assets/icons/mail_gray_60.png" />
              <div>
                <div class="task-not-done">Not invited yet</div>
                <div class="task-done">Invited to join</div>
                <div>
                  <span class="task-date">
                    {{ DateTimeUtil.toRelativePastDate(inviteDateRef) }}
                  </span>
                  <span v-if="invitedByRef" class="task-actor">
                    by <router-link :to="invitedByRef.pageUrl" @click="close">
                      {{ invitedByRef.displayName }}
                    </router-link>
                  </span>
                </div>
              </div>
            </div>
            <div v-if="showAddUserTaskRef" class="member-task user-added" :class="{ done: pmRef?.user }">
              <img v-if="pmRef?.user" src="@/assets/icons/person_gray_150.png" />
              <img v-else src="@/assets/icons/person_dotted_80.png" />
              <div>
                <div class="task-not-done">No user account</div>
                <div class="task-done">User account created</div>
                <div>
                  <span class="task-date">
                    {{ DateTimeUtil.toRelativePastDate(userAddedDateRef) }}
                  </span>
                  <span v-if="userAddedByRef" class="task-actor">
                    by <router-link :to="userAddedByRef.pageUrl" @click="close">
                      {{ userAddedByRef.displayName }}
                    </router-link>
                  </span>
                </div>
              </div>
            </div>
            <div v-if="pmRef?.member" class="member-task member-joined">
              <img src="@/assets/icons/green_check_circle_64.png" />
              Joined {{ DateTimeUtil.toRelativePastDate(joinDateRef) }}
            </div>
            <div v-if="!showHistoryRef" class="member-history">
              <button type="button" class="btn btn-inline btn-link" @click="showHistoryRef = !showHistoryRef">
                View history
              </button>
            </div>
          </div>
          <div v-if="showProfileOnlyRef" class="alert alert-primary alert-sm profile-only">
            <template v-if="isSelfProfileRef">
              Your profile is shared with this group, but you are not a group member.
            </template>
            <template v-else>
              This user's profile is shared with the group, but they are not a group member.
            </template>
          </div>
          <div v-if="showChildOptionRef" class="form-check child-option">
            <div>
              <input type="checkbox" id="childCheck" class="form-check-input" v-model="isChildRef" :disabled="!canEditGroupRef" />
              <label for="childCheck" class="form-check-label">
                This is a child under age 13
              </label>
              <InfoButton @click="showChildOptionInfoRef = !showChildOptionInfoRef" />
            </div>
            <div v-if="showChildOptionInfoRef" class="help-text-sm">
              Use this option to enable special features that will help protect the child and their information.
            </div>              
          </div>
          <div v-if="canAddUserRef" class="alert alert-primary alert-sm">
            To invite this person to join the group, 
            <button type="button" class="btn btn-inline btn-link" @click="onAddUser">create a child account</button> 
            for them.
          </div>
          <FamilyGroupRoleOption
            v-if="showCanInviteOptionRef"
            :canInvite="memCanInviteRef"
            :disabled="!canChangeRoleRef"
            small
            @update:can-invite="onCanInviteChanged">
          </FamilyGroupRoleOption>
        </div>
        <div class="modal-footer">
          <button type="button" v-if="showRemoveProfileRef" class="btn btn-inline btn-link secondary-action"
            :disabled="busyRef" @click="onRemove">
            <template v-if="canEditGroupRef">
              Remove profile
            </template>
            <template v-else>
              Stop sharing
            </template>
          </button>
          <button type="button" v-if="canRemoveMemberRef" class="btn btn-inline btn-link secondary-action"
            :disabled="busyRef" @click="onRemove">
            Remove from group
          </button>
          <button type="button" v-if="canViewShareRequestRef" class="btn btn-outline-primary secondary-action invite"
            :disabled="busyRef" @click="onViewShareRequest">
            <img src='@/assets/icons/mail_gray_60.png' />
            View share request
          </button>
          <button type="button" v-if="canViewInviteRef" class="btn btn-outline-primary secondary-action invite"
            :disabled="busyRef" @click="onViewInvite">
            <img src='@/assets/icons/mail_gray_60.png' />
            View invitation
          </button>
          <button type="button" v-if="canAddMemberRef" class="btn btn-primary add-member"
            :disabled="busyRef" @click="onAddMember">
            Add as member
          </button>
          <button type="button" v-if="canShareRef" class="btn btn-primary"
            :disabled="busyRef" @click="shareProfile">
            Share profile with group
          </button>
          <button type="button" v-if="canRequestShareRef" class="btn btn-primary"
            :disabled="busyRef" @click="requestShareProfile">
            Request share profile
          </button>
          <div v-if="canInviteRef">
            <button type="button" class="btn btn-primary invite"
              :disabled="busyRef" @click="onInvite">
              <img src='@/assets/icons/mail_gray_60.png' />
              Invite
            </button>
          </div>
          <button type="button" class="btn btn-outline-primary"
            :disabled="busyRef" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.member-modal {
  .modal-header {
    padding: 1rem 1.5rem 0.75rem;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-column-gap: 0.5rem;
    
    .member-icon {
      grid-row: 1 / span 2;
      width: 30px;
      opacity: 20%;

      &.user {
        opacity: 60%;
      }

      @media (min-width: 768px) {
        width: 45px;
      }
    }

    h1 {
      margin: 0;
      font-size: 1.3rem;

      @media (min-width: 768px) {
        font-size: 1.5rem;
      }
    }

    .member-type {
      color: #888;
      font-size: 0.875rem;

      button {
        margin-left: 0.25rem;
        font-size: 0.75rem;
      }

      @media (min-width: 768px) {
        font-size: 1rem;
      }
    }
  }

  img.owner {
    margin-top: -3px;
    margin-right: 0.3rem;
    width: 1.2rem;
    filter: contrast(0.5) sepia(1) hue-rotate(5deg) saturate(3);
  }

  .role-info {
    color: #888;
    font-size: 0.875rem;
  }

  .modal-body {
    min-height: 210px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1.5rem 0.5rem;

    .tech-info {
      margin-bottom: -1rem;
    }
  }

  .alert {
    margin: 0;
  }

  .suggested-alert {
    margin-bottom: 2rem;
  }

  .scope-rel {
    display: grid;
    grid-template-columns: 2rem 1fr;
    align-items: center;
    color: #888;
    
    img {
      margin-top: -2px;
      margin-right: 0.25rem;
      width: 20px;
      filter: contrast(0.5) brightness(3);
    }

    &.scope-person img {
      filter: contrast(0.5) sepia(1) hue-rotate(130deg) brightness(2.0) saturate(3);
    }
  }

  .deceased {
    font-size: 0.875rem;
    color: #888;
  }

  .member-link {
    font-size: 0.875rem;
  }

  .member-task-list {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .member-task {
    display: grid;
    grid-template-columns: 1.75rem 1fr;
    line-height: 1.1rem;

    &:not(.done) {
      .task-done, .task-date, .task-actor {
        display: none;
      }
    }

    &.done .task-not-done {
      display: none;
    }

    .task-date, .task-actor {
      font-size: 0.75rem;
      color: #aaa;

      a {
        color: #aaa;

        &:hover {
          color: #333;
        }
      }
    }

    &.shared {
      svg {
        margin-top: 2px;
        margin-left: 1px;
        color: #ccc;
      }

      &.done svg {
        color: #666;
      }
    }

    &.invited {
      img {
        width: 1.2rem;
        opacity: 30%;
      }

      &.done img {
        opacity: 1;
        filter: brightness(0) saturate(100%) invert(63%) sepia(76%) saturate(5097%) hue-rotate(184deg) brightness(100%) contrast(94%);
      }
    }

    &.user-added {
      img {
        width: 1.2rem;
        opacity: 80%;
      }

      &.done img {
        opacity: 60%;
      }
    }
  }

  .member-joined {
    img {
      margin-top: 2px;
      margin-right: 0.25rem;
      width: 1rem;
    }
  }

  .member-history {
    font-size: 0.875rem;
  }

  .member-role {
    label {
      font-size: 0.875rem;
      color: #888;
    }

    .role-value {
      button {
        margin-left: 0.75rem;
        font-size: 0.875rem;
      }
    }
  }

  .child-option {
    margin-top: 1rem;

    .form-check-input {
      border-color: #bbb;
    }
  }

  .role-option {
    //font-size: 0.875rem;

    input[type="checkbox"] {
      margin-right: 0.25rem;
    }

    .recruiter-icon {
      margin-top: -5px;
      margin-left: 0.15rem;
      width: 1.1rem;
      opacity: 40%;
    }

    .option-info {
      font-size: 0.75rem;
      color: #888;
    }
  }

  .date-rel {
    font-size: 0.75rem;  
  }
  
  .modal-footer {
    padding: 1rem 1.5rem;

    button.invite img {
      margin-right: 0.5rem;
      width: 1.5rem;    
      filter: contrast(0.5) brightness(10);
    }

    button.btn-outline-primary.invite img {
      filter: brightness(0) saturate(100%) invert(63%) sepia(76%) saturate(5097%) hue-rotate(184deg) brightness(100%) contrast(94%);
    }

    .secondary-action {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { Person } from '@/rd/ResearchDataModel'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore'
import { useUserStore } from '@/gp/UserStore'
import { PotentialMember } from './FamilyGroupMemberManager'
import { DateTimeUtil } from '@/util/LuxonUtil'
import { notImplemented } from '@/util/AppUtil'
import { useViewFamilyStore } from '@/rd/ViewFamilyStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useRelationTextBuilder } from '@/rd/RelationPath'
import { isDefined } from '@/util/TypeScriptUtil'
import { CompositeId } from '@/rd/CompositeId'
import { TokenManager } from '@/auth/TokenManager'
import { capitalize } from '@/util/LanguageUtil'
import { InvitationResult,  GroupMemberRole, DataGroupMember, PrincipalType, ItemPermissions, User, FamilyGroupMember } from '@/gp/GroupAdminModel'
import { LoadMode } from '@/util/AsyncData'
import { Modal as BsModal } from 'bootstrap'
import { EyeIcon, InfoIcon, PlusIcon } from '@zhuowenli/vue-feather-icons'
import { PatchChange } from '@/util/Api'
import FamilyGroupRoleOption from './FamilyGroupRoleOption.vue'
import InfoButton from '@/util/InfoButton.vue'
import { useUserSupervisorStore } from '@/gp/UserSupervisorStore'

defineExpose({
  open,
  close,
})

const emit = defineEmits([
  'requestShare',
  'invite',
  'addUser',
  'viewShareRequest',
  'viewInvite',
  'remove',
])

const familyGroupIdRef = ref<string>()
const pmRef = ref<PotentialMember>()
const profileIdRef = ref<string>()
const forProfileRef = ref(false)
const isChildRef = ref(false)
const showHistoryRef = ref(false)
const showChildOptionInfoRef = ref(false)
const busyRef = ref(false)
const modalRef = ref<HTMLElement>()

const memberStore = useFamilyGroupMemberStore()
const dataGroupStore = useDataGroupStore()
const userStore = useUserStore()
const familyGroupStore = useFamilyGroupStore()
const familyGroupMemberStore = useFamilyGroupMemberStore()
const viewFamilyStore = useViewFamilyStore()
const viewPersonStore = useViewPersonStore()
const relationTextBuilder = useRelationTextBuilder()
const dataGroupMemberStore = useDataGroupMemberStore()
const userSupervisorStore = useUserSupervisorStore()

// basic data objects
const familyGroupRef = computed(() => familyGroupStore.getAsyncGroup(familyGroupIdRef.value)?.data) // loaded by parent
const scopeFamilyRef = computed(() => viewFamilyStore.getAsyncFamily(familyGroupRef.value?.scopeFamilyId)?.data) // loaded by parent
const scopeRelRef = computed(() => {
  if (personIsScopePersonRef.value) {
    return true // scope person
  }
  const commonAncestorId = scopeFamilyRef.value?.spouseIds?.at(0)
  if (profileIdRef.value && commonAncestorId) {
    return viewPersonStore.getLoadedRelationPath(commonAncestorId, profileIdRef.value) // related (in scope)
  }
  return undefined // not related (not in scope)
})
const profileGroupIdRef = computed(() => CompositeId.getGroupId(profileIdRef.value))
const profileGroupRef = computed(() => dataGroupStore.getAsyncGroup(profileGroupIdRef.value)?.data)
const profileOwnerRef = computed(() => userStore.getAsyncUser(profileGroupRef.value?.ownerId)?.data)
const profileGroupMemberRef = computed(() => {
  // NOTE: Get reactive proxy from store so it will be updated if we share/unshare the profile
  return forProfileRef.value
    ? dataGroupMemberStore.getMembersForDataGroups([profileGroupIdRef.value]).find(m => m.principalType == PrincipalType.FamilyGroup && m.principalId == familyGroupIdRef.value) 
    : dataGroupMemberStore.getMembersForFamilyGroups([familyGroupIdRef.value]).find(m => m.dataGroupId == profileGroupIdRef.value)
})
const sharedByRef = computed(() => userStore.getAsyncUser(profileGroupMemberRef.value?.modifiedUserId, LoadMode.EnsureLoaded)?.data)
const invitedByRef = computed(() => userStore.getAsyncUser(pmRef.value?.lastUserInvite?.senderUserId)?.data)
const profilePermissionsRef = computed(() => dataGroupStore.getAsyncPermissions(profileGroupIdRef.value, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None)
const groupPermissionsRef = computed(() => familyGroupStore.getAsyncPermissions(familyGroupIdRef.value)?.data ?? ItemPermissions.None) // loaded by parent
const supervisedUserIdsRef = computed(() => 
  TokenManager.isSupervised ? [] : userSupervisorStore.getForSupervisor(TokenManager.userId).map(su => su.forUserId!))

// basic data conditions
const isProfileAdminRef = computed(() => (profilePermissionsRef.value & ItemPermissions.Modify) > 0)
const isSelfProfileRef = computed(() => profileGroupIdRef.value == TokenManager.userProfileId)
const isUserSupervisorRef = computed(() => pmRef.value?.user && supervisedUserIdsRef.value.includes(pmRef.value.user.id!))
// isGroupOwner: (groupPermissionsRef.value & ItemPermissions.Owner) > 0
const canEditGroupRef = computed(() => (groupPermissionsRef.value & ItemPermissions.Modify) > 0)
const canAddMembersRef = computed(() => (groupPermissionsRef.value & ItemPermissions.AddItems) > 0)
const profileIsChildRef = computed(() => profileGroupRef.value?.isChild ?? false)
const personIsDeceasedRef = computed(() => !!pmRef.value?.viewPerson?.assumeDeceased)
const personIsScopePersonRef = computed(() => scopeFamilyRef.value?.spouseIds.some(id => id == pmRef.value?.viewPerson?.id) ?? false)
// profileInScope = !!scopeRelRef.value
const profileOwnedByGroupOwnerRef = computed(() => profileOwnerRef.value?.id == familyGroupRef.value?.ownerId)
const profileIsSharedRef = computed(() => !!profileGroupMemberRef.value)
const hasPendingShareRequestRef = computed(() => pmRef.value?.sharingInvite?.result == InvitationResult.Pending)
const hasPendingUserInviteRef = computed(() => pmRef.value?.lastUserInvite?.result == InvitationResult.Pending)
// memIsUser = pmRef.value?.user
const memIsGroupOwnerRef = computed(() => pmRef.value?.member?.role == GroupMemberRole.Owner)
const memCanInviteRef = computed(() => memIsGroupOwnerRef.value || pmRef.value?.member?.role == GroupMemberRole.Recruiter)
// memIsSupervised: not needed?
// memIsGroupMember = pmRef.value?.member
const memIsInvitedRef = computed(() => !!pmRef.value?.lastUserInvite || hasPendingUserInviteRef.value)

// UI conditions and values
const showRoleInfoRef = computed(() => !!pmRef.value?.member)
const memRoleInfoRef = computed(() => {
  switch (pmRef.value?.member?.role) {
    case GroupMemberRole.Owner:
      return "A group owner can view and manage all aspects of the group."
    case GroupMemberRole.Recruiter:
    case GroupMemberRole.Member:
      return "A group member can see other members and any profiles and trees shared with the group."
    case GroupMemberRole.Viewer:
      return "A group viewer can see the group itself, but not the group members, or shared profiles or trees."
    default:
      return undefined
  }
})

const sharedDateRef = computed(() => profileGroupMemberRef.value?.modifiedDate)
const inviteDateRef = computed(() => pmRef.value?.lastUserInvite?.lastSentDate)
const joinDateRef = computed(() => pmRef.value?.member?.createdDate)
const memTypeNameRef = computed(() => pmRef.value?.member?.role == GroupMemberRole.Owner ? "Group owner" : "Group member")
const scopeRelTextRef = computed(() => 
  (scopeRelRef.value == true) ? "Common ancestor for this group" :
  scopeRelRef.value ? `${capitalize(relationTextBuilder.getRelationText(scopeRelRef.value))} of common ancestors for this group` : 
  undefined
)

// allowedAction: user is allowed (has permission) to perform action, but not necessarily in current state
// canBeActioned: action is possible in current state, but not necessarily by current user
// canAction: action is possible AND user has permission

const allowedShareRef = computed(() => canAddMembersRef.value && (isProfileAdminRef.value || profileOwnedByGroupOwnerRef.value))
const canShareRef = computed(() => allowedShareRef.value && !profileIsSharedRef.value)
const canShareBeRequestedRef = computed(() => !profileIsSharedRef.value && !pmRef.value?.user && !hasPendingShareRequestRef.value)
const canRequestShareRef = computed(() => canShareBeRequestedRef.value && canAddMembersRef.value && !isProfileAdminRef.value)

const showShareTaskRef = computed(() => !pmRef.value?.member || showHistoryRef.value)

const canBeInvitedRef = computed(() => !profileGroupRef.value?.isChild && !pmRef.value?.member && !memIsInvitedRef.value && (pmRef.value?.user || (profileIsSharedRef.value && !personIsDeceasedRef.value)))
const canInviteUserRef = computed(() => canBeInvitedRef.value && pmRef.value?.user && canAddMembersRef.value)
const canInviteRef = computed(() => canBeInvitedRef.value && (pmRef.value?.user ? canInviteUserRef : allowedShareRef.value))
const showInviteTaskRef = computed(() => (memIsInvitedRef.value || canBeInvitedRef.value) && (!pmRef.value?.member || showHistoryRef.value))

const showAddUserTaskRef = computed(() => 
  (!pmRef.value?.user && isProfileAdminRef.value && profileGroupRef.value?.isChild) || // account not created yet
  (pmRef.value?.user && isUserSupervisorRef.value)) // user is supervised
const userAddedDateRef = computed(() => pmRef.value?.user?.createdDate)
const userAddedByRef = computed(() => userStore.getAsyncUser(pmRef.value?.user?.createdUserId, LoadMode.EnsureLoaded)?.data)
const showChildOptionRef = computed(() => !pmRef.value?.user && !personIsDeceasedRef.value && allowedShareRef.value && profileIsSharedRef.value)
const canAddUserRef = computed(() => isProfileAdminRef.value && !pmRef.value?.user && profileGroupRef.value?.isChild)

const showProfileOnlyRef = computed(() => profileIsSharedRef.value && !pmRef.value?.member && pmRef.value?.user && !memIsInvitedRef.value)
const canAddMemberRef = computed(() => profileIsSharedRef.value && !pmRef.value?.member && isUserSupervisorRef.value)

const canChangeRoleRef = computed(() => canEditGroupRef.value && !memIsGroupOwnerRef.value)
const showCanInviteOptionRef = computed(() => pmRef.value?.member)

const canProfileBeRemovedRef = computed(() => profileIsSharedRef.value && !pmRef.value?.member && !memIsInvitedRef.value)
const showRemoveProfileRef = computed(() =>  canProfileBeRemovedRef.value && (isProfileAdminRef.value || canEditGroupRef.value))

const canRemoveMemberRef = computed(() => pmRef.value?.member && canEditGroupRef.value && !memIsGroupOwnerRef.value)
const canViewShareRequestRef = computed(() => !profileIsSharedRef.value && hasPendingShareRequestRef.value)
const canViewInviteRef = computed(() => !pmRef.value?.member && hasPendingUserInviteRef.value)

function open(familyGroupId: string, pm: PotentialMember, profileId: string, forProfile = false) {
  familyGroupIdRef.value = familyGroupId
  pmRef.value = pm
  profileIdRef.value = profileId
  forProfileRef.value = forProfile

  isChildRef.value = profileGroupRef.value?.isChild ?? false
  showHistoryRef.value = true
  showChildOptionInfoRef.value = false

  // HACK: Not sure why getInstance isn't sufficient here, but sometimes it returns null because apparently the BS Modal
  // hasn't been initialized yet. Calling getOrCreateInstance seems to fix it.
  BsModal.getOrCreateInstance(modalRef.value!).show()
}

function close() {
  BsModal.getInstance(modalRef.value!)?.hide()
}

watch(isChildRef, async () => {
  if (profileGroupRef.value && isChildRef.value != profileGroupRef.value.isChild) {
    const change = new PatchChange('/isChild', isChildRef.value)
    await dataGroupStore.patchAsync(profileGroupIdRef.value!, [change])
  }
})

async function shareProfile() {
  try {
    busyRef.value = true

    const dm = new DataGroupMember()
    dm.principalType = PrincipalType.FamilyGroup
    dm.principalId = familyGroupIdRef.value
    dm.role = GroupMemberRole.Viewer

    await dataGroupMemberStore.addAsync(profileGroupIdRef.value!, dm)
  }
  finally {
    busyRef.value = false
  }  
}

function requestShareProfile() {
  emit('requestShare', profileIdRef.value)
}

function onRemove() {
  emit('remove', pmRef.value, profileIdRef.value)
}

async function onAddMember() {
  try {
    busyRef.value = true

    const fm = new FamilyGroupMember()
    fm.familyGroupId = familyGroupIdRef.value
    fm.userId = pmRef.value?.user?.id
    fm.role = GroupMemberRole.Member
    await familyGroupMemberStore.addAsync(fm)
  }
  finally {
    busyRef.value = false
  }
}

function onInvite() {
  emit('invite', profileIdRef.value)
}

function onAddUser() {
  emit('addUser', profileIdRef.value)
}

function onViewShareRequest() {
  emit('viewShareRequest', pmRef.value?.sharingInvite?.id)
}

function onViewInvite() {
  emit('viewInvite', pmRef.value, profileIdRef.value)
}

async function onCanInviteChanged(isRecruiter: boolean) {
  try {
    busyRef.value = true

    const member = pmRef.value?.member
    if (member && member.role != GroupMemberRole.Owner) {
      const newRole = isRecruiter ? GroupMemberRole.Recruiter : GroupMemberRole.Member
      if (newRole != member.role) {
        const change = new PatchChange('/role', newRole)
        await memberStore.patchAsync(member.key!, [change])
      }
    }
  }
  finally {
    busyRef.value = false
  }

}

</script>
