<template>
  <TaskPage class="signup-page" no-back :can-continue="canSignUpRef" @action="signUp">
    <template #title>Get started</template>
    <template #alert>
      <SignUpGrantAlert/>
    </template>
    <template #instruction>
      Please tell us your name and email address.
    </template>
    <div class="row">
      <div class="col-sm-6">
        <label for="givenNameInput" class="form-label">Given name</label>
        <input type="text" class="form-control" id="givenNameInput" ref="givenNameInputRef" :readonly="!canEditRef" v-model="signInStore.givenName"/>
      </div>
      <div class="col-sm-6">
        <label for="familyNameInput" class="form-label">Surname</label>
        <input type="text" class="form-control" id="familyNameInput" :readonly="!canEditRef" v-model="signInStore.familyName"/>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <label for="addressInput" class="form-label">Email address</label>
        <input type="text" class="form-control" id="addressInput" ref="addressInputRef" :readonly="!canEditRef" v-model="signInStore.address"/>
      </div>
    </div>
    <vue-turnstile :site-key="tsKey" :appearance="captchaAppearanceRef" theme="light" v-model="captchaCodeRef" style="display: flex"  />
    <template #secondaryTitle>
      <router-link to="/signin">I already have an account</router-link>
    </template>
  </TaskPage>
</template>

<style lang="scss" scoped>
.row {
  margin-bottom: 1rem;
}
</style>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { usePageTitle } from '@webapp/util/AppUtil'
import { useSignInStore } from '@webapp/auth/SignInStore'
import { IdentityApi, SignUpRequest } from '@webapp/auth/IdentityApi'
import { AddressUtil } from '@webapp/util/AddressUtil'
import { Environment } from '@webapp/util/Environment'
import TaskPage from '@webapp/util/TaskPage.vue'
import SignUpGrantAlert from '@webapp/auth/SignUpGrantAlert.vue'
import VueTurnstile from 'vue-turnstile'

usePageTitle("Sign up")

const signInStore = useSignInStore()
const router = useRouter()

const captchaCodeRef = ref("")
const canEditRef = computed(() => !busyRef.value)
const canSignUpRef = computed(() => !busyRef.value && !!(signInStore.givenName || signInStore.familyName) && AddressUtil.isValidEmail(signInStore.address) && !!(isDevEnv || captchaCodeRef.value))
const busyRef = ref(false)
const isDevEnv = Environment.isDevelopment()
const captchaAppearanceRef = computed(() => isDevEnv && router.currentRoute.value.query.forceCaptcha ? "always" : "interaction-only")
const tsKey = Environment.get('TURNSTILE_KEY')

const givenNameInputRef = ref<HTMLInputElement>()
const addressInputRef = ref<HTMLInputElement>()

async function signUp() {
  busyRef.value = true
  
  const request = {
    givenName: signInStore.givenName,
    familyName: signInStore.familyName,
    address: signInStore.address,
    captchaCode: captchaCodeRef.value,
  } as SignUpRequest

  const newIdent = await IdentityApi.signUpAsync(request)

  signInStore.subject = newIdent.subject ?? ""

  // store this so the next page knows to come back here if requested
  signInStore.isNewUser = true

  router.push("/signupapp")

  busyRef.value = false
}

onMounted(async () => {
  signInStore.couponCode = router.currentRoute.value.query.cc as string

  if (signInStore.givenName) {
    addressInputRef.value!.focus()
    addressInputRef.value!.select()
  }
  else {
    givenNameInputRef.value!.focus()
  }
})

</script>
