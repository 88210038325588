import { NewsItem } from "./NewsItem"
import { FamilyGroupInvite, User } from "@/gp/GroupAdminModel"
import { useUserStore } from '@/gp/UserStore'
import { useFamilyGroupInviteStore } from '@/gp/FamilyGroupInviteStore'
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import _ from "lodash"


export function useGroupAdminNews() {
    const familyGroupMemberStore = useFamilyGroupMemberStore()
    const familyGroupInviteStore = useFamilyGroupInviteStore()
    const userStore = useUserStore()

    async function getNewsAsync() {
        return (await Promise.all([
            getGroupInviteNewsAsync(),
            getGroupMemberNewsAsync(),
        ])).flat()
    }

    async function getGroupInviteNewsAsync() {

        const selfMembers = await familyGroupMemberStore.getMembersForUsersAsync()

        const getGroupInvites = selfMembers.map(m => familyGroupInviteStore.getInvitesForFamilyGroupAsync(m.familyGroupId))
        const groupInviteLists = await Promise.all(getGroupInvites)

        const senderIds = groupInviteLists.flatMap(list => list.map(inv => inv.senderUserId!))
        const senders = await userStore.getUserListAsync(senderIds)

        return groupInviteLists
            .map(list => _.groupBy(list, inv => inv.lastSentDate?.toLocal().startOf('day') + '|' + inv.senderUserId))
            .flatMap(invitesByDate => Object.values(invitesByDate)
                .map(list => inviteListToNewsItem(list, senders.find(s => s.id == list[0].senderUserId))))
    }

    function inviteListToNewsItem(list: FamilyGroupInvite[], sender: User | undefined) {
        const familyGroupStore = useFamilyGroupStore()
        const familyGroup = familyGroupStore.getAsyncGroup(list[0].familyGroupId!)!.data // should be loaded by FG member

        const timestamp = list[0].lastSentDate!

        if (list.length == 1) {
            return {
                id: `inv:${list[0].familyGroupId}:${list[0].id}`,
                type: 'FamilyGroupInvite',
                timestamp,
                displayText: `${sender?.displayName} invited ${list[0].recipientDisplayName } to join the ${familyGroup?.name} group`,
                url: familyGroup?.pageUrl
            } as NewsItem
        }

        return {
            id: `inv:${list[0].familyGroupId}:${timestamp.toLocal().toISODate()}`,
            type: 'FamilyGroupInvite',
            timestamp: timestamp.toLocal().startOf('day'),
            displayText: `${sender?.displayName} invited ${list.length} people to join the ${familyGroup?.name} group`,
            url: familyGroup?.pageUrl
        } as NewsItem
    }

    async function getGroupMemberNewsAsync() {
        return [] as NewsItem[]
    }

    return {
        getNewsAsync
    }
}
