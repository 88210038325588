import { DateTime } from "luxon"
import { VersionedData } from "@webapp/util/AsyncData"
import { CompositeId } from "./CompositeId"
import { isDefined } from "@webapp/util/TypeScriptUtil"
import { HistoricalDate } from "./HistoricalDate"
import { SortDate } from "./SortDate"
import { CountryCodes, GeoUtil } from '@webapp/util/GeoUtil'

export interface PersonLike {
    id?: string
    displayProperties: PersonDisplayProperties
    pageUrl?: string
}

export class Person implements PersonLike, VersionedData {
    id?: string
    modifiedUserId?: string
    modifiedDate?: DateTime
    userRestricted = false
    autoRestricted = false
    profile = false
    placeholder = false
    displayProperties = new PersonDisplayProperties()
    familyRelationships?: FamilyRelationship[]

    get groupId() { return CompositeId.getGroupId(this.id)}
    get displayName() { return this.displayProperties.displayName }
    get birthYear() { return this.displayProperties.birthDate.date1?.year }
    get pageUrl() { return Person.getPageUrl(this.id) }
    get viewPersonUrl() { return `/persons/${this.id}` }
    get restricted() { return this.userRestricted || this.autoRestricted }
    get deceased() { return !this.displayProperties.deathDate.isEmpty || this.displayProperties.deathPlaceId }
    get childRelationships() { return this.familyRelationships?.filter(r => r.role == FamilyRelationshipRole.Child) ?? [] }
    get spouseRelationships() { return this.familyRelationships?.filter(r => r.role == FamilyRelationshipRole.Father || r.role == FamilyRelationshipRole.Mother) ?? []}

    isChildInFamily(familyId: string) {
        return this.childRelationships.some(r => r.familyId == familyId)
    }

    static compareDisplayName(a: Person, b: Person) {
        return (a.displayName || '').localeCompare(b.displayName || '')
    }

    static getPageUrl(personId: string | undefined) { return `/profiles/${personId}` }
    static getMatchesPageUrl(personId: string | undefined) { return `/profiles/${personId}/matches` }
}

export class PersonDisplayProperties {
    name?: string
    gender?: Gender
    lifespan?: string
    birthDate = HistoricalDate.Empty
    birthSortDate = new SortDate()
    birthPlaceId?: string
    birthPlace?: Place
    deathDate = HistoricalDate.Empty
    deathSortDate = new SortDate()
    deathPlaceId?: string
    deathPlace?: Place
    living = false

    get displayName() { return PersonName.getDisplayName(this.name) }
}

export class PersonName {
    prefix?: string
    given?: string
    surname?: string
    suffix?: string

    constructor(nameXml?: string) {
        // use replace because you can get capturing groups from all matches
        nameXml?.replace(/<(\w+)>([^<]*)<\/\w+>/g, (match, elem, value) => {
            switch (elem) {
                case 'pf': this.prefix = value; break
                case 'g': this.given = value; break
                case 's': this.surname = value; break
                case 'sf': this.suffix = value; break
                default:
                    throw 'PersonName.UnknownNamePart'
            }
            return match // no-op -- we don't care about the result
        })
    }

    get givenNames() { return this.given?.split(' ') ?? [] }
    get surnames() { return this.surname?.split(' ') ?? [] }

    getValue() {
        // TODO: Allow user to customize display format and persist it
        const parts: string[] = []
        if (this.prefix) parts.push(`<pf>${this.prefix}</pf>`)
        if (this.given) parts.push(`<g>${this.given}</g>`)
        if (this.surname) parts.push(`<s>${this.surname}</s>`)
        if (this.suffix) parts.push(`<sf>${this.suffix}</sf>`)
        return parts.join(' ')
    }

    static getDisplayName(nameXml: string | undefined) {
        return nameXml
            ?.replace('<pf>', '').replace('</pf>', '')
            .replace('<g>', '').replace('</g>', '')
            .replace('<s>', '').replace('</s>', '')
            .replace('<sf>', '').replace('</sf>', '');
    }
   
}

export interface NewFamily {
    fatherId?: string
    motherId?: string
}

export interface FamilyLike {
    fatherId?: string
    motherId?: string
}

export class Family implements VersionedData, FamilyLike {
    id?: string
    fatherId?: string
    motherId?: string
    modifiedUserId?: string
    modifiedDate?: DateTime
    restricted = false
    displayProperties = new FamilyDisplayProperties()

    get groupId() { return CompositeId.getGroupId(this.id)}
    get spouseIds() { return [this.fatherId, this.motherId].filter(isDefined) }
    get isComplete() { return isCompleteFamily(this) }

    hasSpouse(spouseId: string) { return hasSpouse(this, spouseId) }

    getSpouseOf(personId: string) { return spouseOf(this, personId) }

    getSpouseId(role: FamilyRelationshipRole) { return getSpouseId(this, role) }
    
    roleOf(spouseId: string) { return roleOfSpouse(this, spouseId) }

    static getDisplayName(fatherDisplayName: string | undefined, motherDisplayName: string | undefined) {
        return `${fatherDisplayName || "(unknown)"} and ${motherDisplayName || "(unknown)"}`
    }
}

export class FamilyDisplayProperties {
    marriageDate = HistoricalDate.Empty
    marriageSortDate = new SortDate()
    marriagePlaceId?: string
}

export function isCompleteFamily(family: FamilyLike) {
    return !!family.fatherId && !!family.motherId
}

function hasSpouse(family: FamilyLike, spouseId: string) {
    return family.fatherId == spouseId || family.motherId == spouseId    
}

function spouseOf(family: FamilyLike, personId: string) {
    if (family.fatherId == personId)
        return family.motherId
    if (family.motherId == personId)
        return family.fatherId
}

function getSpouseId(family: FamilyLike, role: FamilyRelationshipRole) {
    switch (role) {
        case FamilyRelationshipRole.Father: return family.fatherId
        case FamilyRelationshipRole.Mother: return family.motherId
    }
}

function roleOfSpouse(family: FamilyLike, spouseId: string) {
    if (family.fatherId == spouseId)
        return FamilyRelationshipRole.Father
    if (family.motherId == spouseId)
        return FamilyRelationshipRole.Mother
}

export interface NewAssertion {
    subjectId?: string
    subjectType?: ItemType
    assertionType?: string
    relatedItemId?: string
    relatedItemType?: ItemType
    value?: string
    date?: HistoricalDate
    sortDate?: SortDate
    placeId?: string
    siteId?: string
    restricted?: boolean
}

export class Assertion implements VersionedData {
    id?: string
    subjectId?: string
    subjectType = ItemType.Person
    assertionType = ''
    relatedItemId?: string
    relatedItemType?: ItemType
    value?: string
    date = HistoricalDate.Empty
    sortDate = SortDate.Empty
    placeId?: string
    siteId?: string
    modifiedUserId?: string
    modifiedDate?: DateTime
    restricted = false

    precedence?: AssertionPrecedence

    get groupId() { return CompositeId.getGroupId(this.id)}
    get sortValue() { return this.sortDate.isEmpty ? this.date.sortValue : this.sortDate.value }
    get defaultSortLast() { return Assertion.getDefaultSortLast(this.assertionType) }

    static comparePrecedence(a: Assertion, b: Assertion) {
        // missing precendence sorts FIRST (arbitrary convention)
        if (!a.precedence)
            return (b.precedence ? -1 : 0)
        if (!b.precedence)
            return 1
        return a.precedence.rank - b.precedence.rank
    }

    static getDefaultSortLast(type: string) {
        switch (type) {
            case AssertionType.Death:
            case AssertionType.Burial:
            case AssertionType.Cremation:
            case AssertionType.Cemetery:
                return true
        }
        return false
    }

    static compareDate(a: Assertion, b: Assertion) {
        // compute sort values once
        const aSortValue = a.sortValue
        const bSortValue = b.sortValue
        // sort missing dates FIRST (unless default sort last)
        if (!aSortValue) {
            return bSortValue ? (a.defaultSortLast ? 1 : -1) : 0 // a first (or last) or equal if both missing
        }
        if (!bSortValue) {
            return b.defaultSortLast ? -1 : 1 // b first (or last)
        }
        return aSortValue.localeCompare(bSortValue)
    }
}

export interface AssertionPrecedence {
    instanceId: number
    variationId: number
    rank: number
    confidence: ConfidenceLevel
    trustLevel: number
}

export enum ConfidenceLevel
{
    // NOTE: Numeric values ensure sorting behavior is explicitly defined
    High = 0,
    Medium = 1,
    Low = 2,
    Dismissed = 3,
}

export class AssertionTypeDefinition {
    type?: string
    name?: string
    useValue = false
    useDate = false
    useLocation = false
    subjectTypes: ItemType[] = []
    relatedItemTypes: ItemType[] = []
}

export class Place implements VersionedData {
    id?: string
    name?: string
    shortName?: string
    latitude?: number
    longitude?: number
    modifiedUserId?: string
    modifiedDate?: DateTime
    restricted = false

    get groupId() { return CompositeId.getGroupId(this.id)}
    get countryName() { return this.name?.split(',').pop()?.trim() }
    get countryCode() { return CountryCodes.fromName(this.countryName) }

    distanceTo(other: Place) {
        if (!this.latitude || !this.longitude || !other.latitude || !other.longitude) {
            return undefined
        }
        return GeoUtil.getDistance(this.latitude, this.longitude, other.latitude, other.longitude)
    }
}

export class Site implements VersionedData {
    id?: string
    description?: string
    placeId?: string
    latitude?: number
    longitude?: number
    modifiedUserId?: string
    modifiedDate?: DateTime
    restricted = false

    get groupId() { return CompositeId.getGroupId(this.id)}
}

export const livingWindowStart = DateTime.now().year - 110

export class PersonSet {
    id?: string
    memberIds: string[] = []
    modifiedUserId?: string
    modifiedDate?: DateTime

    get groupId() { return CompositeId.getGroupId(this.id) }
}

export class ViewPerson implements PersonLike {
    id?: string
    matchIds: string[] = []
    displayProperties = new PersonDisplayProperties()

    get displayName() { return this.displayProperties.displayName ?? "(unknown name)" }
    get birthYear() { return this.displayProperties.birthDate.date1?.year }
    get deathYear() { return this.displayProperties.deathDate.date1?.year }
    get pageUrl() { return this.id ? ViewPerson.getPageUrl(this.id) : undefined }
    get assumeDeceased() { 
        return !!this.deathYear || (!!this.birthYear && this.birthYear < livingWindowStart) // ignore living assertion
    }

    get groupIds() { return this.matchIds.map(id => CompositeId.getGroupId(id)!) } // better be valid ids! :)

    static getPageUrl(personId: string) { return `/persons/${personId}` }
}

export interface FamilyRelationship {
    familyId: string
    role: FamilyRelationshipRole
    childOrder?: number
}

export enum FamilyRelationshipRole {
    Father = "Father",
    Mother = "Mother",
    Child = "Child",
}

export class ViewFamily implements FamilyLike {
    id?: string
    fatherId?: string
    motherId?: string
    matchIds: string[] = []
    displayProperties = new FamilyDisplayProperties()

    get pageUrl() { return `/families/${this.id}` }
    get spouseIds() { return [this.fatherId, this.motherId].filter(isDefined) }
    get isComplete() { return isCompleteFamily(this) }
    get groupIds() { return this.matchIds.map(id => CompositeId.getGroupId(id)!) } // better be valid ids! :)

    hasSpouse(spouseId: string) { return hasSpouse(this, spouseId) }

    spouseOf(personId: string) { return spouseOf(this, personId) }

    getSpouseId(role: FamilyRelationshipRole) { return getSpouseId(this, role) }
    
    roleOf(spouseId: string) { return roleOfSpouse(this, spouseId) }

    static getDisplayName(father: ViewPerson | undefined, mother: ViewPerson | undefined) {
        return Family.getDisplayName(father?.displayName, mother?.displayName)
    }
}

export class Workspace {
    id?: string
    viewGroupId?: string
    clientDeviceInfo?: string
    dataFileType?: string
    dataFilePath?: string
    livingStartItemId?: string
    readonly syncOptions = new WorkspaceSyncOptions()
    ownerId?: string
    noProfiles = false
    modifiedDate?: DateTime
    modifiedUserId?: string
    lastSyncId?: string
    
    get dataFileBaseType() { return this.dataFileType?.split('.')[0] ?? 'Unknown' }
    get workspaceTypeInfo() { return workspaceTypeInfos.find(t => t.id == this.dataFileBaseType) }
    get dataFileFullName() { return this.dataFilePath?.split('\\').pop() }
    get dataFileName() { return this.dataFileFullName?.slice(0, this.dataFileFullName.lastIndexOf('.')) }
    get dataFileExt() { return this.dataFileFullName?.slice(this.dataFileFullName.lastIndexOf('.')) }
    get dataFileFolder() { return this.dataFilePath ? this.dataFilePath.slice(0, this.dataFilePath.lastIndexOf('\\')) : undefined }
    get clientDevice() { return JSON.parse(this.clientDeviceInfo ?? '{}')}
    get pageUrl() { return `/trees/${this.id}` }
}

export class WorkspaceSyncOptions {
    livingStartItemId?: string
    livingAutoInclude = false    
}

export type OS = "windows" | "mac"

export interface OSInfo {
    id: OS
    name: string
    versions: string
    icon: any
}

export const osInfos: OSInfo[] = [
    { 
        id: "windows", 
        name: 'Windows', 
        versions: "10, 11",
        icon: new URL('@/assets/logos/Windows_150.png', import.meta.url).href,
    },
    { 
        id: "mac", 
        name: 'Mac', 
        versions: "10",
        icon: new URL('@/assets/logos/Mac_150.png', import.meta.url).href,
    },
]

export interface WorkspaceTypeInfo {
    id: string
    description: string
    product: string
    infoUrl: string
    icon: any
    versions: string
    availableOn: OS[]
    supportedOn: OS[]
    plannedOn: OS[]
}

export const workspaceTypeInfos: WorkspaceTypeInfo[] = [
    {
        id: 'RootsMagic', 
        description: 'RootsMagic file',
        product: "RootsMagic",
        infoUrl: 'https://www.rootsmagic.com/',
        icon: new URL('@/assets/logos/RootsMagic_app.png', import.meta.url).href,
        versions: "7, 8, 9, 10",
        availableOn: ['windows', 'mac'],
        supportedOn: ['windows'],
        plannedOn: ['mac']
    },
    {
        id: 'Legacy', 
        description: 'Legacy Family Tree file', 
        product: "Legacy Family Tree", 
        infoUrl: 'https://www.legacyfamilytree.com/',
        icon: new URL('@/assets/logos/Legacy_app_circle.png', import.meta.url).href,
        versions: "8, 9",
        availableOn: ['windows'],
        supportedOn: ['windows'],
        plannedOn: [],
    },
    {
        id: 'FamilyTreeMaker', 
        description: 'Family Tree Maker file', 
        product: "Family Tree Maker", 
        infoUrl: 'https://www.mackiev.com/ftm/',
        icon: new URL('@/assets/logos/FamilyTreeMaker_app.png', import.meta.url).href,
        versions: "2019",
        availableOn: ['windows', 'mac'],
        supportedOn: [],
        plannedOn: ['windows', 'mac'],
    },
    { 
        id: 'MacFamilyTree', 
        description: 'MacFamilyTree file', 
        product: "MacFamilyTree", 
        infoUrl: 'https://www.syniumsoftware.com/macfamilytree',
        icon: new URL('@/assets/logos/MacFamilyTree_app.png', import.meta.url).href,
        versions: "11",
        availableOn: ['mac'],
        supportedOn: [],
        plannedOn: [],
    },
    { 
        id: 'FamilyHistorian', 
        description: 'Family Historian file', 
        product: "Family Historian", 
        infoUrl: 'https://www.family-historian.co.uk/',
        icon: new URL('@/assets/logos/FamilyHistorian_app.png', import.meta.url).href,
        versions: "7",
        availableOn: ['windows'],
        supportedOn: [],
        plannedOn: ['windows'],
    },
    {
        id: 'AncestralQuest', 
        description: 'Ancestral Quest file', 
        product: "Ancestral Quest", 
        infoUrl: 'https://www.ancquest.com',
        icon: new URL('@/assets/logos/AncestralQuest_app.jpg', import.meta.url).href,
        versions: "16",
        availableOn: ['windows', 'mac'],
        supportedOn: [],
        plannedOn: [],
    },
    // {
    //     id: 'Reunion', 
    //     description: 'Reunion file', 
    //     product: "Reunion", 
    //     infoUrl: 'https://www.leisterpro.com/',
    //     icon: new URL('@/assets/logos/Reunion_app.png', import.meta.url).href,
    //     versions: "14",
    //     availableOn: ['mac'],
    //     supportedOn: [],
    //     plannedOn: [],
    // },
    {
        id: 'Gramps', 
        description: 'Gramps file', 
        product: "Gramps", 
        infoUrl: 'https://gramps-project.org',
        icon: new URL('@/assets/logos/Gramps_app.png', import.meta.url).href,
        versions: "5.2",
        availableOn: ['windows', 'mac'],
        supportedOn: [],
        plannedOn: [],
    },
    {
        id: 'PAF', 
        description: 'PAF file', 
        product: "Personal Ancestral File", 
        infoUrl: 'https://www.familysearch.org/en/blog/personal-ancestral-file-paf-is-discontinued',
        icon: new URL('@/assets/logos/PAF_app.png', import.meta.url).href,
        versions: "5.2",
        availableOn: ['windows'],
        supportedOn: [],
        plannedOn: [],
    },
]

export function isWorkspaceTypeSupportedOn(workspaceTypeId: string, os?: OS) {
    const info = workspaceTypeInfos.find(i => i.id == workspaceTypeId)
    return !!os && !!info?.supportedOn.includes(os)
}

export function isWorkspaceTypePlannedOn(workspaceTypeId: string, os?: OS) {
    const info = workspaceTypeInfos.find(i => i.id == workspaceTypeId)
    return !!os && !!info?.plannedOn.includes(os)
}

export const currentOS: OS | undefined = 
    /Win/.test(navigator.userAgent) ? 'windows'  : 
    /Mac/.test(navigator.userAgent) ? 'mac' :
    undefined

export enum MapTo
{
    Client,
    Server,
}

export class Sync {
    id?: string
    workspaceId?: string
    state = SyncState.Active
    completedDate?: DateTime
    createdDate?: DateTime
    createdUserId?: string
    workspace?: Workspace
}

export enum SyncState
{
    Active,
    Completing,
    Cancelled,
    Failed,
    Completed,
}

export class TrackingLink {
    itemType = ItemType.Person
    itemId?: string
    trackedItemId?: string
    createdDate?: DateTime
    createdUserId?: string
}

export interface ScopeStatistics {
    groupId: string
    personCount: number
    familyCount: number
    assertionCount: number
    placeCount: number
    siteCount: number
}

export enum Gender
{
    Male = "Male",
    Female = "Female",
}

export enum ItemType
{
    Person = "Person",
    Family = "Family",
    Assertion = "Assertion",
    Place = "Place",
    Site = "Site",
    AssertionSubject = "AssertionSubject",
    Content = "Content",
    ContentTag = "ContentTag",
}

export enum AssertionType {
    Birth = "Birth",
    Gender = "Gender",
    Name = "Name",
    Child = "Child",
    Death = "Death",
    Christen = "Christen",
    Burial = "Burial",
    Cremation = "Cremation",
    Adoption = "Adoption",
    Baptism = "Baptism",
    BarMitzvah = "BarMitzvah",
    BasMitzvah = "BasMitzvah",
    Blessing = "Blessing",
    ChristenAdult = "ChristenAdult",
    Confirmation = "Confirmation",
    FirstComm = "FirstComm",
    Ordination = "Ordination",
    Naturalization = "Naturalization",
    Emigration = "Emigration",
    Immigration = "Immigration",
    Census = "Census",
    Probate = "Probate",
    Will = "Will",
    Graduation = "Graduation",
    Retirement = "Retirement",
    Description = "Description",
    Education = "Education",
    Nationality = "Nationality",
    Employment = "Employment",
    Occupation = "Occupation",
    Property = "Property",
    Religion = "Religion",
    Residence = "Residence",
    SSN = "SSN",
    LDSBaptism = "LDSBaptism",
    LDSEndow = "LDSEndow",
    LDSSealPar = "LDSSealPar",
    AFN = "AFN",
    Ref = "Ref",
    Caste = "Caste",
    Title = "Title",
    LDSConf = "LDSConf",
    LDSInit = "LDSInit",
    Degree = "Degree",
    Military = "Military",
    Mission = "Mission",
    Stillborn = "Stillborn",
    Illness = "Illness",
    Living = "Living",
    Elected = "Elected",
    Excomm = "Excomm",
    Namesake = "Namesake",
    AltName = "AltName",
    DNA = "DNA",
    Misc = "Misc",

    Cemetery = "Cemetery",
    Circumcision = "Circumcision",
    Court = "Court",
    Email = "Email",
    Funeral = "Funeral",
    GodFather = "GodFather",
    GodMother = "GodMother",
    Health = "Health",
    Hobbies = "Hobbies",
    Honors = "Honors",
    Hospital = "Hospital",
    Land = "Land",
    Medical = "Medical",
    MilitaryAward = "MilitaryAward",
    MilitaryDischarge = "MilitaryDischarge",
    Obituary = "Obituary",
    Ordinance = "Ordinance",
    Pension = "Pension",
    PensionApp = "PensionApp",
    PhysDesc = "PhysDesc",
    School = "School",
    Travel = "Travel",
    URL = "URL",
    WillFiled = "WillFiled",

    // Family assertion types
    Marriage = "Marriage",
    Annulment = "Annulment",
    Divorce = "Divorce",
    DivorceFiled = "DivorceFiled",
    Engagement = "Engagement",
    MarrBanns = "MarrBanns",
    MarrContract = "MarrContract",
    MarrLicense = "MarrLicense",
    MarrSettlement = "MarrSettlement",
    LDSSealSp = "LDSSealSp",
    ResidenceFam = "ResidenceFam",
    CensusFam = "CensusFam",
    Separation = "Separation",

    MarrNotice = "MarrNotice",
}