<template>
  <div class="user-home-page">
    <div id="titleArea">
      <h1>
        <img src="@/assets/leaf-with-shadow.png"/>
        Let's explore your family
      </h1>
    </div>
    <div class="alerts container-md">
      <div v-for="id in joinedGroupIdsRef" :key="id" class="alert alert-success alert-dismissible">
        <div>Welcome! You just joined this group:</div>
        <FamilyGroupItem :family-group-id="id" small></FamilyGroupItem>
        <button type="button" class="btn-close" aria-label="Close" @click="dismissJoinedGroupAlert(id)"></button>
      </div>
      <div v-if="showUploadActionRef" class="alert alert-success upload-alert">
        <div>Upload your family tree to get started!</div>
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#uploadModal">
          {{ uploadActionTextRef }}
        </button>
      </div>
    </div>
    <section class="activity-gallery container-md">
      <div class="gallery-row row">
        <div class="gallery-item col-md-4">
          <div class="gallery-item-header">
            <h3>What's new</h3>
          </div>
          <NewsList></NewsList>
        </div>
        <div class="gallery-item col-md-4">
          <div id="exploreHeader" class="gallery-item-header">
            <h3>Explore</h3>
          </div>
          <div class="fixed-item-grid">
            <router-link id="exploreFamily" :to="selfViewPersonRef?.pageUrl ?? '#home'" class="image-link" 
              :class="{ disabled: !exploreStore.enableExploreRef }">
              <img src="@/assets/tree_summer_256.jpg" />
              <label>Family</label>
            </router-link>
            <a id="exploreNames" href="#" class="image-link disabled">
              <img src="@/assets/icons/nametag_gray_60.png" />
              <label>Names</label>
            </a>
            <a id="explorePlaces" href="#" class="image-link disabled">
              <div class="image-link-icon">
                <MapPinIcon size="40"></MapPinIcon>
              </div>
              <label>Places</label>
            </a>
          </div>
        </div>
        <div class="gallery-item col-md-4">
          <div id="shareHeader" class="gallery-item-header">
            <h3>Share</h3>
          </div>
          <ul class="fixed-item-list list-unstyled">
            <li>
              <a id="inviteFamily" class="disabled" href="#">
                <img src="@/assets/icons/mail_gray_60.png" />
                <div>Invite family</div>
              </a> 
            </li>
            <li>
              <a id="addToProfile" class="disabled" href="#">
                <img src="@/assets/icons/person_gray_150.png" />
                <div>Add to my profile</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="gallery-row row">
        <div class="gallery-item col-md-4">
          <div id="highlightsHeader" class="gallery-item-header">
            <h3>Fun facts</h3>
          </div>
          <HighlightsList></HighlightsList>
        </div>
        <div class="gallery-item col-md-4">
          <div id="todayHeader" class="gallery-item-header">
            <h3>
              <span>On this day</span>
              <span class="today">{{ DateTime.local().toLocaleString({ month: 'short', day: 'numeric' })}}</span>
            </h3>
          </div>
          <TodayList></TodayList>
        </div>
        <div class="gallery-item col-md-4">
          <div class="gallery-item-header">
            <h3>Learn</h3>
          </div>
          <div class="fixed-item-list">
            <!-- <div><a href="#">How do I start research?</a></div>
            <div><a href="#">How do I learn about places?</a></div> -->
            <div><a class="disabled" href="#">Ask the family expert</a></div>
            <div><a class="disabled" href="#">Ask the family bot</a></div>
            <div><a class="disabled" href="#">Games</a></div>
            <div><a class="disabled" href="#">Resources</a></div>
            <!-- <div><a href="#">Partners</a></div>
            <div><a href="#">Roadmap</a></div> -->
          </div>
        </div>
      </div>
      <div v-if="false" class="gallery-row row">
        <div class="gallery-item col-md-4">
          <div id="recentHeader" class="gallery-item-header">
            <h3>Recent</h3>
          </div>
          <RecentList></RecentList>
        </div>
      </div>
    </section>
    <UserNameConfirmModal ref="userNameConfirmModalRef"></UserNameConfirmModal>
    <ResearchGroupUploadModal id="uploadModal"/>
  </div>
</template>

<style lang="scss" scoped>
#titleArea {
  padding: 1rem;
  text-align: center;
  color: #aaa;
  
  h1 {
    font-size: 2rem;
    font-weight: 200;
  }

  img {
    display: none;
    height: 3rem;
  }
}

@media (min-width: 768px) {
  #titleArea {
    padding: 2rem;

    h1 {
      font-size: 3rem;
    }

    img {
      display: inline-block;
      height: 6rem;
    }
  }
}

.alerts {
  font-size: 0.875rem;
  color: #888;

  .family-group-item {
    margin: 0.25rem 0;
  }
}

@media (min-width: 768px) {
  .alerts {

    .family-group-item {
      margin: 0;
    }
  }
}

.upload-alert {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  align-items: center;

  button {
    margin-left: auto;
  }
}

section {
  margin-bottom: 2rem;
}

.self-section {
  background-color: #ffe1b1;
  padding: 1.5rem 0;

  .container-md {
    display: flex;
    justify-content: center;
  }

  .person-card {
    flex: 1;
    max-width: 600px;
    margin: 0 1rem;
  }
}

@media (min-width: 768px) {
  .activity-gallery, .row {
    --bs-gutter-x: 3rem;
  }
}

.gallery-item{
  margin-bottom: 3rem;

  .gallery-item-header{
    margin-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
    display: flex;

    img, svg {
      width: 2rem;
      align-self: center;
      margin-top: 0.25rem;
      margin-right: 0.75rem;
    }
    
    h3 {
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      color: #aaa;
    }
  }
}

.gallery-item-header h3 .today {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.fixed-item-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0.5rem;
  row-gap: 1rem;
}

.fixed-item-list {
  li a {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    color: #333;
    
    img {
      height: 2rem;
    }
  }
}

a.disabled {
  pointer-events: none;
  color: #aaa !important;

  img, .image-link-icon {
    filter: grayscale(1);
    opacity: 25% !important;
  }
}

.image-link {
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  color: #333;

  img {
    height: 3rem;
  }

  .image-link-icon {
    height: 3rem;
    padding-top: 0.3rem;
  }

  label {
    padding: 0.25rem 0;
    cursor: pointer;
  }

  &.start label {
    border: 1px solid var(--bs-primary);
    border-radius: 0.3rem;
    background-color: var(--bs-primary);
    padding: 0.25rem 0.5rem;
    color: white;
    //border-bottom: 6px solid var(--bs-primary);
  }
}

#exploreHeader svg {
  stroke: #2e6ca4;
  transform: rotate(90deg);
}

#exploreFamily img {
  width: 48px;
  height: 48px;
  transform: scale(1.1);
//   // #93b67b via https://angel-rs.github.io/css-color-filter-generator/
//   //filter: brightness(0) saturate(100%) invert(67%) sepia(27%) saturate(421%) hue-rotate(52deg) brightness(95%) contrast(93%);
}

#exploreNames img {
  width: 36px;
  height: inherit;
  padding: 0.35rem 0;
  // #8099c6 via https://angel-rs.github.io/css-color-filter-generator/
  //filter: brightness(0) saturate(100%) invert(89%) sepia(29%) saturate(5576%) hue-rotate(186deg) brightness(83%) contrast(83%);  
}

#explorePlaces svg {
  stroke: white;
  fill: #888;// #cc4e44;
}

#inviteFamily img {
  //filter: brightness(0) saturate(100%) invert(44%) sepia(72%) saturate(1941%) hue-rotate(184deg) brightness(100%) contrast(94%);
}

#addToProfile img {
  opacity: 80%;
}
</style>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { usePageTitle } from '@/util/AppUtil'
import { useUserStore } from "@/gp/UserStore"
import { useDataGroupStore } from "@/gp/DataGroupStore"
import { useViewPersonStore } from "@/rd/ViewPersonStore"
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore'
import { DataGroupType, User } from '@/gp/GroupAdminModel'
import { TokenManager } from '@/auth/TokenManager'
import { MapPinIcon } from '@zhuowenli/vue-feather-icons'
import { LoadMode } from '@/util/AsyncData'
import { useExploreStore } from './ExploreStore'
import { useUploadAction } from '@/site/UploadAction'
import { DateTime } from 'luxon'
import NewsList from "@/explore/news/NewsList.vue"
import HighlightsList from "@/explore/highlights/HighlightsList.vue"
import TodayList from "@/explore/today/TodayList.vue"
import RecentList from "@/explore/recent/RecentList.vue"
import UserNameConfirmModal from "@/manage/UserNameConfirmModal.vue"
import FamilyGroupItem from '@/manage/FamilyGroupItem.vue'
import ResearchGroupUploadModal from '@/manage/ResearchGroupUploadModal.vue'

const selfRef = ref<User>()

const userStore = useUserStore()
const viewPersonStore = useViewPersonStore()
const dataGroupStore = useDataGroupStore()
const exploreStore = useExploreStore()
const familyGroupMemberStore = useFamilyGroupMemberStore()
const { showUploadActionRef, uploadActionTextRef } = useUploadAction()

const selfPersonIdRef = computed(() => dataGroupStore.getAsyncGroup(selfRef.value?.profileDataGroupId, LoadMode.EnsureLoaded)?.data?.startItemId)
const selfViewPersonRef = computed(() => viewPersonStore.getAsyncPerson(selfPersonIdRef.value, LoadMode.EnsureLoaded)?.data)
const joinedGroupIdsRef = computed(() => {
  const allMembers = familyGroupMemberStore.getMembersForUsers(undefined, LoadMode.EnsureLoaded)
  const recentMembers = allMembers.filter(m => DateTime.utc().diff(m.createdDate!, 'days').days < 1)
  return recentMembers
    .map(m => m.familyGroupId!)
    .filter(id => !exploreStore.dismissedJoinedGroupIds.includes(id))
})

usePageTitle("Home")

const userNameConfirmModalRef = ref<InstanceType<typeof UserNameConfirmModal>>()

watch(selfPersonIdRef, async () => {
  if (selfPersonIdRef.value && !exploreStore.initialTreeLoaded) {
    await viewPersonStore.ensureAncestorsLoadedAsync(selfPersonIdRef.value, 5, 1)
    exploreStore.initialTreeLoaded = true
  }
}, { immediate: true })

onMounted(async () => {
  selfRef.value = await userStore.getAsyncSelf()?.whenLoadCompleted
  if (selfRef.value && !TokenManager.isSupervised && !selfRef.value.metadata?.nameConfirmed) {
    userNameConfirmModalRef.value?.open()
  }
})

function dismissJoinedGroupAlert(id: string) {
  exploreStore.dismissedJoinedGroupIds.push(id)
}
</script>