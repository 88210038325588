
const typeIcons: any = {
    "Name": new URL("@/assets/icons/nametag_gray_60.png", import.meta.url).href,
    "Birth": new URL("@/assets/icons/sunrise_gray_60.png", import.meta.url).href,
    "Residence": new URL("@/assets/icons/house_gray_60.png", import.meta.url).href,
    "Education": new URL("@/assets/icons/scroll_gray_60.png", import.meta.url).href,
    "Occupation": new URL("@/assets/icons/hammer_gray_60.png", import.meta.url).href,
    "Marriage": new URL("@/assets/icons/rings_gray_60.png", import.meta.url).href,
    "Death": new URL("@/assets/icons/sunset_gray_60.png", import.meta.url).href,
    "Burial": new URL("@/assets/icons/grave_gray_60.png", import.meta.url).href,
}

export class AssertionTypeIcons {
    static hasIcon(type?: string) {
        return !!type && !!typeIcons[type]
    }
    
    static getIcon(type?: string): string {
        return type ? typeIcons[type] : ''
    }
}
