<template>
  <div class="home-page">
    <section class="top-section ">
      <div class="container-lg">
        <div class="row">
          <div class="col-md-7 hero-cta">
            <!-- <img src="@/assets/tree-crossing-icon-256.png" class="brand"/> -->
            <img src="@/assets/logos/tree-crossing-logo-160h.png" class="brand"/>
            <h2>
              The safe, connected, and fun way to share family trees
            </h2>
            <!-- <div class="value-prop">
              Tree Crossing is the safe, connected, and fun way to to share your genealogy with your family.
            </div> -->
            <div class="actions">
              <router-link class="btn btn-primary" to="/signup">
                Try it free <ArrowRightIcon size="1.2x"></ArrowRightIcon>
              </router-link>
            </div>
          </div>
          <div class="col-md-5 hero-image">
            <div class="gradient"></div>
            <img src="@/assets/modern-bedtime-story_1024.jpg" alt="Parent sharing a family tree with a child">
          </div>
        </div>
      </div>
    </section>
    <section class="value-section container-lg">
      <HomePageValue></HomePageValue>
    </section>
    <section class="try-section" id="try">
      <div class="container-md">
        <h1>Try it for free</h1>
        <div class="row">
          <div class="col-md">
            <div class="try-step upload-step">
              <h3>1. Upload your tree</h3>
              <div ref="uploadStepIconRef" class="try-step-icon">
                <div class="status">Uploading...</div>
                <div class="status done">Done!</div>
                <div class="upload-total">
                  <div ref="uploadProgressRef" class="upload-progress"></div>
                </div>
              </div>
              <div class="try-step-body">
                It’s easy and fast.
                Most people can upload their tree in just a few minutes.
              </div>
            </div>
          </div>
          <div class="col-md">
            <div class="try-step explore-step">
              <h3>2. Explore it online</h3>
              <div class="try-step-icon">
                <div class="tree">
                  <img class="tree-arm" src="@/assets/icons/connected_tree_arm_150.png"/>
                  <img class="tree-arm" src="@/assets/icons/connected_tree_arm_150.png"/>
                  <img class="tree-base" src="@/assets/icons/connected_tree_base_150.png"/>
                  <canvas id="exploreAni" ref="exploreAniRef"></canvas>
                </div>
              </div>
              <div class="try-step-body">
                Once your tree is uploaded, you can start exploring right away.
              </div>
            </div>
          </div>
          <div class="col-md">
            <div class="try-step invite-step">
              <h3>3. Invite your family</h3>
              <div ref="inviteStepIconRef" class="try-step-icon">
                <div class="lines">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <MailIcon size="6x"></MailIcon>                
              </div>
              <div class="try-step-body">
                Whenever you're ready, start a <router-link to="/plans">free trial</router-link> so you can invite your family.
                It's that easy!
              </div>
            </div>
          </div>
        </div>
        <div class="row try-cta">
          <div class="col-md-12 text-center">
            <router-link to="/signup" class="btn btn-primary btn-lg">
              Get started <ArrowRightIcon size="1.2x"></ArrowRightIcon>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="no-tree-section">
      <h1>Don't have a tree?</h1>
      <div>
        Find someone in your family that does, and ask them to upload theirs.
        Or, use some of these <router-link to="/learn/research">amazing resources</router-link>
        to get started building your own family tree!
      </div>
    </section>    
    <section class="share-section">
      <h1>Share</h1>
      <div class="sharing-bar">
          <!-- Facebook sharing button -->
          <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.treecrossing.com" target="_blank">
            <img src="@/assets/logos/Facebook.png"/>
          </a>

          <!-- Twitter sharing button -->
          <a href="https://twitter.com/intent/tweet?url=https://www.treecrossing.com&text=The%20best%20way%20to%20share%20your%20family%20trees." target="_blank">
            <img src="@/assets/logos/x.png"/>
          </a>

      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.top-section {
  background-color: #ffe1b1;
}

.hero-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1.5rem 2rem;
  z-index: 1;
  text-align: center;

  img.brand {
    //display: none; // small screen has brand in header
    //width: 150px;
    max-width: 100%;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 2.5rem;
    //max-width: 500px;
  }

  .value-prop {
    padding: 0 1rem;
    margin-bottom: 1.5rem;
  }

  .actions {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  @media (min-width: 768px) { // above --bs-breakpoint-md
    padding: 2rem 1rem;

    img.brand {
      //display: inline-block; // img default
      max-width: 100%;
    }

    h2 {
      font-size: 1.5rem;
    }

    .actions .btn {
      font-size: 1.2rem;
    }

    .value-prop {
      padding: 0 3rem;
      font-size: 1.1rem;
    }
  }

  @media (min-width: 1200px) { // above --bs-breakpoint-xl
    padding: 2rem 2rem;

      h2 {
        font-size: 1.5rem;
      }
  }
}

.hero-image {
  padding: 0;
  position: relative;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #ffe1b1 ,#ffe1b1f8 5%, #ffe1b1e0 15%, transparent 70%);
    }
  }
}

.value-section {
  padding: 3rem 0;
}

.try-section {
  background-color: #deebf7;

  .container-md {
    max-width: 1000px;
  }

  h1 {
    padding-top: 2rem;
    font-size: 3rem;
    text-align: center;
  }

  .try-step {
    padding: 1.5rem 0;

    h3 {
      text-align: center;
    }

    .try-step-icon {
      height: 140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (min-width: 768px) {
        height: 160px;
      }
    }
  }

  .try-step-body {
    padding: 1rem 2rem;

    @media (min-width: 768px) {
      padding: 1rem 0;
      font-size: 1.2rem;
    }
  }
}

.upload-step {
  .try-step-icon {
    font-size: 0.75rem;
    color: #888;

    $animation-duration: 3s;

    .status {
      width: 100%;
      height: 0; // show both status at same position
      text-align: center;

      &.done {
        opacity: 0; // when not animating
      }
    }

    &.animate .status:not(.done) {
      animation: hide-at-end $animation-duration linear forwards;
    }    

    &.animate .status.done {
      animation: show-at-end $animation-duration linear forwards;
    }    

    .upload-total {
      margin-top: 1.5rem;
      width: 200px;
      height: 12px;
      border-radius: 6px;
      background-color: white;
      justify-self: center;
      overflow: hidden;

      .upload-progress {
        width: 100%;
        height: 100%;
        background-color: #10c400;
        transform-origin: left;
        transform: scaleX(0.7); // when not animating
      }
    }

    &.animate .upload-total .upload-progress {
      animation: progress $animation-duration ease forwards;
    }
  }
}

@keyframes progress {
  0% { transform: scaleX(0); }
  100% { transform: scaleX(1); }
}

@keyframes hide-at-end {
  0%, 99% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes show-at-end {
  0%, 99% { opacity: 0; }
  100% { opacity: 1; }
}

.explore-step {
  .try-step-icon {

    .tree {
      position: relative;
    }

    .tree-arm, .tree-base {
      position: absolute;
      transform: rotate(90deg);
      filter: brightness(0) saturate(100%) invert(70%) sepia(43%) saturate(705%) hue-rotate(353deg) brightness(97%) contrast(91%);
    }

    .tree-arm {
      left: 0px;
      width: 60px;
    }

    .tree-arm:nth-child(1) {
      top: -62px;
    }

    .tree-arm:nth-child(2) {
      top: 7px;
    }

    .tree-base {
      top: -25px;
      left: -60px;
      width: 75px;
    }

    #exploreAni {
      position: absolute;
      transform: translateX(-45%) translateY(-40%); // scale(1.3);
    }
  }
}

.invite-step {
  .try-step-icon {
    padding-right: 1.5rem;
    flex-direction: row !important;
    animation: fade-in 0.2s linear forwards; // come back gently after flyout

    &.animate {
      animation: skew-x 0.2s ease forwards, flyout-right 0.3s linear 0.2s forwards;

      .lines {
        animation: stretch-x 0.2s ease forwards;
      }
    }

    .lines {
      transform: scaleX(0);
      transform-origin: right;
      padding-top: 1rem;

      div {
        margin-bottom: 1rem;
        width: 2rem;
        height: 6px;
        border-radius: 3px;
        background-color: #1f94f1;
      }

      :nth-child(2) {
        margin-left: -0.2rem;
        width: 2.2rem;
      }
    }
    svg {
      stroke-width: 1.5px;
      filter: brightness(0) saturate(100%) invert(63%) sepia(76%) saturate(5097%) hue-rotate(184deg) brightness(100%) contrast(94%);
    }
  }
}

@keyframes stretch-x {
  0% { transform: scaleX(0); }
  100% { transform: scaleX(1); }
}

@keyframes skew-x {
  0% { transform: skewX(0); }
  100% { transform: skewX(-10deg); }
}

@keyframes flyout-right {
  0% { transform: translateX(0); opacity: 1; }
  100% { transform: translateX(400px); opacity: 0; }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.try-cta {
  padding: 1rem 0 3rem;
}

.no-tree-section {
  margin: 3rem auto 0;
  max-width: 800px;
  padding: 0 1rem;

  h1 {
    font-size: 2rem;
    text-align: center;
  }
}

.share-section {
  margin: 3rem auto 0;
  max-width: 800px;
  padding: 0 1rem;

  h1 {
    font-size: 2rem;
    text-align: center;
  }

  .sharing-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
  }

  .sharing-bar a {
      margin: 0 10px;
      text-decoration: none;
      color: #212529;

      img {
        width: 30px;
      }
  }

  .sharing-bar a:hover {
      color: #0d6efd;
  }
}
</style>

<script setup lang="ts">
import { usePageTitle } from '@/util/AppUtil';
import { ArrowRightIcon as ArrowRightIcon, MailIcon, FacebookIcon, TwitterIcon } from '@zhuowenli/vue-feather-icons'
import HomePageValue from '@/public/HomePageValue.vue'
import { ref, onMounted } from 'vue'
import { Rive,  } from '@rive-app/canvas'

usePageTitle('Home')

const uploadStepIconRef = ref<HTMLDivElement>()
const exploreAniRef = ref<HTMLCanvasElement>()
const inviteStepIconRef = ref<HTMLDivElement>()

let exploreRive: Rive

onMounted(() => {
  const context = exploreAniRef.value?.getContext('2d', { alpha: true })
  exploreRive = new Rive({
    src: '/explore-step.riv',
    canvas: exploreAniRef.value!,
    stateMachines: 'swerve',
    autoplay: true,
    onLoad: () => {
      exploreRive.resizeDrawingSurfaceToCanvas()
      startTryStepAnimations()
    },
  })
})

function startTryStepAnimations() {
  uploadStepIconRef.value?.classList.remove('animate')
  inviteStepIconRef.value?.classList.remove('animate')
  void uploadStepIconRef.value?.offsetWidth // force reflow

  const exploreInput = exploreRive.stateMachineInputs('swerve')[0]

  uploadStepIconRef.value?.classList.add('animate') // start at :00 (now), ends at :03
  setTimeout(() => exploreInput.fire(), 4000) // start at :04, ends at :09
  setTimeout(() => inviteStepIconRef.value?.classList.add('animate'), 10000) // start at :10, ends at :11

  setTimeout(() => startTryStepAnimations(), 14000) // restart at :14
}

</script>
