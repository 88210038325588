<template>
  <div class="accept-page" :class="{ loading: loadingRef }">
    <div v-if="loadingRef" class="loading-message">
      <h4><Spinner color="#bbb"></Spinner>Looking up your invitation...</h4>
    </div>
    <div v-else-if="differentUserRef || notMeRef">
      <h1>Oops, different person</h1>
      <div>This invitation is for someone else.</div>
    </div>
    <div v-else-if="alreadyAcceptedRef">
      <h1>Already accepted!</h1>
      <div>
        <p>This invitation from {{ senderUserRef?.displayName }} was accepted on {{ closedDateText }}.</p>
        <p v-if="authUserRef"><router-link :to="FamilyGroup.getPageUrl(inviteRef?.familyGroupId)">Go to group</router-link></p>
      </div>
    </div>
    <div v-else-if="showConfirmAddressRef" class="confirm-address">
      <h1>Invitation</h1>
      <div class="alert alert-warning">
        This invitation does not match your account.
      </div>
      <div>
        <label class="form-label">Invitation sent to</label>
        <div class="input-value">
          {{ recipientUserRef?.displayName ?? inviteRef?.recipientDisplayName }}
          ({{ inviteRef?.recipientEmail }})
        </div>
      </div>
      <div>
        <label class="form-label">Your account</label>
        <div class="input-value">
          {{ authUserRef?.displayName }} ({{ authUserRef?.email }})
        </div>
      </div>
      <div class="instruction">
        You should continue only if both addresses belong to you.
      </div>
      <button type="button" class="btn btn-primary" @click="addressConfirmedRef = true">Continue</button>
      <div class="actions">
        <button type="button" class="btn btn-inline btn-link" @click="notMeRef = true">
          No, this invitation is intended for someone else
        </button>
      </div>
    </div>
    <div v-else-if="inviteRef" class="accept">
      <div class="title">
        <img src="@/assets/logos/tree-crossing-logo-160h.png"/>
        <h1>You're invited!</h1>
        <div class="recipient">
          {{ recipientUserRef?.displayName ?? inviteRef.recipientDisplayName }}
          ({{ inviteRef.recipientEmail }})
        </div>
      </div>
      <div class="shared-tree">
        <div class="label">
          {{ senderUserRef?.displayName }} <span class="sender-email">({{ senderUserRef?.email }})</span> has invited you to start exploring your family tree.
        </div>
        <div v-if="workspaceDataGroupRef" class="invite-item">
          <img :src="workspaceProductIconRef"/>
          <div class="item-body">
            <div class="item-title">
              {{ workspaceNameRef }}
            </div>
            <div class="item-subtitle">
              Tree created by {{ workspaceOwnerRef?.displayName }} using {{ workspaceProductRef }}
            </div>
          </div>
        </div>
      </div>
      <div class="family-group">
        <div>
          You'll be joining this group:
        </div>
        <div class="invite-item">
          <img src="@/assets/icons/everyone_500.png"/>
          <div class="item-body">
            <div class="item-title">
              {{ familyGroupRef?.name }}
            </div>
            <div class="item-subtitle">
              Group managed by {{ groupOwnerRef?.displayName }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="inviteRef?.profileDataGroupId" class="profile">
        <div class="label">You've also been invited to take control of your own profile in the tree:</div>
        <div class="invite-item">
          <FamilyGroupInviteProfile :invite-id="inviteRef?.id">
          </FamilyGroupInviteProfile>
        </div>
      </div>
      <div v-if="!authUserRef && expiredRef" class="expired">
        <div v-if="!resentRef">
          <div class="alert alert-primary">
            Your invitation link has expired.
            To continue, we'll need to send you an updated invitation with a new link.
          </div>
          <button type="button" class="btn btn-primary" @click="resend">
            <Spinner v-if="busyRef"></Spinner>
            {{ resendTextRef }}
          </button>
          <!-- <div class="actions">
            <button type="button" class="btn btn-inline btn-link">I already have an account</button>
          </div> -->
        </div>
        <div v-else class="alert alert-success">
          We sent you an updated invitation with a new link.
        </div>
      </div>
      <div v-else class="join-action">
        <button type="button" class="btn btn-primary" @click="accept">
          Join group and explore my family tree
        </button>
        <div class="actions">
          <router-link v-if="!authUserRef && !recipientUserRef" :to="signInUrlRef">Join using my existing account</router-link>
          <router-link v-if="!authUserRef && !recipientUserRef" to="/" target="_blank">What is Tree Crossing?</router-link>
        </div>
      </div>
      <div class="terms">
        By accepting this invitation, you agree to our <router-link to="/terms">Terms</router-link> and <router-link to="/privacy">Privacy Policy</router-link>.
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.accept-page {
  max-width: 600px;
  margin: 2rem auto 0 auto;
  padding: 0 0.5rem;
  
  @media (min-width: 768px) {
    &:not(.loading) {
      margin-top: 4rem;
      min-height: 500px;
      border: 1px solid #ddd;
      border-radius: 1rem;
      padding: 1rem 2rem 2rem 2rem;
    }
  }

  .loading-message {
    margin-top: 15rem;
    text-align: center;
  }

  .confirm-address {
    .input-value {
      margin-bottom: 1.5rem;      
    }

    .instruction {
      margin: 1.5rem 0 1rem;
    }
  }

  .accept {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .title {
      display: flex;
      flex-direction: column;

      img {
        margin: 1.5rem 0;
        width: 270px;
        align-self: center;

        @media (min-width: 768px) {
          margin: 2.5rem 0;
          width: 320px;
        }
      }

      h1 {
        margin-bottom: 0;
      }

      .recipient {
        font-size: 0.875rem;
        color: #aaa;
      }
    }

    .sender-email {
      color: #888;
    }

    .invite-item {
      margin-top: 1rem;
      margin-left: 1rem;
      display: flex;
      align-items: center;

      img {
        width: 50px;
      }

      .item-body {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: column;
        align-content: center;  
      }

      .item-title {
        //font-size: 0.875rem;
        color: #666;
      }
      
      .item-subtitle {
        font-size: 0.75rem;
        color: #aaa;
      }
    }

    .family-group {
      .family-group-item {
        margin-left: 1rem;
      }
    }

    .profile {
      .invite-profile {
        margin-left: 1rem;
      }
    }

    .expired {
      margin-top: 1rem;

      .alert {
        margin-bottom: 1.5rem;
        padding: 0.75rem;
        font-size: 0.85rem;        
      }
    }

    .join-action {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .actions {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.875rem;
  }

  .terms {
    text-align: center;
    font-size: 0.75rem;
    color: #aaa;
  }
}
</style>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { usePageTitle } from '@/util/AppUtil'
import { FamilyGroup, InvitationResult } from '@/gp/GroupAdminModel'
import { useFamilyGroupInviteStore } from '@/gp/FamilyGroupInviteStore'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { workspaceTypeInfos } from '@/rd/ResearchDataModel'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { LoadMode } from '@/util/AsyncData'
import { TokenManager } from "@/auth/TokenManager"
import { DateTime } from 'luxon'
import { getSignInUrlWithRedirect } from '@/util/AppUtil'
import FamilyGroupInviteProfile from './FamilyGroupInviteProfile.vue'
import Spinner from '@/util/Spinner.vue'
import { isDefined } from '@/util/TypeScriptUtil'

const props = defineProps({
  inviteId: String,
  inviteCode: String,
})

usePageTitle("Invitation")

const inviteStore = useFamilyGroupInviteStore()
const userStore = useUserStore()
const familyGroupStore = useFamilyGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const dataGroupStore = useDataGroupStore()
const router = useRouter()

const inviteRef = computed(() => props.inviteId ? inviteStore.getAsyncInviteByCode(props.inviteId, props.inviteCode)?.data : undefined)

const expiredRef = computed(() => !!inviteRef.value?.expirationDate && inviteRef.value.expirationDate < DateTime.utc())
const authUserRef = computed(() => userStore.getAsyncSelf()?.data) // loaded on sign in
const senderUserRef = computed(() => userStore.getAsyncUser(inviteRef.value?.senderUserId)?.data) // loaded with invite
const recipientUserRef = computed(() => userStore.getAsyncUser(inviteRef.value?.recipientUserId)?.data) // loaded with invite
const familyGroupRef = computed(() => familyGroupStore.getAsyncGroup(inviteRef.value?.familyGroupId)?.data) // loaded with invite
const groupOwnerRef = computed(() => userStore.getAsyncUser(familyGroupRef.value?.ownerId)?.data) // loaded with invite
const workspaceDataGroupRef = computed(() => {
  // load research group(s) for this family group using invite code
  const dmKeyList = dataGroupMemberStore.getAsyncKeyListsForFamilyGroups([inviteRef.value?.familyGroupId], LoadMode.EnsureLoaded, props.inviteId, props.inviteCode).at(0)?.data
  const dms = dataGroupMemberStore.getLoadedMembers(dmKeyList?.keys ?? [])
  const dgs = dataGroupStore.getAsyncGroupList(dms.map(dm => dm.dataGroupId)).map(a => a.data).filter(isDefined) // should be loaded
  return dgs.length == 1 ? dgs[0] : undefined
})
const workspaceBaseTypeRef = computed(() => workspaceDataGroupRef.value?.workspaceType?.split('.')[0])
const workspaceProductRef = computed(() => workspaceTypeInfos.find(w => w.id == workspaceBaseTypeRef.value)?.product)
const workspaceNameRef = computed(() => workspaceDataGroupRef.value?.name ?? "(unnamed tree)")
const workspaceOwnerRef = computed(() => userStore.getAsyncUser(workspaceDataGroupRef.value?.ownerId)?.data) // should be loaded

const loadingRef = computed(() => !inviteRef.value)

const differentUserRef = computed(() => !!authUserRef.value?.id && !!inviteRef.value?.recipientUserId && inviteRef.value?.recipientUserId != authUserRef.value.id)
const differentAddressRef = computed(() => !!authUserRef.value?.id && inviteRef.value?.recipientEmail != authUserRef.value?.email)
const alreadyAcceptedRef = computed(() => inviteRef.value?.result == InvitationResult.Accepted)
const closedDateText = computed(() => inviteRef.value?.closedDate?.toLocaleString(DateTime.DATE_FULL))
const resendTextRef = computed(() => busyRef.value ? "Sending..." : "Send updated invitation")
const signInUrlRef = computed(() => getSignInUrlWithRedirect(inviteRef.value?.getAcceptUrl(props.inviteCode) ?? ''))

const addressConfirmedRef = ref(false)
const showConfirmAddressRef = computed(() => differentAddressRef.value && !addressConfirmedRef.value && !notMeRef.value)
const notMeRef = ref(false)

const productLogos: any = { // 'any' prevents TS from complaining about arbitrary string as index
  "RootsMagic": new URL('@/assets/logos/RootsMagic_app.png', import.meta.url).href,
  "Legacy": new URL('@/assets/logos/Legacy_app_circle.png', import.meta.url).href,
}

const workspaceProductIconRef = computed(() => productLogos[workspaceBaseTypeRef.value ?? 'unknown'])

const resentRef = ref(false)
const busyRef = ref(false)

async function resend(e: Event) {
  e.preventDefault()
  busyRef.value = true
  await inviteStore.resendAsync(props.inviteId!, props.inviteCode!)
  resentRef.value = true
  busyRef.value = false
}

async function accept(e: Event) {
  e.preventDefault()
  const tokenResponse = await inviteStore.acceptAsync(props.inviteId!, props.inviteCode!)

  if (tokenResponse) {
    TokenManager.saveToken(tokenResponse)
    window.location.href = "/home" // hard navigate after signing in to reload app
  }
  else {
    router.push('/home') // soft navigate if already authenticated
  }
}

/*
GET treexg.com/familygroupinvites/:id?code=123&expand=...
<- confirm page
GET gp.treexg.com/familygroupinvites/:id?code=123
GP: GET id.treexg.com/identities/$secureLookup [lookup recipient identity by address, fill in recipientUserId if known]
If no auth, confirm: Create new account or sign in (already have an account)?
If already have account, sign in using normal method, return to page with app token
If profile, confirm: Is this profile you?
-- does auth user match profile? either could be "wrong". Which to fix?
If no, give option to cancel or sign in as self
If sign in as self, sign in using normal method, return to page with correct app token
Show: [sender] has invited you to join [family group]
Confirm: Join group?
[if YES]
if user exists, gp issues app token directly w/o involving identity service
if no user, gp creates or looks up identity, then issues app token directly
GET id.treexg.com/
*/

</script>