<template>
  <div>
    <label class="form-label">Share with</label>
    <div>
      <GlobeIcon v-if="props.value == PrivacyLevel.Everyone"></GlobeIcon>
      <UsersIcon v-else-if="props.value == PrivacyLevel.Groups"></UsersIcon>
      <HeartIcon v-else-if="props.value == PrivacyLevel.Friends"></HeartIcon>
      <LockIcon v-else-if="props.value == PrivacyLevel.Me"></LockIcon>
      <span class="ms-2 input-value">{{ displayText }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { PrivacyLevel } from '@/gp/GroupAdminModel'
import { GlobeIcon, UsersIcon, HeartIcon, LockIcon } from '@zhuowenli/vue-feather-icons'

const props = defineProps<{
  value: PrivacyLevel
}>()

const displayText = computed(() => props.value)
</script>
