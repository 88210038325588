import { reactive } from 'vue'
import { Family, FamilyDisplayProperties, NewFamily } from "./ResearchDataModel"
import { rd } from './ResearchDataApi'
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore, VersionedKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import _ from "lodash"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"
import { HistoricalDate } from './HistoricalDate'
import { SortDate } from './SortDate'
import { PatchChange } from '@webapp/util/Api'
import { usePlaceStore } from './PlaceStore'
import { useViewFamilyStore } from './ViewFamilyStore'
import { useViewPersonStore } from './ViewPersonStore'

export const useFamilyStore = defineStore("familyStore", () => {
    const asyncFamilies = reactive(new Map<string, AsyncData<Family>>())
    const asyncAssertionKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())

    const placeStore = usePlaceStore()
    const viewFamilyStore = useViewFamilyStore()
    const viewPersonStore = useViewPersonStore()
    
    function getAsyncFamily(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncFamilyList([id], loadMode)[0] : undefined
    }

    function getAsyncFamilyList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadFamiliesAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainFamilies = await rd.getPlainByIdsAsync("families", idsToLoad)
            return processExpandedFamilies(plainFamilies, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncFamilies, ids, "Family", loadMode, loadFamiliesAsync)
    }

    async function getFamilyListAsync(ids: (string | undefined)[]) {
        const asyncFamilies = getAsyncFamilyList(ids, LoadMode.EnsureLoaded)
        return (await Promise.all(asyncFamilies.map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    function getLoadedFamily(id: string | undefined) {
        return getLoadedFamilyList([id]).at(0)
    }

    function getLoadedFamilyList(ids: (string | undefined)[]) {
        return ids.map(id => asyncFamilies.get(id ?? '')).map(a => a?.data).filter(isDefined)
    }

    function getAsyncAssertionKeyLists(familyIds: string[], loadMode = LoadMode.TrackOnly) {
        if (loadMode != LoadMode.TrackOnly) {
            throw `Load mode not implemented`
        }
        // ensure all requested key lists are present
        AsyncDataStore.getAsyncItemsToLoad(asyncAssertionKeys, familyIds, "Family.AssertionKeys", loadMode)
        // get all key lists (not just the ones that need loading)
        return familyIds.map(id => asyncAssertionKeys.get(id)!)
    }

    async function addAsync(groupId: string, family: NewFamily) {
        const addCoreAsync = async () => {
            const p = await rd.postPlainAsync(`groups/${groupId}/families`, family)
            // HACK: add this because we expect it to be present, but a new family never has any data, so an empty object is "correct"
            p.displayProperties = {}
            return plainToFamily(p)
        }
        return await AsyncDataStore.addToStoreMapAsync(asyncFamilies, "Family", f => f.id, addCoreAsync)
    }

    async function patchAsync(id: string, changes: PatchChange[]) {
        const patchCoreAsync = async (id: string, changes: PatchChange[]) => {
            const p = await rd.patchPlainAsync(`families/${id}`, changes);
            return plainToFamily(p);
        }
        await AsyncDataStore.patchFromStoreAsync(asyncFamilies, id, "Family", changes, patchCoreAsync)
        // TODO: clear affected cached items
    }

    async function deleteAsync(id: string) {
        const asyncFam = asyncFamilies.get(id)
        if (!asyncFam)
            throw `Family ${id} not found in store`

        const faVP = viewPersonStore.asyncViewPersons.get(asyncFam.data?.fatherId ?? '')?.data
        const moVP = viewPersonStore.asyncViewPersons.get(asyncFam.data?.motherId ?? '')?.data
        
        await rd.deleteAsync(`families/${id}`)
        
        AsyncData.setDeleted(asyncFamilies.get(id))
        AsyncData.invalidate(viewFamilyStore.asyncViewFamilies.get(id))
        if (faVP?.id) {
            AsyncData.invalidate(viewPersonStore.asyncSpouseFamilyKeys.get(faVP.id))
        }
        if (moVP?.id) {
            AsyncData.invalidate(viewPersonStore.asyncSpouseFamilyKeys.get(moVP.id))
        }
    }
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function addFamiliesToStore(plainFamilies: any[], requestTime: DateTime) {
        const familyMap = processExpandedFamilies(plainFamilies, requestTime)
        AsyncDataStore.addItemMapToStore(asyncFamilies, "Family", familyMap, requestTime)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function processExpandedFamilies(plainFamilies: any[], requestTime: DateTime) {
        // NOTE: family display properties may be missing
        processExpandedFamilyDisplayProperties(plainFamilies.map(p => p.displayProperties).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainFamilies, "Family", plainToFamily, f => f.id!)
    }

    function processExpandedFamilyDisplayProperties(plainDisplayProperties: any[], requestTime: DateTime) {
        placeStore.addPlacesToStore(plainDisplayProperties.map(p => p.marriagePlace).filter(isDefined), requestTime)
    }

    return {
        asyncFamilies,
        asyncAssertionKeys,

        getAsyncFamily,
        getAsyncFamilyList,
        getFamilyListAsync,
        getLoadedFamily,
        getLoadedFamilyList,
        getAsyncAssertionKeyLists,
        addAsync,
        patchAsync,
        deleteAsync,

        addFamiliesToStore,
        processExpandedFamilies,
        processExpandedFamilyDisplayProperties,
    }
})

function plainToFamily(p: any) {
    const family = assignExisting(new Family(), p)
    family.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
    family.displayProperties = plainToFamilyDisplayProperties(p.displayProperties)
    return family
}

export function plainToFamilyDisplayProperties(p: any) {
    const dp = new FamilyDisplayProperties()
    if (p) {
        assignExisting(dp, p)
        dp.marriageDate = HistoricalDate.parse(p.marriageDate)
        dp.marriageSortDate = SortDate.parse(p.marriageSortDate)
        dp.marriagePlaceId = p.marriagePlace?.id
    }
    return dp
}
