<template>
  <Modal ref="modalRef" :ok-disabled="!canShareRef" @ok="onShare">
    <template #title>
      Share a tree
    </template>
    <div class="subtitle">
      Choose a tree to share with this group.
      If the tree is managed by someone else, we'll help you create a sharing request.
    </div>
    <div class="family-group-list">
      <ul class="list-unstyled">
        <li v-for="dg in dataGroupsRef" :key="dg.id">
          <ResearchGroupCard 
            :data-group-id="dg.id" 
            small 
            select-mode 
            :selected-id="selectedDataGroupIdRef" 
            @selected="onSelected(dg.id!)">
          </ResearchGroupCard>
        </li>
      </ul>
    </div>
    <template #okText>{{ shareTextRef }}</template>
    <template #busyText>Sharing...</template>
  </Modal>
</template>

<style scoped>
.subtitle {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #888;
}

.family-group-list {
  min-height: 150px;
  ul {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>

<script setup lang="ts">
import { computed, ref } from "vue"
import { useDataGroupStore } from "@/gp/DataGroupStore"
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { LoadMode } from "@/util/AsyncData"
import { isDefined } from "@/util/TypeScriptUtil"
import { TokenManager } from "@/auth/TokenManager"
import { DataGroupMember, DataGroupType, PrincipalType } from "@/gp/GroupAdminModel"
import { hideModalAsync } from "@/util/AppUtil"
import Modal from '@/util/Modal.vue'
import ResearchGroupCard from '@/manage/ResearchGroupCard.vue'

const emit = defineEmits([
  'requestShare'
])

defineExpose({
  open
})

const familyGroupIdRef = ref<string>()
const excludeIdsRef = ref<string[]>([])
const selectedDataGroupIdRef = ref<string>()

const modalRef = ref<InstanceType<typeof Modal>>()

const dataGroupStore = useDataGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()

const dataGroupsRef = computed(() => dataGroupStore.getVisibleGroups(LoadMode.EnsureLoaded)
  .filter(dg => dg.groupType == DataGroupType.Research))
const canShareRef = computed(() => !!selectedDataGroupRef.value)
const selectedDataGroupRef = computed(() => dataGroupsRef.value.find(dg => dg.id == selectedDataGroupIdRef.value))
const isDataGroupOwnerRef = computed(() => selectedDataGroupRef.value?.ownerId == TokenManager.userId)
const shareTextRef = computed(() => (!selectedDataGroupRef.value || isDataGroupOwnerRef.value) ? "Share" : "Request share")

function open(familyGroupId: string, excludeIds?: string[]) {
  familyGroupIdRef.value = familyGroupId
  excludeIdsRef.value = excludeIds ?? []
  selectedDataGroupIdRef.value = undefined
  modalRef.value?.open()
}

function onSelected(dataGroupId: string) {
  if (dataGroupId == selectedDataGroupIdRef.value) {
    selectedDataGroupIdRef.value = undefined // unselect
  } else {
    selectedDataGroupIdRef.value = dataGroupId
  }
}

async function onShare() {
  if (selectedDataGroupIdRef.value && selectedDataGroupRef.value?.ownerId == TokenManager.userId) {
    // share now
    const member = new DataGroupMember()
    member.dataGroupId = selectedDataGroupIdRef.value
    member.principalType = PrincipalType.FamilyGroup
    member.principalId = familyGroupIdRef.value!
    await dataGroupMemberStore.addAsync(selectedDataGroupIdRef.value, member)
    modalRef.value?.close()
  }
  else {
    await hideModalAsync(modalRef.value!.$el)
    emit('requestShare', selectedDataGroupIdRef.value)
  }
}
</script>
