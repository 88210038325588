import { ReactiveUtil } from '@webapp/util/ReactiveUtil'
import { DateTime } from 'luxon'
import { isDefined } from './TypeScriptUtil'
import _ from 'lodash'
import { ApiError } from './Api'

/**
 * Data structure intended to be wrapped in a reactive proxy. Components can request data operations at any time
 * but requests that occur while another request is pending are ignored. Notification of changes (either direct
 * or as a result of a completed data operation) occurs via the proxy.
 */
export class AsyncData<T> {
    readonly key: string
    readonly typeName: string
    state = AsyncDataState.NotLoaded
    data?: T
    asOfDate?: DateTime

    readonly whenLoadCompleted: Promise<T | undefined> | T | undefined

    setWhenLoadCompleted(value: Promise<T | undefined> | T | undefined) { 
        (this as { whenLoadCompleted: Promise<T | undefined> | T | undefined }).whenLoadCompleted = value
    }

    constructor(key: string, typeName = '') {
        this.key = key
        this.typeName = typeName
    }

    static getRelatedKeys<T>(asyncDatas: AsyncData<T>[], getKey: (d: T) => string) {
        return asyncDatas.map(a => a.data).filter(isDefined).map(getKey)
    }

    /**
     * Gets a value indicating whether an attempt should be made to (re-)load this item, based on its current async state and 
     * the specified load mode. This method should be used to skip loading if a load or save is in progress, or if a previous 
     * load failed.
     */
    shouldLoad(loadMode: LoadMode = LoadMode.EnsureLoaded) {
        if (loadMode == LoadMode.TrackOnly)
            return false
        
        const reload = loadMode == LoadMode.Reload
        const reloadText = reload ? "should" : "should not"

        switch (this.state) {
            case AsyncDataState.NotLoaded:
                //console.log(`${this.typeName}[${this.key}]: ${this.state}, should load`)
                return true // initial load (nothing to lose)

            case AsyncDataState.LoadFailed:
            case AsyncDataState.NotFound:
            case AsyncDataState.Loaded:
                if (reload) {
                    console.log(`${this.typeName}[${this.key}]: ${this.state}, ${reloadText} reload`)
                }
                return reload // only if reload requested

            case AsyncDataState.Loading:
            case AsyncDataState.Saving: // includes an implicit load after save
                // already loading, so don't send another request
                //console.log(`${this.typeName}[${this.key}]: Already ${this.state}`)
                return false

            default:
                throw "AsyncData.UnknownState"
        }
    }

    get isLoaded() { return this.state == AsyncDataState.Loaded }

    /**
     * Gets a value indicating whether an attempt was made to load the data, and the attempt completed in the Loaded, NotFound, or LoadFailed state. 
     */
    get loadComplete() { return _.includes([AsyncDataState.Loaded, AsyncDataState.NotFound, AsyncDataState.LoadFailed], this.state) }

    /**
     * Tracks asynchronous loading of an item, updating the AsyncData structure (especially its state) before and after. State changes can 
     * be used to prevent concurrent requests from trying to update the same item.
     * @param asyncData reactive proxy of AsyncData structure being loaded
     * @param request promise that returns the data OR throws a data error
     */
    static trackLoad<T>(asyncData: AsyncData<T>, requestTime: DateTime, request: Promise<T>) {
        ReactiveUtil.checkIsMutableProxy(asyncData)
        checkNoRequestsPending(asyncData)

        const oldState = asyncData.state

        //console.log(`${asyncData.typeName}[${asyncData.key}]: Loading`)
        asyncData.state = AsyncDataState.Loading
        asyncData.setWhenLoadCompleted(request.then(data => {
            //console.log(`${asyncData.typeName}[${asyncData.key}]: Got data`) // ${JSON.stringify(loadedData)}`)

            AsyncData.finishLoading(asyncData, data, requestTime)
            return data
        }, err => {
            const errCode = (err instanceof ApiError) ? err.statusCode : undefined
            if (oldState == AsyncDataState.Loaded && errCode != DataError.NotFound) {
                // data is still valid, so revert to old state
                asyncData.state = oldState
            } else {
                // data is not valid (as far as we know), so enter failed state
                asyncData.state = (errCode == DataError.NotFound ? AsyncDataState.NotFound : AsyncDataState.LoadFailed)
                console.log(`${asyncData.typeName}[${asyncData.key}]: Load failed: ${err}`)
            }
            asyncData.setWhenLoadCompleted(asyncData.data) // discard promise
            
            // do not rethrow -- promise only indicates completion, not success
            return undefined
        }))
    }

    static load<T>(asyncData: AsyncData<T>, data: T, requestTime: DateTime) {
        ReactiveUtil.checkIsMutableProxy(asyncData)

        asyncData.state = AsyncDataState.Loading
        AsyncData.finishLoading(asyncData, data, requestTime)
    }

    private static finishLoading<T>(asyncData: AsyncData<T>, data: T, requestTime: DateTime) {
        if (!asyncData.data) {
            // not present, so assign loaded data directly
            asyncData.data = data
            //console.log(`${asyncData.typeName}[${asyncData.key}]: Loaded`)
        } else {
            // already present, so update existing data object
            Object.assign(asyncData.data, data)
            //console.log(`${asyncData.typeName}[${asyncData.key}]: Updated`)
        }
    
        asyncData.state = AsyncDataState.Loaded
        asyncData.asOfDate = requestTime
        asyncData.setWhenLoadCompleted(asyncData.data) // discard promise
    }

    /**
     * Tracks asynchronous saving of an item, updating the AsyncData structure (especially its state) before and after. State changes can
     * be used to prevent concurrent requests from trying to update the same item.
     */
    static async trackSaveAsync<T extends VersionedData>(asyncData: AsyncData<T>, request: () => Promise<T>) {
        ReactiveUtil.checkIsMutableProxy(asyncData)
        checkNoRequestsPending(asyncData)

        if (asyncData.state != AsyncDataState.NotLoaded && asyncData.state != AsyncDataState.Loaded) {
            throw "AsyncData.NothingToSave"
        }

        const oldState = asyncData.state
        try {
            console.log(`${asyncData.typeName}[${asyncData.key}]: Saving remote`)
            asyncData.state = AsyncDataState.Saving
            const response = await request()

            console.log(`${asyncData.typeName}[${asyncData.key}]: Saved remote`)

            console.log(`${asyncData.typeName}[${asyncData.key}]: Updating local`)
            Object.assign(asyncData.data!, response)

            console.log(`${asyncData.typeName}[${asyncData.key}]: Updated local`)
            asyncData.state = AsyncDataState.Loaded
            asyncData.asOfDate = response.modifiedDate
        } catch (err) {
            const errCode = (err instanceof ApiError) ? err.statusCode : undefined
            if (errCode == DataError.NotFound) {
                // data is no longer valid, so enter NotFound state
                asyncData.state = AsyncDataState.NotFound
            } else {
                // data is still valid (as far as we know), so revert to old state
                asyncData.state = oldState
                console.log(`${asyncData.typeName}[${asyncData.key}]: Save failed: ${err}`)
            }
        }
    }

    static async invalidate<T>(asyncData: AsyncData<T> | undefined) {
        if (asyncData) {
            ReactiveUtil.checkIsMutableProxy(asyncData)

            asyncData.state = AsyncDataState.NotLoaded
            asyncData.data = undefined
            asyncData.asOfDate = undefined
            asyncData.setWhenLoadCompleted(undefined)
        }
    }

    static async setDeleted<T>(asyncData: AsyncData<T> | undefined) {
        if (asyncData) {
            ReactiveUtil.checkIsMutableProxy(asyncData)

            asyncData.state = AsyncDataState.NotFound
            asyncData.data = undefined
            asyncData.asOfDate = undefined
            asyncData.setWhenLoadCompleted(undefined)        
        }
    }
}

function checkNoRequestsPending<T>(a: AsyncData<T>) {
    if (a.state == AsyncDataState.Loading || 
        a.state == AsyncDataState.Saving) {
        throw "AsyncData.RequestPending"
    }
}


export interface VersionedData {
    modifiedDate?: DateTime
}

export enum AsyncDataState {
    NotLoaded = "NotLoaded",
    Loading = "Loading",
    Loaded = "Loaded",
    Saving = "Saving",
    NotFound = "NotFound",
    LoadFailed = "LoadFailed",
}

export enum LoadMode {
    TrackOnly = "TrackOnly",
    EnsureLoaded = "EnsureLoaded",
    Reload = "Reload",
}

export enum DataError {
    BadRequest = 400, // HTTP 4xx
    Forbidden = 403, // HTTP 403
    NotFound = 404, // HTTP 404
    Conflict = 412, // HTTP 412
    Internal = 500, // HTTP 5xx
}
