<template>
  <div class="app-support" :class="{ center: props.center }">
    <div class="btn-group os-select" role="group" aria-label="Operating system button group">
      <template v-for="os in osInfos" :key="os.id">
        <input type="radio" class="btn-check" name="os" :id="os.id" autocomplete="off" :value="os.id" v-model="selectedOSRef"/>
        <label class="btn btn-outline-primary" :for="os.id">
          <img :class="os.id" :src="os.icon">
          {{ os.name }}
        </label>
      </template>
    </div>
    <div class="btn-group app-select">
      <div v-for="a in osAppInfosRef" :key="a.id">
        <input type="radio" v-if="props.selectMode" class="btn-check" 
          name="app"
          :id="a.id" 
          autocomplete="off" 
          :value="a.id" 
          v-model="selectedAppRef" 
          :disabled="!shouldEnableApp(a)">
        <label :class="getAppLabelClasses(a)" :for="a.id">
          <div v-if="!shouldEnableApp(a)" class="sticker">
            Coming soon
          </div>
          <img :src="a.icon">
          <div class="app-name">{{ a.product }}</div>
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-support {
  display: flex;
  flex-direction: column;
  align-items: start;

  &.center {
    align-items: center;
  }
}

.os-select {
  margin-top: 1rem;

  label {
    min-width: 100px;
    padding: 0.25rem 0.5rem;
    text-align: center;
    font-size: 0.875rem;
    color: #333;

    img {
      margin-top: -4px;
      width: 20px;
      filter: grayscale(100%);

      &.windows {
        filter: grayscale(100%) brightness(1.3);
      }

      &.mac {
        filter: invert(1) brightness(0.7);
      }
    }
  }

  input:not(:checked) + label {
    &:hover {
      color: #333;
    }

    &:not(:hover) {
      border-color: #bbb;
    }
  }

  input:checked + label {
    background-color: rgb(222, 235, 247);
    color: #333;

    img.windows {
      filter: none; //grayscale(100) brightness(2.0);
    }

    img.mac {
      filter: none; //grayscale(100) invert(1) brightness(2.0);
    }
  }
}

.app-select {
  margin-left: -0.5rem;
  min-height: 230px;
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: start;

  input {
    &:checked + label {
      background-color: rgb(222, 235, 247);
      color: #333;
    }

    &:not(:checked):not(:disabled) + label:hover {
      border-color: #bbb;
      color: #333;
    }

    &:disabled + label {
      opacity: 1;

      :not(.sticker) {
        opacity: 40%;
      }
    }
  }

  label {
    position: relative;
    width: 105px;
    padding: 0.5rem;
    border: 1px solid transparent; // take up space even when not visible
    border-radius: 8px !important;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;
    color: #888;

    .sticker {
      top: 30%;
      width: 90%;
      padding: 0;
      font-size: 0.75rem;
    }

    img {
      height: 45px;
      border-radius: 6px;
    }

    .app-name {
      min-height: 36px;
      font-size: 0.75rem;
    }
  }
}
</style>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import { osInfos, workspaceTypeInfos, OS, WorkspaceTypeInfo, isWorkspaceTypeSupportedOn, isWorkspaceTypePlannedOn } from '@/rd/ResearchDataModel'

const props = defineProps<{
  selectMode?: boolean
  showSupport?: boolean
  showAll?: boolean
  os?: OS
  app?: string
  center?: boolean
}>()

const emit = defineEmits([
  'update:os',
  'update:app',
])

const selectedOSRef = ref<OS>()
const selectedAppRef = ref<string>()
const osAppInfosRef = computed(() => workspaceTypeInfos
  .filter(a => isAvailableOn(a, selectedOSRef.value))
  .filter(a => props.showAll || 
    isWorkspaceTypeSupportedOn(a.id, selectedOSRef.value) || 
    isWorkspaceTypePlannedOn(a.id, selectedOSRef.value)))

watch(props, () => {
  selectedOSRef.value = props.os as OS
  selectedAppRef.value = props.app
}, { immediate: true })

watch(selectedOSRef, () => {
  selectedAppRef.value = undefined
  emit('update:os', selectedOSRef.value)
})

watch(selectedAppRef, () => {
  emit('update:app', selectedAppRef.value)
})

function isAvailableOn(app: WorkspaceTypeInfo, os?: OS) {
  return os && workspaceTypeInfos.find(a => a.id == app.id)?.availableOn?.includes(os)
}

function shouldEnableApp(a: WorkspaceTypeInfo) {
  return !props.showSupport || isWorkspaceTypeSupportedOn(a.id, selectedOSRef.value)
}

function getAppLabelClasses(a: WorkspaceTypeInfo) {
  const list = [a.id]
  if (props.selectMode) {
    list.push('btn', 'btn-outline-primary')
  }
  if (isWorkspaceTypeSupportedOn(a.id, selectedOSRef.value)) {
    list.push('supported')
  }
  return list
}

</script>
