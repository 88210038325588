<template>
  <div class="contact-info">
    <form v-if="userRef">
      <div class="row input-row">
        <div class="col-sm-4 col-lg-2">
          <label for="givenNameInput" class="form-label">Given name</label>
          <input type="text" class="form-control" id="givenNameInput" v-if="isEditing" :readonly="!canEditValues" v-model="editUser.givenName"/>
          <div class="input-value" v-show="!isEditing">{{userRef.givenName}}</div>
        </div>
        <div class="col-sm-4 col-lg-2">
          <label for="familyNameInput" class="form-label">Surname</label>
          <input type="text" class="form-control" id="familyNameInput" v-if="isEditing" :readonly="!canEditValues" v-model="editUser.surname"/>
          <div class="input-value" v-show="!isEditing">{{userRef.surname}}</div>
        </div>
        <div class="col-lg-2">
          <PrivacyLevelSelect id="namePrivacySelect" v-if="isEditing" :disabled="!canEditValues" v-model="editUser.namePrivacy"></PrivacyLevelSelect>
          <PrivacyLevelIndicator :value="userRef.namePrivacy" v-show="!isEditing"></PrivacyLevelIndicator>
        </div>
      </div>
      <div class="row input-row">
        <div class="col-sm-4">
          <label for="genderSelect" class="form-label">Gender</label>
          <select class="form-select" id="genderSelect" v-if="isEditing" :disabled="!canEditValues" v-model="editUser.gender">
            <option></option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          <div class="input-value" v-show="!isEditing">
            {{userRef.gender}}
          </div>
        </div>
      </div>
      <div class="row input-row">
        <div class="col-sm-4">
          <label for="emailInput" class="form-label">Contact email</label>
          <input type="text" class="form-control" id="emailInput" v-if="isEditing" :readonly="!canEditValues" v-model="editUser.email"/>
          <div class="input-value" v-show="!isEditing">{{userRef.email}}</div>
        </div>
        <div class="col-lg-2">
          <PrivacyLevelSelect id="emailPrivacySelect" v-if="isEditing" :disabled="!canEditValues" v-model="editUser.emailPrivacy"></PrivacyLevelSelect>
          <PrivacyLevelIndicator :value="userRef.emailPrivacy" v-show="!isEditing && userRef.email"></PrivacyLevelIndicator>
        </div>
      </div>
      <div class="row input-row">
        <div class="col-sm-4">
          <label for="altEmailInput" class="form-label">Alternate email</label>
          <input type="text" class="form-control" id="altEmailInput" v-if="isEditing" :readonly="!canEditValues" v-model="editUser.email2"/>
          <div class="input-value" v-show="!isEditing">{{userRef.email2}}</div>
        </div>
        <div class="col-lg-2">
          <PrivacyLevelSelect id="email2PrivacySelect" v-if="isEditing" :disabled="!canEditValues" v-model="editUser.email2Privacy"></PrivacyLevelSelect>
          <PrivacyLevelIndicator :value="userRef.email2Privacy" v-show="!isEditing && userRef.email2"></PrivacyLevelIndicator>
        </div>
      </div>
      <div class="row input-row">
        <div class="col-sm-4">
          <label for="mobileNumberInput" class="form-label">Mobile number</label>
          <input type="text" class="form-control" id="mobileNumberInput" v-if="isEditing" :readonly="!canEditValues" v-model="editUser.mobileNumber"/>
          <div class="input-value" v-show="!isEditing">{{userRef.mobileNumber}}</div>
        </div>
        <div class="col-lg-2">
          <PrivacyLevelSelect id="mobileNumberPrivacySelect" v-if="isEditing" :disabled="!canEditValues" v-model="editUser.mobileNumberPrivacy"></PrivacyLevelSelect>
          <PrivacyLevelIndicator :value="userRef.mobileNumberPrivacy" v-show="!isEditing && userRef.mobileNumber"></PrivacyLevelIndicator>
        </div>
      </div>
      <div class="edit-button">
        <button type="button" class="btn btn-primary" v-if="canEdit" @click="edit">Edit</button>
      </div>
      <div class="row input-row" v-show="isEditing">
        <div class="col">
          <button type="button" v-if="canEdit" class="btn btn-primary btn-sm" @click="edit">edit</button>
          <button type="submit" v-if="isEditing" class="btn btn-primary section-save" :disabled="!canSave" @click="save">{{saveText}}</button>
          <button type="button" v-if="isEditing" class="btn btn-outline-primary section-cancel" :disabled="!canCancel" @click="cancel">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.edit-button {
  margin: 1.5rem 0 0.5rem;

  button {
    min-width: 80px;
  }
}
</style>

<script setup lang="ts">
import { reactive, computed } from "vue"
import { useUserStore } from '@/gp/UserStore'
import { ItemPermissions, User } from '@/gp/GroupAdminModel'
import { useEditMode } from '@/util/EditMode'
import { PatchChange, PatchChangeValue } from '@/util/Api'
import { TokenManager } from "@/auth/TokenManager"
import PrivacyLevelIndicator from '@/account/PrivacyLevelIndicator.vue'
import PrivacyLevelSelect from '@/account/PrivacyLevelSelect.vue'
import { LoadMode } from "@/util/AsyncData"

const props = defineProps<{
  userId: string
}>()

const userStore = useUserStore()

const userIdRef = computed(() => props.userId ?? TokenManager.userId!)
const userRef = computed(() => userStore.getAsyncUser(userIdRef.value)?.data)
const editUser = reactive(new User())
const editProperties = [
  'givenName',
  'surname', 
  'namePrivacy',
  'gender',
  'email',
  'emailPrivacy',
] as (keyof User)[]

const allowEdit = computed(() => {
  const perms = userStore.getAsyncPermissions(userIdRef.value, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None
  return (perms & ItemPermissions.Modify) > 0
})
const allowSave = computed(() => !!editUser.givenName || !!editUser.surname)

const { isEditing, canEdit, canEditValues, edit, saveText, canSave, saveChanges, canCancel, cancel } = 
  useEditMode(userRef, editUser, editProperties, allowEdit, allowSave)

function save(e: Event) {
  e.preventDefault()
  saveChanges(async () => {
    const changes: PatchChange[] = []
    if (!userRef.value)
      return
    
    for (const key of editProperties) {
      if (editUser[key] != userRef.value[key]) {
        changes.push(new PatchChange(`/${key}`, editUser[key] as PatchChangeValue))
      }
    }

    if (changes.length) {
      await userStore.patchAsync(editUser.id!, changes)
    }
  })
}

</script>
