<template>
  <div class="account-admin">
    <div v-if="isSelfRef" class="admin-setting-group">
      <h5>Privileges</h5>
      <ul class="list-unstyled">
        <li v-for="pr in privileges" :key="pr">
          <label>
            <input type="checkbox" :checked="TokenManager.hasPrivilege(pr)" disabled />
            {{ getPrivilegeDisplayName(pr) }}
          </label>
        </li>
      </ul>
    </div>
    <div class="admin-setting-group">
      <h5>Metadata</h5>
      <label>
        <input type="checkbox" v-model="nameConfirmedRef" :disabled="!canEditRef" />
        User name confirmed
      </label>
    </div>
    <div class="admin-setting-group">
      <h5>Account options</h5>
      <label>
        <input type="checkbox" v-model="limitsDisabledRef" :disabled="!canEditRef" />
        Disable account limits
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.account-admin {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .admin-setting-group {

    label {
      margin-left: 1rem;
    }
  }
}
</style>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useUserStore } from '@/gp/UserStore'
import { LoadMode } from '@/util/AsyncData';
import { TokenManager, UserPrivilege, getPrivilegeDisplayName } from '@/auth/TokenManager';
import { PatchChange } from '@/util/Api';

const props = defineProps<{
  userId?: string
}>()

const privileges = [
  UserPrivilege.ReadAllUsers,
  UserPrivilege.ReadAllGroups,
  UserPrivilege.ManageUsers,
  UserPrivilege.ImpersonateUser,
]

const nameConfirmedRef = ref(false)
const limitsDisabledRef = ref(false)
const busyRef = ref(false)

const userStore = useUserStore()

const isSelfRef = computed(() => TokenManager.userId == props.userId)
const userRef = computed(() => userStore.getAsyncUser(props.userId, LoadMode.EnsureLoaded)?.data)
const canEditRef = computed(() => TokenManager.hasPrivilege(UserPrivilege.ManageUsers) && !busyRef.value)

watch(userRef, () => {
  nameConfirmedRef.value = userRef.value?.metadata?.nameConfirmed ?? false
  limitsDisabledRef.value = userRef.value?.limitsDisabled ?? false
}, { immediate: true })

watch(nameConfirmedRef, async () => {
  if (userRef.value && nameConfirmedRef.value != userRef.value.metadata?.nameConfirmed) {
    busyRef.value = true
    const change = new PatchChange('/metadata/nameConfirmed', nameConfirmedRef.value)
    await userStore.patchAsync(props.userId!, [change])
    busyRef.value = false
  }
})

watch(limitsDisabledRef, async () => {
  if (userRef.value && limitsDisabledRef.value != userRef.value?.limitsDisabled) {
    busyRef.value = true
    await userStore.setLimitsDisabledAsync(props.userId!, limitsDisabledRef.value)
    busyRef.value = false
  }
})
</script>
