import { reactive } from 'vue'
import { defineStore } from 'pinia'
import { FamilyGroupMember, FamilyGroupMemberKey, GroupMemberRole, PrincipalType } from '@webapp/gp/GroupAdminModel'
import { gp } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore, VersionedKeyList } from '@webapp/util/AsyncDataStore'
import { useUserStore } from './UserStore'
import { useFamilyGroupStore } from './FamilyGroupStore'
import { useDataGroupMemberStore } from './DataGroupMemberStore'
import { TokenManager } from '@webapp/auth/TokenManager'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { PatchChange } from '@/util/Api'

export const useFamilyGroupMemberStore = defineStore('familyGroupMembers', () => {
    const asyncFamilyGroupMembers = reactive(new Map<string, AsyncData<FamilyGroupMember>>())

    const familyGroupStore = useFamilyGroupStore()
    const dataGroupMemberStore = useDataGroupMemberStore()
    const userStore = useUserStore()

    async function getMemberAsync(key: string | FamilyGroupMemberKey) {
        // expand nothing (this is typically a point request to facilitate some other operation)
        const parsedKey = typeof key == "string" ? FamilyGroupMember.parseKey(key) : key
        const requestTime = DateTime.utc()
        const p = await gp.getPlainAsync(`familygroups/${parsedKey.familyGroupId}/members/${parsedKey.id}`)
        const itemMap = processExpandedFamilyGroupMembers([p], requestTime)
        AsyncDataStore.addItemMapToStore(asyncFamilyGroupMembers, "FamilyGroupMember", itemMap, requestTime)
        return itemMap.values().next().value
    }

    function getLoadedMembers(keys: (string | FamilyGroupMemberKey | undefined)[]) {
        const ids = keys.filter(isDefined).map(id => typeof id == "string" ? id : FamilyGroupMember.toKeyString(id))
        return ids.map(id => asyncFamilyGroupMembers.get(id)?.data).filter(isDefined)
    }

    function getAsyncKeyListsForFamilyGroups(familyGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        // expand user + profile, but assume family group is already loaded
        return getAsyncKeyListsForFamilyGroupMembers(familyGroupIds, "FamilyGroup", familyGroupStore.asyncMemberKeys, loadMode, 
            id => `familygroups/${id}/members`, "user/profiledatagroup", fm => fm.familyGroupId!) // NOTE: user is group owner, so no need to expand
    }

    function getAsyncKeyListsForUsers(userIds?: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        // expand family group + owner inline, but assume user is already loaded
        userIds ??= [TokenManager.userId]
        return getAsyncKeyListsForFamilyGroupMembers(userIds, "User", userStore.asyncFamilyGroupMemberKeys, loadMode, 
            id => `users/${id}/familygroups`, "familygroup/owner", fm => fm.userId!)
    }

    function getMembersForFamilyGroups(familyGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const keys = getAsyncKeyListsForFamilyGroups(familyGroupIds, loadMode).flatMap(a => a?.data?.keys ?? [])
        return getLoadedMembers(keys)
    }

    async function getMembersForFamilyGroupsAsync(familyGroupIds: (string | undefined)[]) {
        const keyListPromises = getAsyncKeyListsForFamilyGroups(familyGroupIds, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted)
        const keyLists = await Promise.all(keyListPromises)
        const keys = keyLists.filter(isDefined).flatMap(list => list.keys)
        return getLoadedMembers(keys)
    }

    function getMembersForUsers(userIds?: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const keys = getAsyncKeyListsForUsers(userIds, loadMode).flatMap(a => a?.data?.keys ?? [])
        return getLoadedMembers(keys)
    }

    async function getMembersForUsersAsync(userIds?: (string | undefined)[]) {
        const keyListPromises = getAsyncKeyListsForUsers(userIds, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted)
        const keyLists = await Promise.all(keyListPromises)
        const keys = keyLists.filter(isDefined).flatMap(list => list.keys)
        return getLoadedMembers(keys)
    }

    async function addAsync(member: FamilyGroupMember) {
        const addCoreAsync = async () => {
            const p = await gp.postPlainAsync(`familygroups/${member.familyGroupId}/members`, member)
            return plainToFamilyGroupMember(p)
        }            
        const key = await AsyncDataStore.addToStoreMapAsync(asyncFamilyGroupMembers, "FamilyGroupMember", m => m.id, addCoreAsync)
        const newMember = asyncFamilyGroupMembers.get(key)?.data // should be present

        invalidateKeyLists(newMember!)
        return key
    }

    async function patchAsync(key: string | FamilyGroupMemberKey, changes: PatchChange[]) {
        const keyStr = typeof key == "string" ? key : FamilyGroupMember.toKeyString(key)
        const patchCoreAsync = async (keyStr: string, changes: PatchChange[]) => {
            const parsedKey = FamilyGroupMember.parseKey(keyStr)
            const p = await gp.patchPlainAsync(`familygroups/${parsedKey.familyGroupId}/members/${parsedKey.id}`, changes)
            return plainToFamilyGroupMember(p)
        }
        await AsyncDataStore.patchFromStoreAsync(asyncFamilyGroupMembers, keyStr, "FamilyGroupMember", changes, patchCoreAsync)
        getMemberAsync(key) // trigger reload, but don't wait for it
    }

    async function deleteAsync(member: FamilyGroupMember) {
        const memberUser = await userStore.getAsyncUser(member.userId, LoadMode.EnsureLoaded)?.whenLoadCompleted
        const profileMember = memberUser
            ? await dataGroupMemberStore.getMemberByPrincipalAsync(memberUser.profileDataGroupId!, PrincipalType.FamilyGroup, member.familyGroupId!)
            : undefined

        // delete member
        await gp.deleteAsync(`familygroups/${member.familyGroupId}/members/${member.id}`)
        AsyncData.invalidate(asyncFamilyGroupMembers.get(member.key!))

        invalidateKeyLists(member)

        if (profileMember) {
            // NOTE: the server also "unshared" the user's profile data group, so invalidate that too
            dataGroupMemberStore.invalidateKeyLists(profileMember)
        }
        
        // TODO: clear other stores of cached data related to this member?
    }
    
    function plainToFamilyGroupMember(p: any) {
        const member = assignExisting(new FamilyGroupMember(), p)
        member.role = p.role ?? GroupMemberRole.Member // default
        member.createdDate = DateTimeUtil.fromAPI(p.createdDate);
        member.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate);
        return member
    }

    function processExpandedFamilyGroupMembers(plainItems: any[], requestTime: DateTime) {
        userStore.addUsersToStore(plainItems.map(p => p.user).filter(isDefined), requestTime)
        familyGroupStore.addFamilyGroupsToStore(plainItems.map(p => p.familyGroup).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainItems, "FamilyGroupMember", plainToFamilyGroupMember, m => m.key!)
    }

    function getAsyncKeyListsForFamilyGroupMembers(sourceItemKeys: (string | undefined)[],
        sourceTypeName: string,
        relatedKeysStoreMap: Map<string, AsyncData<VersionedKeyList>>,
        loadMode: LoadMode,
        relatedItemsPath: (id: string) => string,
        relatedItemsExpand: string,
        getSourceKey: (fm: FamilyGroupMember) => string) {

        const loadAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const ids = sourceKeys.filter(isDefined)
            const path = relatedItemsPath(sourceKeys.length == 1 ? sourceKeys[0] : '$list')
            const query = { ids: sourceKeys.length == 1 ? undefined : ids.join() }
            const plainItems = await gp.getPlainCollectionAsync(path, relatedItemsExpand, query)
            console.log(`Loaded ${plainItems.length} family group members at ${relatedItemsPath}`)
            return processExpandedFamilyGroupMembers(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListsForRelatedItems(sourceItemKeys, sourceTypeName, relatedKeysStoreMap,
            asyncFamilyGroupMembers, "FamilyGroupMember", loadMode, loadAsync, getSourceKey)
    }

    function invalidateKeyLists(member: FamilyGroupMember) {
        AsyncData.invalidate(userStore.asyncFamilyGroupMemberKeys.get(member.userId!))

        AsyncData.invalidate(familyGroupStore.asyncMemberKeys.get(member.familyGroupId!))

        AsyncData.invalidate(familyGroupStore.asyncDataGroupMemberKeys.get(member.familyGroupId!))
    }

    return {
        asyncFamilyGroupMembers,

        getMemberAsync,
        getAsyncKeyListsForFamilyGroups,
        getAsyncKeyListsForUsers,
        getMembersForFamilyGroups,
        getMembersForUsers,
        getMembersForFamilyGroupsAsync,
        getMembersForUsersAsync,
        addAsync,
        patchAsync,
        deleteAsync,
    }
})

