import { useExploreStore } from "@/explore/ExploreStore"
import { AncestorFamily, useViewFamilyStore } from "@/rd/ViewFamilyStore"
import { useViewPersonStore } from "@/rd/ViewPersonStore"
import { HighlightItem } from "./HighlightItem"
import { ViewPerson } from "@/rd/ResearchDataModel"
import _ from "lodash"
import { FuzzyDatePrecision } from "@/rd/FuzzyDate"
import { HistoricalDateType } from "@/rd/HistoricalDateEnums"
import { isDefined } from "@/util/TypeScriptUtil"

interface Tuplet extends AncestorFamily {
    tuplets: ViewPerson[]
}

const tupletNames = [
    undefined,
    undefined,
    'twins',
    'triplets',
    'quadruplets',
    'quintuplets',
]

function getTupletName(count: number) {
    return tupletNames.at(count) ?? 'tuplets'
}

const twinsIcon = new URL('@/assets/icons/twins_gray_60.png', import.meta.url).href

export function useFamilyHighlights() {
    const exploreStore = useExploreStore()
    const viewFamilyStore = useViewFamilyStore()
    const viewPersonStore = useViewPersonStore()
    
    function getPersonFamilyHighlights() {
        // do something
    }

    function getTreeFamilyHighlights() {
        if (!exploreStore.startPersonId || !exploreStore.initialTreeLoaded)
            return []
        
        const found = [] as (HighlightItem | undefined)[]
        const allTuplets = [] as Tuplet[]

        console.log('Looking for family highlights...')
        let searchCount = 0

        for (const af of viewFamilyStore.getLoadedAncestorFamilies(exploreStore.startPersonId, 10)) {
            searchCount++

            const children = viewFamilyStore.getLoadedChildren(af.family.id!).children
            if (children.length > 12) {
                console.log(`Found family with ${children.length} children after searching ${searchCount} families`)
                found.push(toManySiblingsHighlight(af.directChildId, children))
            }

            for (const parentId in af.family.spouseIds) {
                const parentSpouseFamilies = viewFamilyStore.getLoadedSpouseFamilies(parentId).spouseFamilies
                if (parentSpouseFamilies.length > 1) {
                    const otherChildren = parentSpouseFamilies.flatMap(sf => viewFamilyStore.getLoadedChildren(sf.id!).children)
                    const allChildren = new Set([...children, ...otherChildren]) // unique
                    if (allChildren.size > 12) {
                        console.log(`Found parent with ${allChildren.size} children after searching ${searchCount} families`)
                        found.push(toManyChildreHighlight(parentId, Array.from(allChildren)))
                    }
                }
            }

            const childrenByBday = _.groupBy(children, ch => ch.displayProperties.birthSortDate.value)
            const tupletsInFamily = [] as Tuplet[]
            for (const bday in childrenByBday) {
                const sameBdayChildren = childrenByBday[bday]
                if (sameBdayChildren.length > 1) {
                    const sortDate = sameBdayChildren[0].displayProperties.birthSortDate
                    if (!sortDate.isEmpty && sortDate.type == HistoricalDateType.Date && sortDate.date1.precision == FuzzyDatePrecision.Day) {
                        console.log(`Found ${getTupletName(sameBdayChildren.length)} after searching ${searchCount} families`)
                        const tuplet = { family: af.family, directChildId: af.directChildId, tuplets: sameBdayChildren }
                        found.push(toTupletsHighlight(tuplet))
                        tupletsInFamily.push(tuplet)
                        allTuplets.push(tuplet)
                    }
                }
            }

            if (tupletsInFamily.length > 1) {
                console.log(`Found ${tupletsInFamily.length} tuplets in one family after searching ${searchCount} families`)                
                found.push(toTupletsInFamilyHighlight(tupletsInFamily))
            }
        }

        if (allTuplets.length > 0) {
            found.push({
                id: 'allTuplets',
                text: 'direct ancestors have twins in their family',
                value: allTuplets.length,
            })

            const directTuplets = allTuplets.filter(t => t.tuplets.find(ch => ch.id == t.directChildId))
            if (directTuplets.length > 0) {
                found.push({
                    id: 'directTuplets',
                    text: 'direct ancestors have a twin',
                    value: directTuplets.length,
                })
            }
        }

        return found.filter(isDefined)
    }

    function toManyChildreHighlight(parentId: string, children: ViewPerson[]) {
        const parent = viewPersonStore.getAsyncPerson(parentId)?.data
        if (parent) {
            return {
                id: `manyChildren:${parent.id}`,
                personId: parent.id,
                text: `children of ${parent?.displayName}`,
                value: children.length,
            } as HighlightItem
        }
    }

    function toManySiblingsHighlight(personId: string, siblings: ViewPerson[]) {
        const vp = viewPersonStore.getAsyncPerson(personId)?.data
        if (vp) {
            return {
                id: `manySiblings:${vp.id}`,
                personId: vp.id,
                text: `siblings in ${vp?.displayName}'s family`,
                value: siblings.length,
            } as HighlightItem
        }
    }

    function toTupletsHighlight(t: Tuplet) {
        const ch = viewPersonStore.getAsyncPerson(t.directChildId)?.data
        if (ch) {
            const text = t.tuplets.find(ch => ch.id == t.directChildId)
                ? (t.tuplets.length > 2
                    ? `${ch.displayName} and ${t.tuplets.length - 1} siblings are ${getTupletName(t.tuplets.length)}`
                    : `${ch.displayName} has a twin`)
                : `${ch.displayName} has siblings who are ${getTupletName(t.tuplets.length)}`

            return {
                id: `tuplets:${t.directChildId}`,
                personId: ch.id,
                text,
                image: twinsIcon,
            } as HighlightItem
        }
    }

    function toTupletsInFamilyHighlight(tuplets: Tuplet[]) {
        const ch = viewPersonStore.getAsyncPerson(tuplets[0].directChildId)?.data
        if (ch) {
            const tupletDesc = tuplets.every(t => t.tuplets.length == 2) ? 'twins' : 'tuplets'
            return {
                id: `manyTuplets:${ch.id}`,
                personId: ch.id,
                text: `${ch.displayName} has ${tuplets.length} sets of ${tupletDesc} in their family`,
                image: twinsIcon,
            }
        }
    }

    return {
        getTreeFamilyHighlights
    }
}
