<template>
  <TaskPage class="signin-code-page" :no-title="isEmbeddedRef" no-back no-action :class="{ embedded: isEmbeddedRef }">
    <template #title>Sign in</template>
    <template #instruction>
      Check your email and enter the code we sent you.
    </template>
    <div class="body">
      <div>
        <label for="codeInput" class="form-label">Sign in code</label>
        <input type="text" class="form-control code-input" id="codeInput" ref="codeInputRef" :readonly="busyRef" v-model="codeRef"/>
      </div>
      <button type="submit" class="btn btn-primary" :disabled="!canSignInRef" @click="signIn">Sign in</button>
      <div class="other-actions">
        <router-link :to="retryPageRef">Use a different email address</router-link>
      </div>
    </div>
  </TaskPage>
</template>

<style lang="scss" scoped>
.body {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.other-actions {
  margin-top: 1rem;
  font-size: 0.875rem;
}
</style>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useSignInStore } from './SignInStore'
import { useAppHostStore } from '@webapp/AppHostStore'
import { useUserStore } from '@webapp/gp/UserStore'
import { getSafeRedirectUri, usePageTitle } from '@webapp/util/AppUtil'
import { IdentityApi } from '@webapp/auth/IdentityApi'
import { TokenManager } from './TokenManager'
import { ApiError, PatchChange } from '@webapp/util/Api'
import TaskPage from '@webapp/util/TaskPage.vue'

const props = defineProps<{
  noRedirect?: boolean
}>()

const emit = defineEmits([
  'authenticated'
])

usePageTitle("Sign in")

const appHostStore = useAppHostStore()
const isEmbeddedRef = computed(() => appHostStore.isEmbedded)
const signInStore = useSignInStore()
const userStore = useUserStore()
const router = useRouter()

const codeRef = ref("")
const busyRef = ref(false)

const canSignInRef = computed(() => codeRef.value.length > 5)
const retryPageRef = computed(() => signInStore.isNewUser ? "/signup" : "/signin")

const codeInputRef = ref<HTMLInputElement>()

async function signIn(e: Event) {
  e.preventDefault()

  if (signInStore.address) {
    busyRef.value = true

    let token = null
    try {
      const response = await IdentityApi.signInAsync(signInStore.address, codeRef.value)
      token = response.access_token
    }
    catch (ex) {
      if (ex instanceof ApiError && ex.statusCode != 400) {
        // TODO: notify/track unexpected error
      }
      // TODO: notify user of invalid code
      busyRef.value = false
      return
    }

    // SECURITY: set window location (instead of using router) to force app state to reset (new user -> new state)
    if (appHostStore.isEmbedded && !props.noRedirect) {
      // give host a chance to grab the access token (doesn't work for iframe "hosts")
      window.location.href = "/authredirect?access_token=" + encodeURIComponent(token)
    }
    else {
      await TokenManager.exchangeToken(token)

      if (signInStore.isNewUser && TokenManager.userId) {
        // new users who sign up via the website (vs. accepting an invite) have already provided their preferred name
        const change = new PatchChange('/metadata/nameConfirmed', 'true')
        await userStore.patchAsync(TokenManager.userId, [change])
      }

      if (props.noRedirect) {
        emit('authenticated')
      }
      else {
        window.location.href = getSafeRedirectUri() ?? "/home"
      }
    }
  }
}

onMounted(() => {
  codeInputRef.value!.focus()
  codeInputRef.value!.select()
})

</script>
