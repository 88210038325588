import { onUnmounted } from "vue"
import { TokenManager } from "@webapp/auth/TokenManager"
import { Environment } from "@webapp/util/Environment"
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { LoadMode } from '@webapp/util/AsyncData'
import { DataGroupMember } from './GroupAdminModel'
import { useUserStore } from './UserStore'
import { useDataGroupMemberStore } from "./DataGroupMemberStore"
import { useDataGroupStore } from "./DataGroupStore"
import { useFamilyGroupStore } from "./FamilyGroupStore"
import { isDefined } from "@webapp/util/TypeScriptUtil"
import { DateTime } from "luxon"
import { ItemAddedNotificationArgs } from "@/util/Api"

const gpUrl = Environment.get('GROUP_ADMIN_URL')
let notificationHub: HubConnection | undefined;

type Listener = (args: any) => void
const listenerSets = new Map<string, Set<Listener>>()

export function useGroupAdminNotifications() {
    const localListeners: Listener[] = []

    function initialize() {
        if (!TokenManager.isAccessTokenValid)
            return

        const userStore = useUserStore()
        const dataGroupMemberStore = useDataGroupMemberStore()
        const dataGroupStore = useDataGroupStore()
        const familyGroupStore = useFamilyGroupStore()
    
        notificationHub = new HubConnectionBuilder()
            .withUrl(`${gpUrl}notifications`, {
                accessTokenFactory: async () => (await TokenManager.getAccessTokenAsync()) ?? '',
            })
            .withAutomaticReconnect()
            .build()

        notificationHub.on('User.Changed', (args: ItemAddedNotificationArgs) => {
            console.log(`User.Changed: ${JSON.stringify(args)}`)
            // reload user ONLY if user is already present (not necessarily loaded)
            if (userStore.asyncUsers.get(args.itemId)) {
                userStore.getAsyncUser(args.itemId, LoadMode.Reload)
            }
        })

        notificationHub.on('DataGroupMember.OwnersAdded', async (args: DataGroupMemberOwnersAddedArgs) => {
            // load members whether data group is present or not (usually not)
            if (args.memberKeys.length > 0) {
                const requestTime = DateTime.utc()
                let dms: DataGroupMember[]
                if (args.memberKeys.length > 1 && args?.ownerId) {
                    // just get all members for the user at once instead (NOTE: related user is NOT reloaded)
                    dms = await dataGroupMemberStore.getMembersForUsersAsync([args.ownerId], LoadMode.Reload)
                }
                else {
                    // get each member one at a time (NOTE: related principals are NOT reloaded)
                    const asyncMembers = args.memberKeys.map(k => dataGroupMemberStore.getAsyncMemberByKey(k, LoadMode.Reload))
                    dms = (await Promise.all(asyncMembers.map(a => a.whenLoadCompleted!))).filter(isDefined)
                }
                dataGroupMemberStore.addToLoadedKeyLists(dms, requestTime)
            }
        })

        notificationHub.on('AuthCode.Used', (args: AuthCodeUsedArgs) => {
            console.log(`AuthCode.Used: ${JSON.stringify(args)}`)
            listenerSets.get('AuthCode.Used')?.forEach(ls => ls(args))
        })
        
        notificationHub.start()

        onUnmounted(() => {
            if (notificationHub) {
                notificationHub.stop()
                notificationHub = undefined
            }
        })    
    }

    const getConnectionId = () => notificationHub?.connectionId

    function addListener(methodName: string, listener: (args: any) => void) {
        if (!listenerSets.has(methodName)) {
            listenerSets.set(methodName, new Set())
        }
        listenerSets.get(methodName)!.add(listener)
        localListeners.push(listener)
    }

    function onAuthCodeUsed(listener: (args: AuthCodeUsedArgs) => void) {
        addListener('AuthCode.Used', listener)
    }

    onUnmounted(() => {
        // remove all local listeners
        localListeners.forEach(ls => {
            listenerSets.forEach((s: Set<Listener>) => {
                s.delete(ls) // if present
            })
        })
    })

    return {
        initialize,
        getConnectionId,
        onAuthCodeUsed,
    }
}

interface DataGroupMemberOwnersAddedArgs {
    ownerId: string
    memberKeys: string[]
}

interface AuthCodeUsedArgs {
    clientId: string
    notificationId: string
}
