<template>
  <div>
    <h1 class="page-title with-subhead">Managed Profiles</h1>
    <p class="subhead">
      <template v-if="isSelfRef">
        Profiles of living or recently living people that you manage individually.
      </template>
      <template v-else>
        Profiles of living or recently living people that are managed individually.
      </template>
    </p>
    <section v-if="!isSelfRef" class="owner-area">
      <h5 class="with-subhead">Profile owner</h5>
      <div class="owner">
        <UserItem :user-id="userIdRef"></UserItem>
      </div>
    </section>
    <ul ref="tabsElemRef" class="nav nav-pills profiles-tabs" role="tablist">
      <li class="nav-item">
        <button type="button" id="profilesTab" class="nav-link active" data-bs-toggle="tab" data-bs-target="#profilesPane" role="tab" aria-controls="profilesPane" aria-selected="true">
          Profiles
          <span v-if="profilePersonsRef.length > 0" class="count">{{ profilePersonsRef.length }}</span>
        </button>
      </li>
      <li class="nav-item">
        <button type="button" id="sharingTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#sharingPane" role="tab" aria-controls="sharingPane" aria-selected="true">
          Sharing
          <span v-if="pendingInvitesRef.length > 0" class="badge notify-badge rounded-pill">{{ pendingInvitesRef.length }}</span>
        </button>
      </li>
    </ul>
    <div class="tab-content content-area">
      <section id="profilesPane" class="tab-pane active" role="tabpanel" aria-labelledby="profilesTab" tab-index="0">
        <ul class="list-unstyled profile-list">
          <li v-for="p in profilePersonsRef" :key="p.id">
            <PersonCard :person-id="p.id" profile layout="small"></PersonCard>
          </li>
        </ul>
      </section>
      <section id="sharingPane" class="tab-pane" role="tabpanel" aria-labelledby="sharingTab" tab-index="0">
        <ul class="list-unstyled sharing-list">
          <li v-if="inviteFamilyGroupsRef.length == 0" class="empty-list">
            No sharing requests
          </li>
          <li v-for="fg in inviteFamilyGroupsRef" :key="fg.id">
            <FamilyGroupItem :family-group-id="fg.id"></FamilyGroupItem>
            <ExpandableItem>
              <template #item>
                <div class="invite-list-header">
                  Pending requests <span class="count">({{ getInvitedProfilePersons(fg.id!).length }})</span>
                </div>
              </template>
              <template #content>
                <ul class="list-unstyled invite-list">
                  <li v-for="p in getInvitedProfilePersons(fg.id!)" :key="p.id">
                    <PersonCard :person-id="p.id" profile layout="small" select-mode @click="onInvitedPersonClick(fg.id!, p)"></PersonCard>
                  </li>
                </ul>
              </template>
            </ExpandableItem>
          </li>
        </ul>
      </section>
    </div>
    <DataGroupInviteModal ref="dataGroupInviteModalRef"></DataGroupInviteModal>
  </div>
</template>

<style lang="scss" scoped>
button.nav-link {
  position: relative;
}

.owner-area {
  margin-bottom: 2rem;

  .owner {
    margin-left: 1rem;
  }
}

.profile-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.sharing-list {
  ::v-deep .expandable-item {
    margin-top: 0.5rem;
    margin-left: 3rem;
  }

  ::v-deep .invite-list-header {
    font-size: 0.875rem;
    color: #888;
  }

  ::v-deep .invite-list {
    margin-left: 1.25rem;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useDataGroupInviteStore } from '@/gp/DataGroupInviteStore'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { DataGroupInvite, DataGroupType, FamilyGroup, GroupMemberRole, InvitationResult, PrincipalType } from '@/gp/GroupAdminModel'
import { usePersonStore } from '@/rd/PersonStore'
import { Person } from '@/rd/ResearchDataModel'
import { TokenManager } from '@/auth/TokenManager'
import { LoadMode } from '@/util/AsyncData'
import { isDefined } from '@/util/TypeScriptUtil'
import { usePageTitle } from '@/util/AppUtil'
import UserItem from './UserItem.vue'
import PersonCard from './PersonCard.vue'
import FamilyGroupItem from './FamilyGroupItem.vue'
import ExpandableItem from '@/util/ExpandableItem.vue'
import DataGroupInviteModal from './DataGroupInviteModal.vue'

const props = defineProps<{
  userId?: string
}>()

const profilePersonsRef = ref<Person[]>([])
const invitesRef = ref<DataGroupInvite[]>([])

const dataGroupInviteModalRef = ref<InstanceType<typeof DataGroupInviteModal>>()

const userStore = useUserStore()
const dataGroupStore = useDataGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const dataGroupInviteStore = useDataGroupInviteStore()
const familyGroupStore = useFamilyGroupStore()
const personStore = usePersonStore()

const userIdRef = computed(() => props.userId ?? TokenManager.userId)
const isSelfRef = computed(() => userIdRef.value == TokenManager.userId)
const userRef = computed(() => userStore.getAsyncUser(userIdRef.value, LoadMode.EnsureLoaded)?.data)
const ownerMembersRef = computed(() => dataGroupMemberStore.getMembersForUsers([userIdRef.value], LoadMode.EnsureLoaded)
  .filter(m => m.role == GroupMemberRole.Owner))
const profileGroupIdsRef = computed(() => ownerMembersRef.value.map(m => m.dataGroupId))
const profileGroupsRef = computed(() => dataGroupStore.getAsyncGroupList(profileGroupIdsRef.value) // loaded inline with members
  .map(a => a.data).filter(isDefined).filter(dg => dg.groupType == DataGroupType.Profile))
const pendingInvitesRef = computed(() => invitesRef.value.filter(inv => inv.result == InvitationResult.Pending))
const inviteFamilyGroupsRef = computed(() => 
  familyGroupStore.getAsyncGroupList(pendingInvitesRef.value.map(inv => inv.principalId)) // loaded with invites
    .map(a => a.data).filter(isDefined)
    .sort(FamilyGroup.compareName))

usePageTitle("Managed Profiles", () => isSelfRef.value ? "" : userRef.value?.displayName)

watch(profileGroupsRef, async () => {
  profilePersonsRef.value = []
  
  const asyncPersons = personStore.getAsyncPersonList(profileGroupsRef.value.map(dg => dg.startItemId), LoadMode.EnsureLoaded)
  await Promise.all(asyncPersons.map(a => a.whenLoadCompleted))
  const persons = asyncPersons.map(a => a.data).filter(isDefined)
  persons.sort(Person.compareDisplayName)

  profilePersonsRef.value = persons
}, { immediate: true })

watch(profilePersonsRef, async () => {
  const personGroupIDs = profilePersonsRef.value.map(p => p.groupId)
  const asyncKeyLists = dataGroupInviteStore.getAsyncKeyListsForDataGroups(personGroupIDs, LoadMode.EnsureLoaded)
  await Promise.all(asyncKeyLists.map(a => a.whenLoadCompleted))

  const inviteKeys = asyncKeyLists.flatMap(a => a.data?.keys ?? [])
  invitesRef.value = await dataGroupInviteStore.getInviteListAsync(inviteKeys)
}, { immediate: true })

function getInvitedProfilePersons(familyGroupId: string) {
  return profilePersonsRef.value.filter(p => invitesRef.value.find(inv => inv.dataGroupId == p.groupId && inv.principalId == familyGroupId))
}

function onInvitedPersonClick(familyGroupId: string, person: Person) {
  const invite = invitesRef.value.find(inv => inv.principalId == familyGroupId && inv.dataGroupId == person.groupId)
  if (invite) {
    dataGroupInviteModalRef.value?.openEdit(invite.id!)
  }
}

</script>
