import { Api } from "@webapp/util/Api"
import { TokenManager } from "@webapp/auth/TokenManager"
import { Environment } from "@webapp/util/Environment"
import { useGroupAdminNotifications } from "./GroupAdminNotifications"

const { getConnectionId } = useGroupAdminNotifications()

const gpUrl = Environment.get('GROUP_ADMIN_URL')
export const gp = new Api(gpUrl, TokenManager.getAccessTokenAsync, () => getConnectionId() ?? '')
export const gpAnon = new Api(gpUrl, () => '')
