export class CompositeId {
    readonly groupId: string
    readonly itemId: string
    readonly value: string

    constructor(groupId: string, itemId: string) {
        this.groupId = groupId
        this.itemId = itemId
        this.value = `${groupId}-${itemId}`
    }

    static parse(value: string) {
        const parts = value.split('-', 2)
        return new CompositeId(parts[0], parts[1])
    }

    static getGroupId(value: string | undefined) {
        return value ? CompositeId.parse(value).groupId : undefined
    }

    static hasGroupId(value: string, groupId: string) {
        return CompositeId.parse(value).groupId == groupId
    }

    static isCompositeId(value: string | undefined) {
        return value?.includes('-') ?? false
    }
}

