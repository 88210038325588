<template>
  <div class="sync-app-page container">
    <h1>Do we support your tree yet?</h1>
    <AppSupportGrid center show-support/>
    <!-- <div class="more-info">
      <div class="inline-question">
        <button type="button" class="btn btn-inline btn-link" data-bs-toggle="collapse" data-bs-target="#otherSources">
          What about other trees?
        </button>
        <div id="otherSources" class="collapse">
          We'd like to add more!
          If there's one you'd like to see soon, <router-link to="/support">let us know!</router-link>
        </div>
      </div>
    </div> -->
    <h1>Use the Sync app to upload it.</h1>
    <div class="alert alert-warning alert-sm">
      <div>
        We're in private preview. If you're not approved yet, you won't be able to sign in to the Sync app.
        You can still <router-link to="/signup">sign up</router-link>, though, and we'll let you know as soon as you can try it out!
      </div>
    </div>
    <div class="sync-app">
      <img src="@/assets/logos/Sync_app.png">
      <div>
        <h3>Tree Crossing Sync</h3>
        <div class="publisher">Published by Tree Crossing, LLC</div>
      </div>
    </div>
    <div class="download-badges">
      <a v-if="osRef" :href="msStoreLink"><img src="@/assets/logos/GetFromMicrosoft.png"></a>
      <a v-else href="#"><img src="@/assets/logos/GetFromMacStore.svg"></a>
    </div>
    <div class="more-info">
      <div>
        <router-link :to="{ name: 'sync' }">How does the Sync app work?</router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sync-app-page {
  max-width: 700px;
  text-align: center;

  h1 {
    margin: 2rem 0;
    font-size: 2rem;

    @media (min-width: 768px) {
      margin: 4rem 0 3rem;
    }
  }

  .alert {
    margin-bottom: 2rem;
  }

  .sync-app {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    text-align: start;

    img {
      height: 52px;
    }

    h3 {
      margin: 0;
      font-size: 1.5rem;
    }

    .publisher {
      font-size: 0.875rem;
      color: #aaa;
    }
  }

  .download-badges {
    margin: 1.5rem 2rem 3rem;

    img {
      height: 50px;
      border-radius: 10px;

      @media (min-width: 768px) {
        height: 65px;
      }
    }
  }

  .more-info {
    margin-top: 2rem;
    text-align: center;

    .inline-question {
      margin-bottom: 1rem;

      button {
        margin-bottom: 0.5rem;
      }

      .collapse, .collapsing {
        padding: 1rem;
        background-color: #fefdeb;
        border: 1px solid #ddd;
        border-radius: 0.25rem;
        font-size: 0.85rem;
        //color: #bbb;
      }
    }
  }
}
</style>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { usePageTitle } from '@/util/AppUtil'
import AppSupportGrid from './AppSupportGrid.vue'

usePageTitle('Get the Sync app')

type OS = "Windows" | "Mac"
interface SupportedOS {
  id: OS
  name: string
  versions: string
}

const oses: SupportedOS[] = [
  { id: "Windows", name: 'Windows', versions: "10, 11" },
  { id: "Mac", name: 'Mac', versions: "10" },
]
const osRef = ref<OS>();
const msStoreLink = computed(() => osRef.value == 'Windows'
  ? "ms-windows-store://pdp?ocid=pdpshare&hl=en-us&gl=us&productid=9P50KNCSGK41"
  : "https://www.microsoft.com/store/productId/9P50KNCSGK41?ocid=pdpshare")

const osIcons: any = {
  "Windows": new URL('@/assets/logos/Windows_150.png', import.meta.url).href,
  "Mac": new URL('@/assets/logos/Mac_150.png', import.meta.url).href,
}

interface SupportedApp {
  id: string
  name: string
  versions: string
  availableOn: OS[]
  supportedOn?: OS[]
}

const apps: SupportedApp[] = [
  { id: 'RootsMagic', name: 'RootsMagic®', versions: "7, 8, 9", availableOn: ['Windows', 'Mac'], supportedOn: ['Windows']},
  { id: 'Legacy', name: 'Legacy Family Tree®', versions: "8, 9", availableOn: ['Windows'], supportedOn: ['Windows'] },
  { id: 'FamilyTreeMaker', name: 'Family Tree Maker®', versions: "2019, 2024", availableOn: ['Windows', 'Mac'] },
  { id: 'MacFamilyTree', name: 'MacFamilyTree®', versions: "10", availableOn: ['Mac'] },
  { id: 'FamilyHistorian', name: 'Family Historian®', versions: "7", availableOn: ['Windows'] },
]

const appIcons: any = { // 'any' prevents TS from complaining about arbitrary string as index
  "RootsMagic": new URL('@/assets/logos/RootsMagic_app.png', import.meta.url).href,
  "Legacy": new URL('@/assets/logos/Legacy_app.png', import.meta.url).href,
  "FamilyTreeMaker": new URL('@/assets/logos/FamilyTreeMaker_app.png', import.meta.url).href,
  "MacFamilyTree": new URL('@/assets/logos/MacFamilyTree_app.png', import.meta.url).href,
  "FamilyHistorian": new URL('@/assets/logos/FamilyHistorian_app.png', import.meta.url).href,
}

function isAvailableOn(app: SupportedApp, os: OS) {
  return apps.find(a => a.id == app.id)?.availableOn?.includes(os)
}

function isSupportedOn(app: SupportedApp, os: OS) {
  return apps.find(a => a.id == app.id)?.supportedOn?.includes(os)
}

onMounted(() => {
  osRef.value = 
    /Win/.test(navigator.userAgent) ? 'Windows'  : 
    /Mac/.test(navigator.userAgent) ? 'Mac' :
    undefined
});
</script>