import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore'
import { TokenManager } from '@/auth/TokenManager'
import { DataGroupType, GroupMemberRole } from '@/gp/GroupAdminModel'
import { LoadMode } from '@/util/AsyncData'

// These pages show the upload action themselves, so it should NOT be shown in the header
const hideUploadActionPages = [
    '/home',
]

export function useUploadAction() {
    const dataGroupStore = useDataGroupStore()
    const dataGroupMemberStore = useDataGroupMemberStore()
    const familyGroupMemberStore = useFamilyGroupMemberStore()
    const router = useRouter()
    
    const showUploadActionRef = computed(() => {
        if (!TokenManager.isAccessTokenValid || 
            TokenManager.isSupervised)
            return false // not allowed

        const dmKeyList = dataGroupMemberStore.getAsyncKeyListsForUsers(undefined, LoadMode.EnsureLoaded)[0]
        const fmKeyList = familyGroupMemberStore.getAsyncKeyListsForUsers(undefined, LoadMode.EnsureLoaded)[0]

        if (!dmKeyList.isLoaded || !fmKeyList.isLoaded)
            return false // don't know yet

        const dms = dataGroupMemberStore.getLoadedMembers(dmKeyList.data!.keys)
            .filter(dm => dm.role == GroupMemberRole.Owner)
        const rgs = dataGroupStore.getLoadedGroupList(dms.map(dm => dm.dataGroupId))
            .filter(dg => dg.groupType == DataGroupType.Research)
        
        // show upload action is user does not own any research groups AND is not a member of any family groups
        console.log(`ShowUploadAction: ORG(${rgs.length}), FG(${fmKeyList.data?.keys.length})`)
        return rgs.length == 0 && fmKeyList.data!.keys.length == 0
    })

    const showUploadActionInHeaderRef = computed(() => 
        showUploadActionRef.value && !hideUploadActionPages.includes(router.currentRoute.value.path))

    const uploadActionTextRef = ref('Upload my tree')

    return {
        showUploadActionRef,
        showUploadActionInHeaderRef,
        uploadActionTextRef,
    }
}
