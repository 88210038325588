import { ViewPerson } from '@/rd/ResearchDataModel'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { DateTime } from 'luxon'
import { TodayItem, isSameMonthDay } from './TodayItem'
import { useExploreStore } from '../ExploreStore'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const sunriseIcon = new URL('@/assets/icons/sunrise_gray_60.png', import.meta.url).href

export function useAncestorsToday() {
    const exploreStore = useExploreStore()
    const viewPersonStore = useViewPersonStore()

    function getLoadedAncestorsBornOn(monthDay: DateTime, adjacentDays = false) {
        const startPersonId = exploreStore.startPersonId
        if (!startPersonId)
            return []

        const dayBefore = monthDay.minus({ days: 1 })
        const dayAfter = monthDay.plus({ days: 1 })

        console.log(`Looking for ancestors born on ${monthDay.day} ${monthDay.monthShort}...`)

        const found = [] as ViewPerson[]
        for (const vp of viewPersonStore.getLoadedAncestors(startPersonId, 10, 1)) {
            const bday = vp.displayProperties.birthDate.date1
            if (bday && (isSameMonthDay(bday, monthDay) || (adjacentDays && (isSameMonthDay(bday, dayBefore) || isSameMonthDay(bday, dayAfter))))) {
                console.log(`Found ancestor ${vp.displayName} born on ${bday.toString()}`)
                found.push(vp)
            }
        }

        return found.map(toBornTodayItem)
    }

    function toBornTodayItem(vp: ViewPerson) {
        return {
            id: `birth:${vp.id}`,
            date: vp.displayProperties.birthDate.date1!,
            personId: vp.id!,
            displayText: `${vp.displayName} was born in ${vp.birthYear}`,
            place: vp.displayProperties.birthPlaceId,
            image: sunriseIcon,
            pageUrl: vp.pageUrl
        } as TodayItem
    }

    return {
        getLoadedAncestorsBornOn
    }
}
