import { defineStore } from "pinia"
import { TokenManager } from '@/auth/TokenManager'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { RelationPath } from "@/rd/RelationPath"
import { LatLong } from "@/util/GeoUtil"
import { AsyncData, LoadMode } from "@/util/AsyncData"
import { DateTime } from "luxon"
import { Api } from "@/util/Api"
import { computed, reactive, ref } from "vue"
import { DataGroupType } from "@/gp/GroupAdminModel"

export interface UserLocation extends LatLong {
    ip: string
    city: string
    region: string
    region_code: string
    country_code: string
    country_code_iso3: string
    country_name: string
    in_eu: boolean
    currency: string
    org: string
}

export const useExploreStore = defineStore("exploreStore", () => {
    const altStartPersonId = ref<string>()
    const relationPath = ref<RelationPath>()
    const initialTreeLoaded = ref(false)
    const hasViewedTree = ref(false)
    const userLocation = reactive(new AsyncData<UserLocation>("user", "Location"))
    const dismissedJoinedGroupIds = ref<string[]>([])

    const dataGroupStore = useDataGroupStore()
    
    const selfPersonId = computed(() => {
        const selfProfileGroup = dataGroupStore.getAsyncGroup(TokenManager.userProfileId)?.data
        return selfProfileGroup?.startItemId
    })

    const startPersonId = computed(() => altStartPersonId.value ?? selfPersonId.value)
    const enableExploreRef = computed(() => dataGroupStore.getVisibleGroups(LoadMode.EnsureLoaded)
        .filter(dg => dg.groupType == DataGroupType.Research).length > 0)


    function setStartPersonId(personId: string) {
        if (personId == selfPersonId.value) {
            altStartPersonId.value = undefined
        }
        else {
            altStartPersonId.value = personId
        }
    }

    async function ensureUserLocationLoaded() {
        if (userLocation.shouldLoad(LoadMode.EnsureLoaded)) {
            const ipapi = new Api('https://ipapi.co/json/')
            const getLocation = ipapi.getPlainAsync().then(data => {
                const location = data as UserLocation
                console.log(`Loaded user location: ${location.latitude}, ${location.longitude} (${location.city}, ${location.region_code}, ${location.country_code})`)
                return location
            })
            AsyncData.trackLoad(userLocation, DateTime.utc(), getLocation)
        }
    }

    return {
        altStartPersonId,
        relationPath,
        initialTreeLoaded,
        hasViewedTree,
        userLocation,
        dismissedJoinedGroupIds,

        selfPersonId,
        startPersonId,
        enableExploreRef,

        setStartPersonId,
        ensureUserLocationLoaded
    }
})