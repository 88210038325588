<template>
  <Modal ref="modalRef" info title-lg @shown="onShown" @secondary-action="learnMore">
    <template #title>Upload my tree</template>
    <div class="body">
      <template v-if="isWindows">
        <div >
          First, install and open our Sync app.
        </div>
        <SyncAppBadge />
      </template>
      <div>
        Then, use this code to sign into the app.
      </div>
      <button type="button" v-if="isCodeExpiredRef" class="btn btn-link" @click="refreshCode">
        Get a new code
      </button>
      <div v-else class="code">
        {{ codeRef?.code }}
      </div>
      <div class="remaining" :class="{ show: isCodeExpiringRef}">
        Expires in {{ remainingTextRef }}
      </div>
    </div>
    <template #secondaryText>Why do I need the Sync app?</template>
  </Modal>
</template>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 0;
  gap: 0.5rem;
  color: #333;

  .sync-app-badge {
    margin: 0.5rem 0 1.5rem;
  }

  .code {
    font-size: 2rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: black;
  }

  .remaining {
    font-size: 0.875rem;
    color: transparent;

    &.show {
      color: #888;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { hideModalAsync } from '@/util/AppUtil'
import { currentOS } from '@/rd/ResearchDataModel'
import { LocalTokenApi, AuthCodeResponse } from '@/auth/LocalTokenApi'
import { TokenManager } from '@/auth/TokenManager'
import { DateTime, Duration } from 'luxon'
import { useGroupAdminNotifications } from '@/gp/GroupAdminNotifications' 
import Modal from '@/util/Modal.vue'
import SyncAppBadge from '@/public/SyncAppBadge.vue'

const router = useRouter()
const gpNotify = useGroupAdminNotifications()

const syncAppClientId = "Cofnxru6I9" // public client id for sync app

const expiringDuration = 9 * 60 * 1000 // 9 minutes

const codeRef = ref<AuthCodeResponse>()
const remainingTimeRef = ref(0)
let timerHandle = 0

const isCodeExpiredRef = computed(() => codeRef.value && remainingTimeRef.value <= 0)
const isCodeExpiringRef = computed(() => codeRef.value && remainingTimeRef.value > 0 && remainingTimeRef.value < expiringDuration)
const remainingTextRef = computed(() => {
  const d = Duration.fromMillis(remainingTimeRef.value)
  return d.as('minutes') >= 1 ? `${Math.ceil(d.as('minutes'))} min` : d.toFormat('m:ss')
})

const isWindows = currentOS == 'windows'
const modalRef = ref<InstanceType<typeof Modal>>()

defineExpose({
  open: () => modalRef.value?.open(),
})

async function learnMore() {
  await hideModalAsync(modalRef.value!.$el)
  router.push('/learn/sync')
}

function onShown() {
  if (!codeRef.value) {
    refreshCode()
  }
}

function authCodeUsed() {
  codeRef.value = undefined
  if (timerHandle) {
    clearInterval(timerHandle)
  }
  modalRef.value?.close()
}

onMounted(() => {
  gpNotify.onAuthCodeUsed(args => {
    if (args.clientId == syncAppClientId && args.notificationId == codeRef.value?.notificationId) {
      authCodeUsed();
    }
  })
})

onUnmounted(() => {
  if (timerHandle) {
    clearInterval(timerHandle)
  }
})

async function refreshCode() {
  const token = await TokenManager.getAccessTokenAsync()
  if (token) {
    codeRef.value = await LocalTokenApi.getLocalAuthCodeAsync(token, syncAppClientId)
    updateRemainingTime()

    timerHandle = setInterval(() => {
      updateRemainingTime()      
      if (remainingTimeRef.value == 0) {
        clearInterval(timerHandle)
      }
    }, 1000)
  }
}

function updateRemainingTime() {
  remainingTimeRef.value = Math.max(0, codeRef.value!.expirationDate.diff(DateTime.utc()).milliseconds)
}

</script>
