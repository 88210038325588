import { defineStore } from "pinia"
import { OS, osInfos, workspaceTypeInfos } from '@webapp/rd/ResearchDataModel'

const defaultOS: OS = /Mac/.test(navigator.userAgent) ? 'mac' : 'windows'

export const useSignInStore = defineStore("signInStore", {
    state: () => ({
        subject: "",
        givenName: "",
        familyName: "",
        address: "",
        isNewUser: false,
        couponCode: "",
        os: defaultOS,
        app: "",
        otherApp: "",
        notifyRequested: new Set<string>(),
    }),
    actions: {
        getAppDesc() {
            const osInfo = osInfos.find(os => os.id == this.os)
            const appInfo = workspaceTypeInfos.find(app => app.id == this.app)
            if (osInfo && appInfo) {
                return appInfo.product + (appInfo.availableOn.length > 1 ? ' on ' + osInfo.name : '')
            }
            return this.app
        },
        getAppKey() {
            return `${this.app}|${this.os}`
        },
        isNotifyRequested() {
            return this.notifyRequested.has(this.getAppKey())
        }
    },
})