<template>
  <TaskPage class="app-notify-page" :can-continue="true" @action="nextPage">
    <template #title>Get started</template>
    <div class="body">
      <div v-if="isKnownAppRef" class="known-app">
        <img v-if="showOSRef" class="os-icon" :src="osInfoRef?.icon"/>
        <img v-if="showOSRef" class="plus-icon" src="@/assets/icons/plus_gray_60.png"/>
        <div class="app-icon">
          <img :src="appInfoRef?.icon"/>
          <div>{{ appInfoRef?.product }}</div>
        </div>
      </div>
      <div class="alert alert-warning" :class="{ known: isKnownAppRef }">
        To share your tree on Tree Crossing, our Sync app needs to support your specific genealogy software.
        <template v-if="isPlannedRef">
          We're adding support for yours right now, but it's not ready yet.
        </template>
        <template v-else-if="isKnownAppRef" >
          We don't support {{ signInStore.getAppDesc() }} yet, but your interest will help us prioritize it.
          Thanks for letting us know!
        </template>
        <template v-else>
          We don't support yours right now, but we'll consider adding it if we get enough interest.
          Thanks for letting us know!
        </template>
      </div>
      <div v-if="isKnownAppRef" class="notify-check">
        <label>
          <input type="checkbox" v-model="shouldNotifyRef"/>
          Notify me when it's ready
        </label>
      </div>
    </div>
  </TaskPage>
</template>

<style lang="scss" scoped>
.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.known-app {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  .os-icon {
    width: 50px;
  }

  .plus-icon {
    width: 30px;
  }

  .app-icon {
    margin-top: 2rem;
    width: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 0.875rem;
    color: #333;

    img {
      margin-bottom: 0.25rem;
      width: 75px;
      border-radius: 8px;
    }
  }
}

.alert:not(.known) {
  margin-top: 3rem;
}

.notify-check {
  margin-left: 1rem;
  align-self: flex-start;

  label, input {
    cursor: pointer;
  }

  input {
    margin-right: 0.25rem;
  }
}
</style>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue'
import { useSignInStore } from '@webapp/auth/SignInStore'
import { osInfos, workspaceTypeInfos } from '@webapp/rd/ResearchDataModel'
import { useRouter } from 'vue-router'
import _ from 'lodash'
import { PatchChange } from '@webapp/util/Api'
import { IdentityApi } from './IdentityApi'
import TaskPage from '@webapp/util/TaskPage.vue'

const signInStore = useSignInStore()
const router = useRouter()

const shouldNotifyRef = ref(false)

const osInfoRef = computed(() => osInfos.find(os => os.id == signInStore.os))
const appInfoRef = computed(() => workspaceTypeInfos.find(app => app.id == signInStore.app))
const showOSRef = false //computed(() => (appInfoRef.value?.availableOn.length ?? 0) > 1)
const isPlannedRef = computed(() => !!appInfoRef.value?.plannedOn.includes(signInStore.os))
const isKnownAppRef = computed(() => workspaceTypeInfos.some(a => a.id == signInStore.app))

onMounted(() => {
  shouldNotifyRef.value = signInStore.isNotifyRequested()
})

watch(shouldNotifyRef, async () => {
  const key = signInStore.getAppKey()
  if (shouldNotifyRef.value) {
    signInStore.notifyRequested.add(key)
  }
  if (!shouldNotifyRef.value) {
    signInStore.notifyRequested.delete(key)
  }
})

async function nextPage() {
  router.push('/signupdone')

  if (signInStore.app && !isKnownAppRef.value) {
    signInStore.notifyRequested.add(signInStore.app)
  }
  const change = new PatchChange("/metadata/appSupportNotify", [...signInStore.notifyRequested])
  await IdentityApi.patchAsync(signInStore.subject, [change])
}
</script>
