<template>
  <div class="navbar">
    <ul class="navbar-nav site-nav">
      <li class="nav-item hide-md" v-if="userRef">
        <SiteSearch />
      </li>
      <li class="nav-item" v-if="userRef">
        <router-link class="nav-link" to="/home">
          Home
        </router-link>
      </li>
      <template v-if="props.asDrawer">
        <li class="nav-item">
          <button type="button" class="nav-link" data-bs-toggle="collapse" data-bs-target="#exploreItems">
            Explore
          </button>
        </li>
        <li id="exploreItems" class="collapse">
          <ul class="navbar-nav">
            <li v-if="selfViewPersonRef?.pageUrl" class="nav-item" :class="{ disabled: !exploreStore.enableExploreRef }">
              <router-link class="nav-link" :to="selfViewPersonRef.pageUrl">
                Family
              </router-link>
            </li>
            <li class="nav-item disabled">
              <div class="nav-link">
                Names
              </div>
            </li>
            <li class="nav-item disabled">
              <div class="nav-link">
                Places
              </div>
            </li>
          </ul>
        </li>
      </template>
      <template v-else>
        <li class="nav-item dropdown" v-if="selfViewPersonRef?.pageUrl">
          <button type="button" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Explore</button>
          <ul class="dropdown-menu">
            <li class="dropdown-item" :class="{ disabled: !exploreStore.enableExploreRef }">
              <router-link class="link-dark" :to="selfViewPersonRef.pageUrl">
                Family
              </router-link>
            </li>
            <li class="dropdown-item disabled">
              Names
            </li>
            <li class="dropdown-item disabled">
              Places
            </li>
          </ul>
        </li>
      </template>
      <li class="nav-item" v-if="!userRef">
        <router-link class="nav-link" to="/plans">
          Plans
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/support">
          Support
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.site-nav {
  font-size: 0.875rem;
}

.navbar-nav .navbar-nav {
  margin-left: 1rem;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.nav-link.dropdown-toggle::after {
  display: none;
}

.dropdown-item.disabled a {
  color: #888;
}

@media (min-width: 768px) {
  .site-nav {
    flex-direction: row;
    gap: 1rem;

    .nav-item > .nav-link:not(:hover) {
      color: #888;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/gp/UserStore'
import { useDataGroupStore } from "@/gp/DataGroupStore"
import { useViewPersonStore } from "@/rd/ViewPersonStore"
import { useExploreStore } from '@/explore/ExploreStore'
import { LoadMode } from '@/util/AsyncData'
import SiteSearch from './SiteSearch.vue'

const props = defineProps<{
  asDrawer?: boolean
}>()

const router = useRouter()
const userStore = useUserStore()
const dataGroupStore = useDataGroupStore()
const viewPersonStore = useViewPersonStore()
const exploreStore = useExploreStore()

const userRef = computed(() => userStore.getAsyncSelf(LoadMode.EnsureLoaded)?.data)
const selfPersonIdRef = computed(() => dataGroupStore.getAsyncGroup(userRef.value?.profileDataGroupId, LoadMode.EnsureLoaded)?.data?.startItemId)
const selfViewPersonRef = computed(() => viewPersonStore.getAsyncPerson(selfPersonIdRef.value, LoadMode.EnsureLoaded)?.data)
</script>
