<template>
  <div class="signup-grant-alert">
    <div v-if="signupGrantActiveRef" class="alert alert-success alert-sm block-source">
      <img :src="sourceLogos[signupGrantRef?.sourceUrl!]"/>
      <div>
        Thanks for visiting us from <b>{{ signupGrantRef?.sourceName }}</b>.
        You got here in time to be one of the first {{ signupGrantRef?.allocated }} people to join our Preview!
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.alert.block-source {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  img {
    height: 45px;
  }
}
</style>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import { BlockGrant, IdentityApi } from '@webapp/auth/IdentityApi'
import { useSignInStore } from '@webapp/auth/SignInStore'

const sourceLogos: any = { // 'any' prevents TS from complaining about arbitrary string as index
  "https://doug.blogger.com": new URL('@/assets/logos/DearMyrtle.png', import.meta.url).href,
}

const signInStore = useSignInStore()

const signupGrantRef = ref<BlockGrant>()
const signupGrantActiveRef = computed(() => signupGrantRef.value && signupGrantRef.value.used < signupGrantRef.value.allocated)

watch(() => signInStore.couponCode, async () => {
  signupGrantRef.value = undefined

  if (signInStore.couponCode) {
    signupGrantRef.value = await IdentityApi.tryGetBlockGrantAsync(signInStore.couponCode)
  }
}, { immediate: true })
</script>
