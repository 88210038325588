<template>
  <div class="profile-page">
    <div class="page-title">
      <div class="gender-bar" :class="genderClassRef"></div>
      <img src="@/assets/icons/person_gray_150.png" class="page-title-icon"/>
      <div class="page-title-text-area">
        <h1 class="with-subhead">
          {{ titleRef }}
        </h1>
        <p v-if="profileTypeRef" class="subhead">
          <LinkIcon v-if="profileTypeRef == ProfileType.ManagedLinked" size="1x"></LinkIcon>
          <LockIcon v-if="profileTypeRef == ProfileType.ResearchRestricted" size="1x"></LockIcon>
          {{ profileTypeTextRef }}
          <UserLink v-if="ownerRef" :userId="ownerRef?.id" dimmed></UserLink>
        </p>
      </div>
    </div>
    <ul ref="tabsElemRef" class="nav nav-pills profile-tabs">
      <li class="nav-item">
        <button type="button" id="personTab" ref="personTabRef" class="nav-link active" data-bs-toggle="tab" data-bs-target="#personPane" role="tab" aria-controls="personPane" aria-selected="true">
          Person
        </button>
      </li>
      <li class="nav-item">
        <button type="button" id="matchesTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#matchesPane" role="tab" aria-controls="matchesPane" aria-selected="false">
          Matches
        </button>
      </li>
      <li class="nav-item">
        <button type="button" id="sharingTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#sharingPane" role="tab" aria-controls="sharingPane" aria-selected="false">
          Sharing
        </button>
      </li>
      <li class="nav-item">
        <button type="button" id="manageTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#managePane" role="tab" aria-controls="managePane" aria-selected="false">
          Manage
        </button>
      </li>
    </ul>
    <div class="tab-content content-area">
      <section id="personPane" class="tab-pane show active" role="tabpanel" aria-labelled-by="personTab" tab-index="0">
        <ProfileRelationships v-if="personIdRef" :person-id="personIdRef" @reload="reload"></ProfileRelationships>
        <h5>Details</h5>
        <AssertionList :person-id="personIdRef" profile></AssertionList>
        <div v-if="personRef" class="tree-link">
          <router-link :to="personRef?.viewPersonUrl">
            <ArrowRightIcon></ArrowRightIcon>
            View this person in my tree
          </router-link>
        </div>
      </section>
      <section id="matchesPane" class="tab-pane" role="tabpanel" aria-labelledby="matchesTab" tab-index="0">
        <ProfileMatchesEditor :person-id="personIdRef"/>
      </section>
      <section id="sharingPane" class="tab-pane relation-secton" role="tabpanel" aria-labelledby="sharingTab" tab-index="0">
        <ProfileSharingEditor v-if="personIdRef" :person-id="personIdRef"/>
      </section>
      <section id="managePane" class="tab-pane details-section" role="tabpanel" aria-labelled-by="manageTab" tab-index="0">
        <div v-if="!isResearchRef">
          <label class="form-label">
            <span v-if="profileTypeRef == ProfileType.User">User profile for</span>
            <span v-else>Profile owner</span>
          </label>
          <div class="input-value">
            <UserItem :user-id="dataGroupRef?.ownerId"></UserItem>
          </div>
        </div>
        <div v-if="profileTypeRef == ProfileType.ManagedLinked">
          <label class="form-label">
            Linked to tree profile
          </label>
          <PersonCard :person-id="trackedPersonIdRef" profile layout="small"></PersonCard>
          <button type="button" v-if="canEditRef" class="btn btn-inline btn-link action" data-bs-toggle="modal" data-bs-target="#confirmUnlinkModal">
            Unlink
          </button>
        </div>
        <div v-if="showIsChildRef" class="child-option">
          <label class="form-label">
            Profile options
          </label>
          <div class="form-check">
            <input type="checkbox" id="childOption" class="form-check-input" v-model="isChildRef" :disabled="!canEditRef">
            <label for="childOption" class="form-check-label">
              This person is a child
            </label>
            <InfoButton @click="showChildOptionInfoRef = !showChildOptionInfoRef"/>
          </div>
          <div v-if="showChildOptionInfoRef" class="help-text">
            Use this option to enable special features for children under age 13 that help protect them and their information.
          </div>
          <button type="button" v-if="showAddUserRef" class="btn btn-primary" @click="onAddChildUser">
            Create child account
          </button>
        </div>
        <div class="actions">
          <button type="button" v-if="canDeleteRef" class="btn btn-inline btn-link btn-link-danger" data-bs-toggle="modal" data-bs-target="#confirmDeleteModal">
            Delete profile
          </button>
        </div>
      </section>
    </div>
    <Modal id="confirmUnlinkModal"
      ref="confirmUnlinkModalRef"
      ok-style="warning" 
      @ok="unlinkProfile">
      <template #title>Unlink profile</template>
      <p>
        Unlinking this profile will allow it to be edited. It will no longer be updated automatically when the matching tree profile changes.
      </p>
      <p>
        <b>This operation cannot be undone.</b>
      </p>
      <p>
        Do you want to continue?
      </p>
      <template #okText>Unlink</template>
      <template #busyText>Unlinking...</template>
    </Modal>
    <UserAddModal ref="userAddModalRef"/>
    <Modal id="confirmDeleteModal"
      ref="confirmDeleteModalRef"
      ok-style="danger"
      @ok="deleteProfile">
      <template #title>Delete profile</template>
      <p>
        All information in this profile will be permanently deleted.
      </p>
      <p>
        Do you want to continue?
      </p>
      <template #okIcon><Trash2Icon></Trash2Icon></template>
      <template #okText>Delete</template>
      <template #busyText>Deleting...</template>
    </Modal>
    <Modal id="explainRestrictedModal" title="Restricted profiles" info>
      <p>
        Profiles of living or recently living people are automatically <b>restricted</b> when you upload your tree.
        They are only visible to the tree owner.
        However, at the same time, a linked copy is created that can be managed and shared separately from the tree.
        Linked profiles are updated automatically whenever the tree profile they are linked to changes.
      </p>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
.page-title {
  margin: 0.5rem 0 1.5rem;
  grid-template-columns: max-content max-content;

  .gender-bar {
    grid-column: 1;
    margin-right: 6px;
    width: 10px;
    background-color: #e9e9e9;

    &.male {
      background-color: #c3d5ea;
    }

    &.female {
      background-color: #fadce1;
    }
  }

  .page-title-icon {
    grid-column: 2;
    opacity: 40%;
  }

  .page-title-text-area {
    grid-column: 3;
  }

  .subhead {
    margin: 0;
  }
}

.alert {
  margin: 0;
}

.restricted-alert svg {
  margin-top: -2px;
  height: 1rem;
}

.linked-alert img {
  display: inline;
  width: 20px;
  // opacity: 60%;
  // filter to change color to 0d6efd
  filter: contrast(100%);
  
}

.tree-link a {
  font-size: 0.875rem;
}

.alert, .nav, .tab-content {
  max-width: 700px;
}

section h5 {
  font-size: 1rem;
  font-weight: normal;
}

.tab-content > section.active {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
}

#personPane {
  .research-group-card, .profile-card, .family-group-card {
    margin-top: 0.5rem;
  }
}

.input-value {
  margin: 0;
}

.actions {
  margin-top: 1.5rem;
}

.details-section {
  padding-bottom: 3rem;

  .assertion-list {
    max-width: 800px;
  }
}

.child-option {
  button.btn-primary {
    margin-top: 1.5rem;
  }

  .help-text {
    margin-left: 1.5rem;
  }
}
</style>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { ProfilePageData, loadProfilePageDataAsync } from './ProfilePageDataLoader'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { DataGroupType, ItemPermissions } from '@/gp/GroupAdminModel'
import { usePageTitle } from '@/util/AppUtil'
import { ProfileType, ProfileUtils } from './ProfileUtils'
import { ArrowRightIcon, Trash2Icon, LinkIcon, LockIcon } from '@zhuowenli/vue-feather-icons'
import { TokenManager } from '@/auth/TokenManager'
import { Tab } from 'bootstrap'
import { useTabsTelemetry } from '@/util/TabTelemetry'
import { PatchChange } from '@/util/Api'
import UserItem from './UserItem.vue'
import UserLink from './UserLink.vue'
import PersonCard from './PersonCard.vue'
import ProfileMatchesEditor from './ProfileMatchesEditor.vue'
import ProfileRelationships from './ProfileRelationships.vue'
import ProfileSharingEditor from './ProfileSharingEditor.vue'
import AssertionList from '@/explore/AssertionList.vue'
import Modal from '@/util/Modal.vue'
import InfoButton from '@/util/InfoButton.vue'
import UserAddModal from './UserAddModal.vue'
import { LoadMode } from '@/util/AsyncData'

const props = defineProps<{
  personId?: string,
}>()

const personIdRef = ref<string>()
const pageDataRef = ref<ProfilePageData>()
const isChildRef = ref(false)
const showChildOptionInfoRef = ref(false)

const tabsElemRef = ref<HTMLElement>()
const personTabRef = ref<HTMLButtonElement>()
const confirmUnlinkModalRef = ref<InstanceType<typeof Modal>>()
const userAddModalRef = ref<InstanceType<typeof UserAddModal>>()
const confirmDeleteModalRef = ref<InstanceType<typeof Modal>>()

const dataGroupStore = useDataGroupStore()
const userStore = useUserStore()
const router = useRouter()

const personRef = computed(() => pageDataRef.value?.asyncPerson?.data)
const genderClassRef = computed(() => [personRef.value?.displayProperties.gender?.toLowerCase()])
const dataGroupRef = computed(() => pageDataRef.value?.asyncDataGroup?.data)
const permissionsRef = computed(() => pageDataRef.value?.asyncPermissions?.data ?? ItemPermissions.None)
const workspaceRef = computed(() => pageDataRef.value?.asyncWorkspace?.data)
const trackingPersonIdRef = computed(() => pageDataRef.value?.trackingItemId)
const trackedPersonIdRef = computed(() => pageDataRef.value?.asyncTrackedItemLink?.data?.trackedItemId)

const titleRef = computed(() => personRef.value?.displayName ?? "Unknown name")
const profileTypeTextRef = computed(() => ProfileUtils.getProfileSubtitle(profileTypeRef.value))
const ownerRef = computed(() => userStore.getAsyncUser(dataGroupRef.value?.ownerId)?.data) // loaded with group
const profileTypeRef = computed(() => ProfileUtils.getProfileType(personRef.value, dataGroupRef.value, ownerRef.value))
const isResearchRef = computed(() => dataGroupRef.value?.groupType == DataGroupType.Research)
const showIsChildRef = computed(() => profileTypeRef.value == ProfileType.Managed || profileTypeRef.value == ProfileType.User)

const isOwnerRef = computed(() => (permissionsRef.value & ItemPermissions.Owner) > 0)
const canEditRef = computed(() => (permissionsRef.value & ItemPermissions.Modify) > 0)
const canDeleteRef = computed(() => (permissionsRef.value & ItemPermissions.Delete) > 0)

const showAddUserRef = computed(() => isOwnerRef.value && profileTypeRef.value == ProfileType.Managed && dataGroupRef.value?.isChild)

usePageTitle("Profile", () => personRef.value?.displayName)
useTabsTelemetry(tabsElemRef)

watch(props, () => reload(), { immediate: true })

watch(personIdRef, async () => {
  console.log(`ProfilePage: personId changed to ${personIdRef.value}`)
  if (personTabRef.value) {
    new Tab(personTabRef.value).show()
  }
  showChildOptionInfoRef.value = false
}, { immediate: true })

watch(isChildRef, async () => {
  if (dataGroupRef.value && isChildRef.value != dataGroupRef.value.isChild) {
    const change = new PatchChange('/isChild', isChildRef.value)
    await dataGroupStore.patchAsync(dataGroupRef.value!.id!, [change])
  }
})

async function reload() {
  pageDataRef.value = undefined
  personIdRef.value = props.personId
  if (!personIdRef.value) {
    const userProfileGroup = (await dataGroupStore.getGroupListAsync([TokenManager.userProfileId])).at(0)
    personIdRef.value = userProfileGroup?.startItemId
  }
  if (personIdRef.value) {
    pageDataRef.value = await loadProfilePageDataAsync(personIdRef.value)
  }
  isChildRef.value = dataGroupRef.value?.isChild ?? false
}

async function unlinkProfile() {
  await dataGroupStore.unlinkAsync(dataGroupRef.value!.id!)
  confirmUnlinkModalRef.value?.close()
}

async function deleteProfile() {
  await dataGroupStore.deleteAsync(dataGroupRef.value!.id!)
  confirmDeleteModalRef.value?.close()
  router.push('/profiles')
}

function onAddChildUser() {
  userAddModalRef.value?.open(personIdRef.value!)
}
</script>