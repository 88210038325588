<template>
  <div class="invite-profile shadow-sm">
    <div class="gender-bar" :gender="inviteRef?.profileGender"></div>
    <div class="profile-image">
      <img :src="profileImageRef"/>
    </div>
    <div class="profile-card-body">
      <div class="profile-card-title">{{ inviteRef?.profileDisplayName }}</div>
      <div class="profile-card-subtitle">Managed profile</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.invite-profile {
  height: 44px;
  max-width: 400px;
  border-top: 1px solid #eee;
  display: flex;
  line-height: 1.3; // tight line spacing but don't clip glyph descenders (1.2 is too tight)

  .gender-bar {
    flex: 0 0 8px;
    width: 0.5rem;
    background-color: #ddd;

    &[gender="Male"] {
      background-color: #c3d5ea;
    }

    &[gender="Female"] {
      background-color: #fadce1;
    }
  }

  .profile-image {
    background-color: #f4f4f4;
    flex: 0 0 36px;
    display: flex; // only to center placeholder image
    padding: 6px;
    align-items: center;
    justify-content: center;
    padding: 4px;

    img {
      width: 100%;
      object-fit: cover;
      opacity: 10%;
    }
  }

  .profile-card-body {
    padding: 0.1rem 0.5rem;
  }

  .profile-card-subtitle {
    font-size: 0.75rem;
    color: #aaa;
  }
}

</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { useFamilyGroupInviteStore } from '@/gp/FamilyGroupInviteStore'
import { LoadMode } from '@/util/AsyncData'
import { Gender } from '@/rd/ResearchDataModel';

const props = defineProps({
  inviteId: String
})

const inviteStore = useFamilyGroupInviteStore()

const genderImages: any = {}
genderImages[Gender.Male] = new URL("@/assets/icons/male_gray_150.png", import.meta.url).href
genderImages[Gender.Female] = new URL("@/assets/icons/female_gray_150.png", import.meta.url).href
genderImages["default"] = new URL("@/assets/icons/person_gray_150.png", import.meta.url).href

const inviteRef = computed(() => inviteStore.getAsyncInvite(props.inviteId, LoadMode.EnsureLoaded)?.data)
const profileImageRef = computed(() => genderImages[inviteRef.value?.profileGender ?? "default"])

</script>