<template>
  <div ref="modalRef" class="modal assertion-modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">
            Detail
          </h3>
          <button type="button" class="btn-close" :disabled="busy" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="assertion-field">
            <label class="form-label">Type</label>
            <div class="assertion-type">
              <img v-if="assertionTypeIcon && !isEditingTypeRef" :src="assertionTypeIcon"/>
              <select v-if="isEditingTypeRef" class="form-select" v-model="editAssertion.assertionType">
                <option v-for="d in sortedDefinitions" :value="d.type" :key="d.type">{{ d.type }}</option>
              </select>
              <div class="input-value" v-else>{{ assertionRef?.assertionType }}</div>
            </div>
          </div>
          <div v-if="definitionRef?.useDate" class="assertion-field">
            <label class="form-label">Date</label>
            <input type="text" class="form-control" v-if="isEditing" placeholder="e.g., 1 Jan 2000" :readonly="!canEditValues" v-model="editDateRef"/>
            <div class="input-value" v-show="!isEditing">{{ assertionRef?.date.toString(HistoricalDateFormat.LongDisplay) }}</div>
          </div>
          <div v-if="isNameRef">
            <div class="assertion-field">
              <label class="form-label">Given</label>
              <input type="text" class="form-control" v-if="isEditing" :readonly="!canEditValues" v-model="editNameValue.given"/>
              <div class="input-value" v-show="!isEditing">{{ nameValueRef?.given }}</div>
            </div>
            <div class="assertion-field">
              <label class="form-label">Surname</label>
              <input type="text" class="form-control" v-if="isEditing" :readonly="!canEditValues" v-model="editNameValue.surname"/>
              <div class="input-value" v-show="!isEditing">{{ nameValueRef?.surname }}</div>
            </div>
          </div>
          <div v-else-if="definitionRef?.useValue" class="assertion-field">
            <label class="form-label">Value</label>
            <select v-if="isEditing && useValueListRef" class="form-select" v-model="editAssertion.value">
              <option v-for="v in valueListRef" :value="v.value" :key="v.value">{{ v.name }}</option>
              <option value="">Unknown</option>
            </select> 
            <input type="text" class="form-control" v-else-if="isEditing" :readonly="!canEditValues" v-model="editAssertion.value"/>
            <div class="input-value" v-show="!isEditing">{{ assertionRef?.value }}</div>
          </div>
          <!-- <div v-if="definitionRef?.useLocation" class="assertion-field">
            <label class="form-label">Place</label>
            <input type="text" class="form-control" v-if="isEditing" :readonly="!canEditValues" v-model="editAssertion.placeId"/>
            <div class="input-value" v-show="!isEditing">{{ assertionRef?.placeId }}</div>
          </div> -->
        </div>
        <div class="modal-footer">
          <button type="button" v-if="canEdit" class="btn btn-link" @click="onEdit">
            Edit
          </button>
          <button type="button" v-if="!isEditing" class="btn btn-primary" @click="close">
            Close
          </button>
          <button type="submit" v-if="isEditing" class="btn btn-primary" :disabled="!canSave" @click="save">
            {{ saveText }}
          </button>
          <button type="button" v-if="isEditing" class="btn btn-outline-primary" :disabled="!canCancel" @click="cancelEdit">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-body {
  padding: 1rem 1.5rem;
}

.assertion-field {
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 100px auto;
  column-gap: 0.5rem;
  align-items: center;

  .form-control, .input-value {
    margin-bottom: 0;
  }

  .assertion-type {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
      width: 24px;
      opacity: 20%;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed, reactive, ref, watch } from 'vue'
import { useAssertionStore } from '@/rd/AssertionStore'
import { useAssertionTypeDefinitionStore } from '@/rd/AssertionTypeDefinitionStore'
import { usePlaceStore } from '@/rd/PlaceStore'
import { Assertion, AssertionType, Gender, ItemType, PersonName } from '@/rd/ResearchDataModel'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { AssertionTypeIcons } from '@/explore/AssertionTypeIcons'
import { CompositeId } from '@/rd/CompositeId'
import { useEditMode } from '@/util/EditMode'
import { Modal as BsModal } from 'bootstrap'
import { ItemPermissions } from '@/gp/GroupAdminModel'
import { PatchChange, PatchChangeValue } from '@/util/Api'
import { LoadMode } from '@/util/AsyncData'
import { assignExisting } from '@/util/TypeScriptUtil'
import { HistoricalDate } from '@/rd/HistoricalDate'
import { HistoricalDateFormat } from '@/rd/HistoricalDateEnums'
import { SortDate } from '@/rd/SortDate'

defineExpose({
  openEdit,
  close,
  openNew,
})

const assertionIdRef = ref<string>()
const subjectIdRef = ref<string>()
const subjectTypeRef = ref<ItemType>()
const editDateRef = ref('')
const modalRef = ref<HTMLElement>()

const assertionStore = useAssertionStore()
const dataGroupStore = useDataGroupStore()
const definitionStore = useAssertionTypeDefinitionStore()
const placeStore = usePlaceStore()

const assertionRef = computed(() => assertionStore.getAsyncAssertion(assertionIdRef.value)?.data) // loaded by parent
const isNewRef = computed(() => !assertionIdRef.value)
const permissionsRef = computed(() => dataGroupStore.getAsyncPermissions(CompositeId.getGroupId(assertionIdRef.value ?? subjectIdRef.value))?.data ?? ItemPermissions.None) // loaded by parent
const allowEdit = computed(() => (permissionsRef.value & ItemPermissions.Modify) != 0)
const editAssertion = reactive(new Assertion())
const editProperties: (keyof Assertion)[] = [
  'assertionType',
  'date',
  'value',
  'placeId',
]

const allowSave = computed(() => !!editAssertion.assertionType)

const { isEditing, canEdit, canEditValues, edit, saveText, canSave, saveChanges, busy, canCancel, cancel } = 
  useEditMode(assertionRef, editAssertion, editProperties, allowEdit, allowSave)

const assertionTypeRef = computed(() => (isEditing.value ? editAssertion : assertionRef.value)?.assertionType)
const assertionTypeIcon = computed(() => AssertionTypeIcons.getIcon(assertionTypeRef.value))
const definitionRef = computed(() => definitionStore.getAsyncDefinition(assertionTypeRef.value, LoadMode.EnsureLoaded)?.data)

const isNameRef = computed(() => assertionTypeRef.value == AssertionType.Name)
const nameValueRef = computed(() => isNameRef.value ? new PersonName(assertionRef.value?.value) : undefined)
const editNameValue = reactive(new PersonName())
watch(editNameValue, () => {
  editAssertion.value = editNameValue.getValue()
})

const isEditingTypeRef = computed(() => isNewRef.value && isEditing.value)
const sortedDefinitions = computed(() => definitionStore.getDefinitionsForCommon(LoadMode.EnsureLoaded).sort((a, b) => a.type!.localeCompare(b.type!)))

const useValueListRef = computed(() => assertionTypeRef.value == AssertionType.Gender)
const valueListRef = [
  { value: Gender.Male, name: Gender.Male },
  { value: Gender.Female, name: Gender.Female },
]

function openNew(subjectId: string, subjectType: ItemType) {
  assertionIdRef.value = undefined
  subjectIdRef.value = subjectId
  subjectTypeRef.value = subjectType

  editAssertion.assertionType = ''
  editAssertion.date = HistoricalDate.Empty
  editAssertion.value = undefined
  editAssertion.placeId = undefined

  openCore()
  edit()
}

function openEdit(assertionId: string) {
  assertionIdRef.value = assertionId
  subjectIdRef.value = undefined
  openCore()
}

function openCore() {
  if (modalRef.value) {
    cancel()

    // HACK: Not sure why getInstance isn't sufficient here, but sometimes it returns null because apparently the BS Modal
    // hasn't been initialized yet. Calling getOrCreateInstance seems to fix it.
    BsModal.getOrCreateInstance(modalRef.value).show()
  }
}

function close() {
  BsModal.getInstance(modalRef.value!)!.hide()
}

function onEdit() {
  if (isNameRef.value) {
    assignExisting(editNameValue, new PersonName(assertionRef.value?.value))
  }
  edit()
  editDateRef.value = editAssertion.date.toString(HistoricalDateFormat.LongDisplay)
}

function save() {
  saveChanges(async () => {
    if (isNewRef.value) {
      const newAssertion = new Assertion()
      newAssertion.assertionType = editAssertion.assertionType
      newAssertion.subjectId = subjectIdRef.value!
      newAssertion.subjectType = subjectTypeRef.value!
      if (definitionRef.value!.useDate) {
        newAssertion.date = HistoricalDate.parseUserInput(editDateRef.value)
        newAssertion.sortDate = SortDate.parse(newAssertion.date.sortValue)
      }
      if (definitionRef.value!.useValue) {
        newAssertion.value = editAssertion.value
      }
      if (definitionRef.value!.useLocation) {
        //newAssertion.placeId = editAssertion.placeId
      }
      await assertionStore.addAsync(CompositeId.getGroupId(subjectIdRef.value!)!, newAssertion)
      close()
    }
    else {
      const changes: PatchChange[] = []
      for (const key of editProperties) {
        if (editAssertion[key] != assertionRef.value![key]) {
          changes.push(new PatchChange(`/${key}`, editAssertion[key] as PatchChangeValue))
        }
      }

      await assertionStore.patchAsync(assertionIdRef.value!, changes)
    }
  })
}

function cancelEdit() {
  cancel()
  if (isNewRef.value) {
    close()
  }
}
</script>