import { Api } from '@webapp/util/Api'
import { AccessTokenResponse } from './AccessTokenResponse'
import { Environment } from '@webapp/util/Environment'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'

const tokenPath = 'oauth/v2.0/token'

export class LocalTokenApi {

    static async getLocalAccessTokenAsync(identityToken: string): Promise<AccessTokenResponse> {
        return await exchangeToken(identityToken, Environment.get('LOCAL_TOKEN_AUDIENCE'), "User")
    }

    static async getLocalAccessTokenFromCodeAsync(clientId: string, code: string) {
        const api = new Api(Environment.get('GROUP_ADMIN_URL'), () => '');
        const request = {
            grant_type: "authorization_code",
            requested_token_type: "access_token",
            client_id: clientId,
            code,
        };
        return await api.postPlainAsync(tokenPath, request, {}, true) as AccessTokenResponse;
    }

    static async getLocalIdentityTokenAsync(localAccessToken: string) {
        const audience = Environment.get('IDENTITY_URL').slice(0, -1) // remove trailing slash
        return await exchangeToken(localAccessToken, audience, "User")
    }

    static async getLocalAccessTokenForUserAsync(subjectToken: string, forUserId: string) {
        const audience = Environment.get('LOCAL_TOKEN_AUDIENCE')
        return await exchangeToken(subjectToken, audience, `User.${forUserId}`)
    }

    static async refreshAccessToken(accessToken: string, refreshToken: string) {
        // use current access token (expired okay) to authenticate the request
        const api = new Api(Environment.get('GROUP_ADMIN_URL'), () => accessToken)
        const request = {
            grant_type: "refresh_token",
            refresh_token: refreshToken,
        }
        return await api.postPlainAsync(tokenPath, request, {}, true) as AccessTokenResponse
    }

    static async getLocalAuthCodeAsync(accessToken: string, clientId: string) {
        const api = new Api(Environment.get('GROUP_ADMIN_URL'), () => accessToken)
        const p = await api.postPlainAsync('authcode', undefined, { client_id: clientId })
        p.expirationDate = DateTimeUtil.fromAPI(p.expirationDate)
        return p as AuthCodeResponse
    }
}

async function exchangeToken(subjectToken: string, audience: string, scope: string) {
    // current access token is specified in the body, not in the headers
    const api = new Api(Environment.get('GROUP_ADMIN_URL'), () => '');
    const request = {
        grant_type: "token-exchange",
        subject_token_type: "access_token",
        subject_token: subjectToken,
        requested_token_type: "access_token",
        audience,
        scope,
    };
    return await api.postPlainAsync(tokenPath, request, {}, true) as AccessTokenResponse;
}

export interface AuthCodeResponse {
    code: string
    expirationDate: DateTime
    notificationId: string
}
